export const isValidUrl = (
  url: string,
  options: {
    requireProtocol?: boolean;
    allowedProtocols?: string[];
    baseUrl?: string;
  } = {
    requireProtocol: true,
    allowedProtocols: ['http', 'https'],
  },
): boolean => {
  if (!url || typeof url !== 'string') {
    return false;
  }

  const trimmedUrl = url.trim();
  if (trimmedUrl === '') {
    return false;
  }

  try {
    // If a baseUrl is provided, resolve relative URLs
    const urlToValidate = options.baseUrl
      ? new URL(trimmedUrl, options.baseUrl)
      : new URL(trimmedUrl);

    // If a protocol is required, check it
    if (options.requireProtocol) {
      const protocol = urlToValidate.protocol.replace(':', ''); // Remove trailing colon
      if (
        !protocol ||
        (options.allowedProtocols &&
          !options.allowedProtocols.includes(protocol))
      ) {
        return false;
      }
    }

    // Additional validation for hostname (basic check)
    const hostname = urlToValidate.hostname;
    if (!hostname || !/^[a-zA-Z0-9-\\.]+$/.test(hostname)) {
      return false;
    }

    // Basic domain validation: ensure it has at least one dot and a valid TLD
    const domainParts = hostname.split('.');
    if (domainParts.length < 2 || domainParts[domainParts.length - 1] === '') {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
};
