import React from 'react';
import { withPatientForm, WithFormProps } from '@@/hocs/withPatientForm';
import {
  PatientMedicalForm,
  PatientMedicalContactsForm,
  PatientProfileForm,
  PatientPreferrencesForm,
} from '@@/components/Forms/PatientForm';
import DocumentSection from '@@/components/DocumentSection';
import InvoiceSection from '@@/components/InvoiceSection';
import HistoryForm from '@@/components/Forms/HistoryForm';
import SectionTitle from '@@/components/SectionTitle';

const PatientPage: React.FC<WithFormProps> = ({
  patientForm,
  patient,
  handleRevisionChange,
}) => {
  return (
    <div className="relative p-4 w-full">
      <div className="flex flex-row justify-end">
        <HistoryForm
          patientId={patient.id}
          callback={handleRevisionChange}
          disabled={false}
        />
      </div>

      <PatientProfileForm form={patientForm} />
      <PatientPreferrencesForm form={patientForm} userId={patient.id} />

      <div className="mt-12" />
      <PatientMedicalForm form={patientForm} />

      <SectionTitle title="Correspondants" className="mt-12 mb-6" />
      <PatientMedicalContactsForm form={patientForm} />

      <SectionTitle title="Documents" className="mt-12 mb-6" />
      <DocumentSection userId={patient.id} />
      <InvoiceSection userId={patient.id} />
    </div>
  );
};

export default withPatientForm(PatientPage);
