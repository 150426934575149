import React, { useCallback, useState, useEffect } from 'react';
import cx from 'classnames';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  useGetUserTimelineEventsQuery,
  useDeleteEventMutation,
  useHandleEventNoShowMutation,
} from '@@/services/event';
import {
  LuCalendar,
  LuMessageSquareText,
  LuStar,
  LuMapPin,
  LuUser,
  LuNotebookPen,
  LuPaperclip,
  LuSend,
  LuLink2,
  LuAnnoyed,
  LuPhoneMissed,
  LuDelete,
  LuCalendarPlus2,
} from 'react-icons/lu';
import { MdOutlineEdit } from 'react-icons/md';
import { RxUpdate } from 'react-icons/rx';
import { getPractitionerContactLabel } from '@@/components/Inputs/SelectPractitionerContactInput';
import { translateRecurringFrequency } from '@@/constants/recurringEvent';
import Loader from '@@/components/Loader';
import AuthenticatedImage from '@@/components/AuthenticatedImage';
import AuthenticatedLink from '@@/components/AuthenticatedLink';

const TimelineItem: React.FC<{ item: Unpatient.TimelineEvent }> = ({
  item,
}) => {
  return (
    <div key={item.id} className="mb-8 ml-4 font-main">
      <div className="absolute -left-3.5 h-6 w-6 rounded-full bg-sky-500 flex flex-row items-center justify-center">
        <LuMessageSquareText className="text-white" size={12} />
      </div>
      <p className="text-sky-500 font-semibold">
        {moment(item.date).format('DD/MM/YYYY')}
      </p>
      <div className="bg-white p-4 rounded-lg shadow-lg">
        <h3 className="font-semibold text-slate-700">
          {item.cim11?.length ? (
            <span className="mr-1">{`[${item.cim11.join(' - ')}]`}</span>
          ) : null}

          {item.title}
        </h3>
        <p className="text-sm text-slate-700">{item.summary}</p>
      </div>
    </div>
  );
};

const EventItem: React.FC<{
  item: Unpatient.Event;
  deleteEvent: (id: string) => void;
  handleEventNoShow: (event: Unpatient.Event) => void;
  isLoading: boolean;
}> = ({ item, deleteEvent, handleEventNoShow, isLoading }) => {
  return (
    <div key={item.id} className="mb-8 ml-3.5 relative">
      <div className="absolute -left-7 h-6 w-6 rounded-full bg-sky-500 flex flex-row items-center justify-center">
        <LuCalendar className="text-white" size={12} />
      </div>
      <p className="text-sky-500 font-semibold">
        {moment(item.startDate).format('DD/MM/YYYY')}
      </p>
      <div className="mt-1 bg-white p-4 rounded-lg shadow-lg">
        <h3 className="font-semibold text-slate-700">{item.name}</h3>

        <div className="flex flex-row items-center flex-wrap space-x-2">
          {item.isNoShow && (
            <div className="flex flex-row items-center p-0.5 rounded-lg bg-pink-100 text-pink-700 text-xs">
              <LuAnnoyed size={12} />
              <span className="ml-1 text-xs">No Show</span>
            </div>
          )}

          {!!item.recurringEvent && (
            <p className="flex flex-row items-center bg-slate-100 text-slate-700 p-0.5 rounded-lg">
              <RxUpdate size={12} />
              <span className="ml-1 text-xs">
                {translateRecurringFrequency(item.recurringEvent.frequency)}
              </span>
            </p>
          )}

          {!!item.doctolibId && (
            <Link
              to={item.url || item.targetPractitioner?.appointmentURL || ''}
              className="flex flex-row items-center p-0.5 rounded-lg bg-slate-100 text-slate-700 w-fit"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LuLink2 size={12} />
              <span className="ml-1 text-xs">Doctolib</span>
            </Link>
          )}

          {!!item.calendlyId && (
            <Link
              to={item.url || ''}
              className="flex flex-row items-center p-0.5 rounded-lg bg-slate-100 text-slate-700 w-fit"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LuLink2 size={12} />
              <p className="ml-1 text-xs">Calendly</p>
            </Link>
          )}

          {!!item.shouldSendCalendarInvite && (
            <div className="flex flex-row items-center p-0.5 rounded-lg bg-emerald-100 text-emerald-700 w-fit">
              <LuSend size={12} />
              <p className="ml-1 text-xs">Invitation envoyée</p>
            </div>
          )}

          <Link
            to={
              item.calendlyRescheduleUrl
                ? item.calendlyRescheduleUrl
                : `/patients/${item.userId.id}/events/${item.id}`
            }
            className="ml-1"
          >
            <MdOutlineEdit
              size={20}
              className="text-white bg-yellow-500 p-1 rounded-full"
            />
          </Link>
          {!!item.calendlyId && (
            <button
              className="ml-1"
              onClick={() =>
                confirm(
                  item.isNoShow
                    ? 'Etes vous sûr de vouloir enlever la mention No Show ?'
                    : 'Etes vous sûr de vouloir passer cet évènement en no-show',
                ) && handleEventNoShow(item)
              }
              disabled={isLoading}
            >
              <LuPhoneMissed
                size={20}
                className="text-white bg-pink-500 p-1 rounded-full"
              />
            </button>
          )}
          {!item.doctolibId && (
            <button
              className="ml-1"
              onClick={() =>
                confirm('Etes vous sûr de vouloir supprimer cet évènement') &&
                deleteEvent(item.id)
              }
              disabled={isLoading}
            >
              <LuDelete
                size={20}
                className="text-white bg-red-500 p-1 rounded-full"
              />
            </button>
          )}
        </div>

        <div className="flex flex-row items-center mt-2 text-slate-700">
          <LuCalendar size={16} />
          <span className="ml-1 text-sm">
            {moment(item.startDate).format('llll')}
          </span>
        </div>

        {!!item.targetPractitioner?.id && (
          <Link
            className="flex flex-row items-center mt-1 text-slate-700"
            to={`/practitioner-contacts/${item.targetPractitioner.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LuUser size={16} />
            <span className="ml-1 text-sm">
              {getPractitionerContactLabel(item.targetPractitioner)}
            </span>
          </Link>
        )}

        {!!item.location && (
          <div className="flex flex-row items-center mt-1 text-slate-700">
            <LuMapPin size={16} />
            <p className="ml-1 text-sm">{item.location}</p>
          </div>
        )}

        {!!item.notes && (
          <div className="flex flex-row items-start mt-1 text-slate-700">
            <LuNotebookPen size={16} />
            <span className="ml-1 text-sm w-full">{item.notes}</span>
          </div>
        )}

        <div className="mt-2 flex flex-row flex-wrap ">
          {item.attachmentIds?.map((attachment) => (
            <AuthenticatedLink
              key={attachment.id}
              documentId={attachment.id}
              className="mr-1 mb-1 p-1 bg-slate-100 border border-gray-200 rounded-lg flex flex-row items-center w-fit max-w-full truncate"
            >
              {attachment.mimetype.includes('image') ? (
                <AuthenticatedImage
                  documentId={attachment.id}
                  alt={attachment.name}
                  className="h-6 w-6 content-center object-cover rounded-md"
                />
              ) : (
                <LuPaperclip size={12} />
              )}
              <span className="ml-1 text-xs text-slate-70O truncate w-fit max-w-36">
                {attachment.name}
              </span>
            </AuthenticatedLink>
          ))}
        </div>
      </div>
    </div>
  );
};

const PlanItem: React.FC<{ item: Unpatient.Event }> = ({ item }) => {
  return (
    <div key={item.id} className="mb-8 ml-4 font-main">
      <div className="absolute -left-3.5 h-6 w-6 rounded-full bg-sky-500 flex flex-row items-center justify-center">
        <LuStar className="text-white" size={12} />
      </div>
      <p className="text-sky-500 font-semibold">
        {moment(item.startDate).format('DD/MM/YYYY')}
      </p>
      <div className="bg-white p-4 rounded-lg shadow-lg">
        <h3 className="font-semibold">{item.name}</h3>
        {!!item.notes && <p className="text-sm text-gray-700">{item.notes}</p>}
      </div>
    </div>
  );
};

const TimelineSection: React.FC<{ patientId: string }> = ({ patientId }) => {
  const { data: timelineItems, isLoading } = useGetUserTimelineEventsQuery(
    patientId,
    {
      skip: !patientId,
    },
  );

  const [showPlanEvents, setShowPlanEvents] = useState(false);
  const togglePlanEvents = useCallback(() => {
    setShowPlanEvents((prev) => !prev);
  }, [setShowPlanEvents]);

  const [showSummaryEvents, setShowSummaryEvents] = useState(true);
  const toggleSummaryEvents = useCallback(() => {
    setShowSummaryEvents((prev) => !prev);
  }, [setShowSummaryEvents]);

  const [
    deleteEvent,
    { isSuccess: isDeletionSuccess, isLoading: isDeletionLoading },
  ] = useDeleteEventMutation();

  const [
    handleEventNoShow,
    { isSuccess: isHandleNoShowSuccess, isLoading: isHandleNoShowLoading },
  ] = useHandleEventNoShowMutation();

  useEffect(() => {
    if (isDeletionSuccess) {
      toast.success("L'événement a été supprimé", {
        position: 'top-right',
      });
    }
  }, [isDeletionSuccess]);

  useEffect(() => {
    if (isHandleNoShowSuccess) {
      toast.success('Le no show a bien été traité', {
        position: 'top-right',
      });
    }
  }, [isHandleNoShowSuccess]);

  if (isLoading) {
    return (
      <div className="m-8 flex flex-row items-center justify-center w-full">
        <Loader />
        <p className="ml-2 font-main text-main text-lg">Chargement...</p>
      </div>
    );
  }

  return (
    <div className="mt-6">
      <Link
        className="bg-sky-600 hover:bg-sky-700 rounded-md p-1 flex flex-row items-center text-white cursor-pointer w-fit ml-auto"
        to={`/patients/${patientId}/events/new`}
      >
        <LuCalendarPlus2 size={16} />
        <span className="ml-1 text-sm">Nouvel événement</span>
      </Link>
      <div className="flex flex-row items-center justify-between mb-4 mt-2">
        <h1 className="text-slate-700 text-2xl font-bold">Chronologie :</h1>
        <div className="flex flex-row space-x-2">
          <button
            onClick={togglePlanEvents}
            className={cx(
              'p-1 rounded-lg',
              showPlanEvents
                ? 'text-slate-700 bg-slate-100 border border-slate-200'
                : 'text-slate-400 border border-slate-200',
            )}
          >
            Plans
          </button>

          <button
            onClick={toggleSummaryEvents}
            className={cx(
              'p-1 rounded-lg',
              showSummaryEvents
                ? 'text-slate-700 bg-slate-100 border border-slate-200'
                : 'text-slate-400 border border-slate-200',
            )}
          >
            Résumés
          </button>
        </div>
      </div>
      <div className="mx-auto px-6 pb-8 overflow-scroll">
        <div className="relative border-l-2 border-sky-200">
          {timelineItems?.map((item) => {
            switch (item.type) {
              case 'timeline-event':
                return (
                  <EventItem
                    key={item.id}
                    item={item as Unpatient.Event}
                    isLoading={isDeletionLoading || isHandleNoShowLoading}
                    deleteEvent={deleteEvent}
                    handleEventNoShow={handleEventNoShow}
                  />
                );
              case 'timeline-item':
                if (showSummaryEvents) {
                  return (
                    <TimelineItem
                      key={item.id}
                      item={item as Unpatient.TimelineEvent}
                    />
                  );
                }
                return null;

              case 'timeline-plan':
                if (showPlanEvents) {
                  return (
                    <PlanItem key={item.id} item={item as Unpatient.Event} />
                  );
                }
                return null;

              default:
                return null;
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default TimelineSection;
