import React, { useEffect, useCallback, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import cx from 'classnames';
import omit from 'lodash.omit';
import toast from 'react-hot-toast';
import {
  useGetPrescriptionQuery,
  useCreatePrescriptionMutation,
  usePatchPrescriptionMutation,
} from '@@/services/prescription';
import { useViewDocumentQuery } from '@@/services/document';
import { PrescriptionTypes } from '@@/constants/prescription';
import { useGetPopulatedPatientQuery, useGetMeQuery } from '@@/services/user';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PrescriptionForm, { schema } from '@@/components/Forms/PrescriptionForm';
import { usePDF, PDFViewer } from '@react-pdf/renderer';
import { CiExport } from 'react-icons/ci';
import Prescription from '@@/components/PDF/Prescription';
import AuthenticatedPrescriptionPDFLink from '@@/components/AuthenticatedPrescriptionPDFLink';

const NEW_PRESCRIPTION = 'new';

const NewPrescriptionPage: React.FC<{ targetPatientId?: string }> = ({
  targetPatientId,
}) => {
  const navigate = useNavigate();
  const {
    prescriptionId: prescriptionIdFromParams,
    patientId: patientIdFromParams,
  } = useParams();
  const patientId = patientIdFromParams || targetPatientId;
  const prescriptionId = prescriptionIdFromParams || NEW_PRESCRIPTION;
  const isPage = !!patientIdFromParams;

  const isNewPrescription = useMemo(
    () => prescriptionId === NEW_PRESCRIPTION,
    [prescriptionId],
  );
  const { data: prescription } = useGetPrescriptionQuery(prescriptionId || '', {
    skip: !prescriptionId || isNewPrescription,
  });
  const { data: patient } = useGetPopulatedPatientQuery(patientId || '', {
    skip: !patientId,
  });
  const { data: me } = useGetMeQuery();

  const signatureDocumentId = useMemo(
    () =>
      prescription?.practitioner?.signatureDocumentId ||
      me?.signatureDocumentId,
    [prescription?.practitioner, me],
  );
  const { data: signature } = useViewDocumentQuery(signatureDocumentId || '', {
    skip: !signatureDocumentId,
  });

  const [createPrescription, { isSuccess, isLoading, data: newPrescription }] =
    useCreatePrescriptionMutation();

  const [
    updatePrescription,
    { isSuccess: isUpdateSucess, isLoading: isUpdateLoading },
  ] = usePatchPrescriptionMutation();

  const form = useForm<Unpatient.Prescription & { templateId?: string }>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: {
      type: PrescriptionTypes.FREE,
      freeItems: [{ text: '', comment: '' }],
    },
  });

  const formValues = form.watch();

  useEffect(() => {
    if (prescription?.id) {
      const data = omit(prescription, [
        '_id',
        'id',
        'isDeleted',
        'createdAt',
        'updatedAt',
        'patientId',
        'practitionerId',
        'patient',
        'practitioner',
        'isTemplate',
      ]);

      form.reset(data);
    }
  }, [prescription?.id, form.reset]);

  const onSubmit = useCallback(
    (data: Partial<Unpatient.Prescription> & { templateId?: string }) => {
      switch (data.type) {
        case PrescriptionTypes.BIOLOGY:
          data.imageryItems = undefined;
          data.freeItems = undefined;
          data.standardItems = undefined;
          data.aldItems = undefined;
          break;
        case PrescriptionTypes.IMAGERY:
          data.biologyItems = undefined;
          data.freeItems = undefined;
          data.standardItems = undefined;
          data.aldItems = undefined;
          break;
        case PrescriptionTypes.FREE:
          data.biologyItems = undefined;
          data.imageryItems = undefined;
          data.standardItems = undefined;
          data.aldItems = undefined;
          break;
        case PrescriptionTypes.BIZONE:
          data.biologyItems = undefined;
          data.imageryItems = undefined;
          data.freeItems = undefined;
          break;
        default:
          data.biologyItems = undefined;
          data.imageryItems = undefined;
          data.freeItems = undefined;
          data.standardItems = undefined;
          data.aldItems = undefined;
          break;
      }

      data.templateId = undefined;

      if (prescription?.id) {
        updatePrescription({
          ...data,
          id: prescription.id,
          patientId,
        });
      } else {
        createPrescription({ ...data, patientId });
      }
    },
    [createPrescription, updatePrescription, patientId, prescription?.id],
  );

  useEffect(() => {
    if (isSuccess && newPrescription?.id) {
      toast.success(`La prescription a bien été crée`, {
        position: 'top-right',
      });

      if (isPage) {
        navigate(`/patients/${patientId}/prescriptions/${newPrescription.id}`);
      }
    }
  }, [isSuccess, newPrescription?.id, navigate, patientId, isPage]);

  useEffect(() => {
    if (isUpdateSucess) {
      toast.success(`La prescription a bien été mise à jour`, {
        position: 'top-right',
      });
    }
  }, [isUpdateSucess]);

  const prescriptionPDF = useMemo(
    () => (
      <Prescription
        prescription={formValues}
        patient={patient}
        practitioner={prescription?.practitioner || me}
        signature={signature}
      />
    ),
    [formValues, patient, prescription?.practitioner, me, signature],
  );

  const [pdf, updatePdf] = usePDF({
    document: prescriptionPDF,
  });

  useEffect(() => {
    updatePdf(prescriptionPDF);
  }, [updatePdf, prescriptionPDF]);

  const button = useMemo(() => {
    const disabled =
      isLoading ||
      isUpdateLoading ||
      form.formState.isSubmitting ||
      !form.formState.isDirty;

    return (
      <button
        type="button"
        onClick={form.handleSubmit(onSubmit)}
        disabled={disabled}
        className={cx(
          'my-6 rounded-lg p-2 bg-cyan-400 text-white',
          disabled && 'opacity-50 cursor-not-allowed',
        )}
      >
        <span className=" ml-1">Valider</span>
      </button>
    );
  }, [
    form.handleSubmit,
    form.formState.isSubmitting,
    form.formState.isDirty,
    onSubmit,
    isLoading,
    isUpdateLoading,
  ]);

  const downloadButton = useMemo(() => {
    if (isNewPrescription) {
      return null;
    }
    if (prescription?.type === PrescriptionTypes.BIZONE) {
      return (
        <AuthenticatedPrescriptionPDFLink
          prescriptionId={prescription.id}
          className="bg-cyan-400 rounded-lg p-2 flex flex-row items-center text-white h-fit"
        >
          <CiExport size={22} />
          <span className="ml-1">CERFA Bizone</span>
        </AuthenticatedPrescriptionPDFLink>
      );
    }
    if (pdf.loading || !pdf.url) {
      return null;
    }

    return (
      <a
        className="bg-cyan-400 rounded-lg p-2 flex flex-row items-center text-white h-fit"
        download={`Ordonnance-${patient?.firstName}-${patient?.lastName}.pdf`}
        href={pdf.url}
      >
        <CiExport size={22} />
        <span className="ml-1">{pdf.error ? pdf.error : 'Exporter'}</span>
      </a>
    );
  }, [pdf, isNewPrescription, prescription?.type]);

  return (
    <div className="m-4 overflow-scroll">
      <h1 className="font-main text-main text-xl border-b-2 border-cyan-400 mb-8">
        {isNewPrescription
          ? `Nouvelle ordonnance pour ${patient?.firstName} ${patient?.lastName}`
          : `Ordonnance pour ${patient?.firstName} ${patient?.lastName}`}
      </h1>

      <div
        className={cx(
          'z-10 top-12 right-6 flex flex-row items-center space-x-2',
          isPage ? 'fixed' : 'absolute',
        )}
      >
        {button} {downloadButton}
      </div>
      <div className="flex flex-row h-full space-x-10">
        <div className="w-[600px]">
          <PrescriptionForm form={form} isNewPrescription={isNewPrescription} />
        </div>

        {prescription?.type !== PrescriptionTypes.BIZONE && (
          <PDFViewer style={{ width: '500px', height: '760px' }}>
            {prescriptionPDF}
          </PDFViewer>
        )}
      </div>

      <div className="h-36" />
    </div>
  );
};

export default NewPrescriptionPage;
