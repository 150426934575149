import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { useViewDocumentQuery } from '@@/services/document';
import Loader from '@@/components/Loader';

const AuthenticatedLink: React.FC<{
  documentId?: string;
  className?: string;
  children?: any;
}> = ({ documentId = '', className, children }) => {
  const { data: documentUrl, isLoading } = useViewDocumentQuery(documentId, {
    skip: !documentId,
  });

  return (
    <Link
      to={documentUrl || ''}
      target="_blank"
      rel="noopener noreferrer"
      className={cx(className, !documentUrl && 'cursor-not-allowed opacity-50')}
    >
      {isLoading && (
        <Loader className="w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-gray-400 mr-1" />
      )}
      {children}
    </Link>
  );
};

export default AuthenticatedLink;
