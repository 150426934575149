import React, { useCallback, useEffect } from 'react';
import moment from 'moment';
import toast from 'react-hot-toast';
import {
  useGetUserSuggestionsQuery,
  useCreateUserSuggestionMutation,
} from '@@/services/suggestion';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import SuggestionForm, { schema } from '@@/components/Forms/SuggestionForm';

const SuggestionPage: React.FC<{ targetPatientId: string }> = ({
  targetPatientId,
}) => {
  const { data: suggestions } = useGetUserSuggestionsQuery(
    targetPatientId || '',
    {
      skip: !targetPatientId,
    },
  );

  const [createSuggestion, { isSuccess }] = useCreateUserSuggestionMutation();

  const handleCreateSuggestion = useCallback(
    ({ temperature, hint }: { temperature: number; hint?: string }) => {
      if (targetPatientId) {
        createSuggestion({
          userId: targetPatientId,
          temperature: temperature / 100,
          hint,
        });
      }
    },
    [createSuggestion, targetPatientId],
  );

  const form = useForm<{ temperature: number }>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  useEffect(() => {
    if (isSuccess) {
      form.reset();
    }
  }, [isSuccess, form.reset]);

  if (!suggestions) {
    return null;
  }

  return (
    <div className="mx-auto px-6 py-2 overflow-scroll w-full">
      <SuggestionForm form={form} />
      <button
        onClick={form.handleSubmit(handleCreateSuggestion)}
        disabled={form.formState.isSubmitting}
        className="block mt-4 mb-8 ml-auto bg-sky-600 rounded-md p-1.5"
      >
        <span className="text-white ml-1">Créer une nouvelle suggestion</span>
      </button>
      <div className="relative border-l-2 border-gray-200">
        {suggestions.map((item: Unpatient.Suggestion) => (
          <div key={item.id} className="mb-8 ml-4 font-main">
            <div className="absolute -left-3.5 h-6 w-6 rounded-full bg-cyan-500"></div>
            <p className="text-cyan-500 font-semibold">
              {moment(item.createdAt).format('DD/MM/YYYY - HH:mm:ss')}
              &nbsp;(temp:&nbsp;{item.temperature})
            </p>
            <div
              className="bg-white p-4 rounded-lg shadow-lg"
              onClick={() => {
                navigator.clipboard.writeText(item.body);
                toast.success('Message copié dans le presse-papier');
              }}
            >
              <p className="text-gray-700">{item.body}</p>
            </div>
            {!!item.hint && (
              <blockquote className="font-main text-main text-xs italic mt-2">
                {item.hint}
              </blockquote>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SuggestionPage;
