import React, { useMemo } from 'react';
import cx from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useFieldArray } from 'react-hook-form';
import { RiDeleteBinLine } from 'react-icons/ri';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { IoCalendarClearOutline } from 'react-icons/io5';
import { selectInputStatuses, selectInputRoles } from '@@/constants/user';
import { selectInputGenders, Genders } from '@@/constants/medicalRecord';
import StandardInput from '@@/components/Inputs/StandardInput';
import SelectInput from '@@/components/Inputs/SelectInput';
import SelectPractitionerContactInput from '@@/components/Inputs/SelectPractitionerContactInput';
import DatePickerInput from '@@/components/Inputs/DatePickerInput';
import PhonePickerInput from '@@/components/Inputs/PhonePickerInput';
import SectionTitle from '@@/components/SectionTitle';
import AuthenticatedImage from '@@/components/AuthenticatedImage';
import { formatId } from '@@/utils/string';

export type MedicalRecordFormType = Omit<
  Unpatient.MedicalRecord,
  'treatingDoctor' | 'specialists'
> & {
  treatingDoctor?: { id: string; label: string };
  specialists?: { id: string; label: string }[];
};
export type UserFormType = Omit<Unpatient.User, 'medicalRecord'> & {
  medicalRecord?: MedicalRecordFormType;
};

export const schema = yup
  .object({
    firstName: yup.string().required('Veuillez entrer le prénom du patient'),
    lastName: yup.string().required('Veuillez entrer le nom du patient'),
    email: yup
      .string()
      .email('Veuillez entrer un email valide')
      .required("Veuillez entrer l'email du patient"),
    phone: yup
      .string()
      .required('Veuillez entrer le numéro de téléphone du patient'),
    status: yup.string(),
    address: yup.string(),
    city: yup.string(),
    postcode: yup.string(),
    country: yup.string(),
    appointmentPreferences: yup.string(),
    icsLink: yup.string().url('Veuillez entrer un lien valide'),
    medicalRecord: yup.object({
      socialSecurityNumber: yup
        .string()
        .test(
          'len',
          'Must be exactly 15 characters',
          (val?: string) => !val || val.length === 15,
        ),
      gender: yup.string(),
      birthDate: yup.date(),
      height: yup
        .number()
        .min(0)
        .max(300)
        .nullable(true)
        .transform((_, val?: number) => {
          if (val) {
            return Number(val);
          }
        }),
      weight: yup
        .number()
        .min(0)
        .max(600)
        .nullable(true)
        .transform((_, val?: number) => {
          if (val) {
            return Number(val);
          }
        }),
      profession: yup.string(),
      alcohol: yup.string(),
      smoker: yup.string(),
      exercise: yup.string(),
      alimentation: yup.string(),
      sleep: yup.string(),
      stress: yup.string(),
      social: yup.string(),
      surgeryHistory: yup.string(),
      longTermMedication: yup.string(),
      nonDrugTherapy: yup.string(),
      antecedents: yup.object({
        cardiovascular: yup.string(),
        neurology: yup.string(),
        pulmonology: yup.string(),
        nephrology: yup.string(),
        urology: yup.string(),
        digestion: yup.string(),
        hematology: yup.string(),
        rheumatology: yup.string(),
        orthopedics: yup.string(),
        dermatology: yup.string(),
        endocrinology: yup.string(),
        gynecology: yup.string(),
        ent: yup.string(),
        infectiousDiseases: yup.string(),
        ophthalmology: yup.string(),
        allergies: yup.string(),
        dental: yup.string(),
        psychiatry: yup.string(),
        other: yup.string(),
      }),
      familyAntecedents: yup.object({
        father: yup.string(),
        mother: yup.string(),
        children: yup.string(),
        brother: yup.string(),
        sister: yup.string(),
        other: yup.string(),
      }),
      treatingDoctor: yup.object({
        id: yup.string(),
        label: yup.string(),
      }),
      specialists: yup.array().of(
        yup.object({
          id: yup.string().required(),
          label: yup.string(),
        }),
      ),
      vaccines: yup.array().of(
        yup.object({
          name: yup.string().required(),
          date: yup.date(),
        }),
      ),
    }),
  })
  .required();

export const PatientProfileForm: React.FC<{
  form: any;
}> = ({ form }) => {
  const {
    register,
    control,
    watch,
    formState: { errors },
  } = form;

  const [photoInput] = watch(['photo']);

  const photo: string = useMemo(() => {
    if (typeof photoInput === 'string') {
      return photoInput;
    }
    if (photoInput?.[0] instanceof File) {
      return URL.createObjectURL(photoInput[0]);
    }
    return '/user-profile-placeholder.jpg';
  }, [photoInput]);

  return (
    <form className="">
      <SectionTitle title="Etat Civil" className="mb-10" />
      <div className="mb-12 flex flex-row items-center">
        <label htmlFor="photo">
          <AuthenticatedImage
            className="object-cover h-24 w-24 sm:h-40 sm:w-40 bg-white rounded-full"
            alt="patient-photo"
            placeholder={photo}
          />

          <input
            {...register('photo')}
            type="file"
            id="photo"
            className="hidden"
          />
        </label>

        <div className="flex flex-col w-fit">
          <div className="flex flex-row">
            <div className="grow-0 mx-8 -mt-6">
              <SelectInput
                register={register}
                id="medicalRecord.gender"
                label="Genre"
                options={selectInputGenders}
              />
            </div>
            <div className="grow-0 mx-8">
              <StandardInput
                register={register}
                id="firstName"
                label="Prénom (*)"
                type="text"
                placeholder="Prénom (*)"
                error={errors.firstName}
              />
            </div>
            <div className="grow-0">
              <StandardInput
                register={register}
                id="lastName"
                label="Nom (*)"
                type="text"
                placeholder="Nom (*)"
                error={errors.lastName}
              />
            </div>
          </div>

          <div className="flex flex-row">
            <div className="mx-8 mt-3">
              <SelectInput
                register={register}
                id="role"
                label="Rôle"
                options={selectInputRoles}
              />
            </div>
            <div className="mt-10 ml-8">
              <DatePickerInput
                id="medicalRecord.birthDate"
                control={control}
                label="Date de naissance"
              />
            </div>
          </div>
        </div>
      </div>

      <SectionTitle title="Admin" className="mt-8 mb-12" />
      <div className="grid grid-cols-3 gap-12">
        <StandardInput
          register={register}
          id="email"
          label="Email (*)"
          type="text"
          placeholder="Email (*)"
          error={errors.email}
        />
        <PhonePickerInput
          control={control}
          id="phone"
          error={errors.phone}
          label="Téléphone (*)"
        />

        <div className="-mt-8 w-48">
          <SelectInput
            register={register}
            id="status"
            label="Statut"
            options={selectInputStatuses}
          />
        </div>

        <StandardInput
          register={register}
          id="address"
          label="Adresse"
          type="text"
          placeholder="Adresse"
          error={errors.address}
        />
        <StandardInput
          register={register}
          id="postcode"
          label="Code postal"
          type="text"
          placeholder="Code postal"
          error={errors.postcode}
        />
        <StandardInput
          register={register}
          id="city"
          label="Ville"
          type="text"
          placeholder="Ville"
          error={errors.city}
        />
        <StandardInput
          register={register}
          id="country"
          label="Pays"
          type="text"
          placeholder="Pays"
          error={errors.country}
        />

        <StandardInput
          register={register}
          id="medicalRecord.socialSecurityNumber"
          label="Numéro de sécurité sociale"
          type="text"
          placeholder="Numéro de sécurité sociale"
          error={errors.medicalRecord?.socialSecurityNumber}
        />
      </div>
    </form>
  );
};

export const PatientPreferrencesForm: React.FC<{
  form: ReturnType<typeof useForm<UserFormType>>;
  userId: string;
}> = ({ form, userId }) => {
  const {
    register,
    formState: { errors },
    watch,
  } = form;

  const icsLink = watch('icsLink');

  return (
    <form className="">
      <div className="mt-12">
        <StandardInput
          register={register}
          id="appointmentPreferences"
          label="Préferences de rendez-vous"
          type="textarea"
          rows={4}
          placeholder="Préferences de rendez-vous"
          error={errors.appointmentPreferences}
        />
      </div>
      <div className="mt-8">
        <StandardInput
          register={register}
          id="icsLink"
          label="Calendrier ICS"
          type="text"
          placeholder="Lien du calendrier ICS"
          error={errors.icsLink}
        />
      </div>
      {icsLink && (
        <Link
          className="mt-4 flex flex-row items-center"
          target="_blank"
          rel="noopener noreferrer"
          to={`/patients/${userId}/ics`}
        >
          <IoCalendarClearOutline size={20} className="text-main mr-1" />
          <span className="italic underline font-main text-main text-sm">
            Lien vers le calendrier ICS
          </span>
        </Link>
      )}
    </form>
  );
};

const SummarySection = ({
  title,
  subTitles,
  hash,
}: {
  title: string;
  subTitles: string[];
  hash?: string;
}) => {
  const isSectionHighlighted = useMemo(() => {
    return (
      hash === formatId(title) ||
      subTitles.some((s) => !!s && hash === formatId(s))
    );
  }, [hash, title]);

  return (
    <div className="flex flex-col mb-1">
      <Link
        className={cx(
          'font-bold text-xs',
          isSectionHighlighted ? 'text-sky-500' : 'text-slate-700',
        )}
        to={`#medical-info@${formatId(title)}`}
      >
        {title}
      </Link>
      {subTitles.map((subTitle, index) => {
        const isSubSectionSectionHighlighted =
          !!subTitle && hash === formatId(subTitle);

        return (
          <Link
            key={index}
            className={cx(
              'text-[10px] pl-1',
              isSubSectionSectionHighlighted
                ? 'text-sky-500 border-l border-sky-500'
                : 'text-slate-500',
            )}
            to={`#medical-info@${formatId(subTitle || '')}`}
          >
            {subTitle}
          </Link>
        );
      })}
    </div>
  );
};

export const PatientMedicalForm: React.FC<{
  form: ReturnType<typeof useForm<UserFormType>>;
}> = ({ form }) => {
  const {
    register,
    control,
    watch,
    formState: { errors },
  } = form;

  const [gender, weight, height] = watch([
    'medicalRecord.gender',
    'medicalRecord.weight',
    'medicalRecord.height',
  ]);

  const imc = useMemo(() => {
    return weight && height
      ? Math.round((weight / (height * height)) * 10000 * 100) / 100
      : null;
  }, [weight, height]);

  const {
    fields: vaccines,
    append: appendVaccine,
    remove: removeVaccine,
  } = useFieldArray({
    control,
    name: 'medicalRecord.vaccines',
  });

  const location = useLocation();

  const subHash = useMemo(
    () => location.hash.split('@')?.[1],
    [location?.hash],
  );

  return (
    <div className="relative flex flex-row h-[800px]">
      <div className="w-[180px]">
        <SummarySection
          hash={subHash}
          title="Traitements"
          subTitles={[
            'Chirurgies passées',
            'Traitements à long terme',
            'Traitements non médicamenteux',
          ]}
        />
        <SummarySection
          hash={subHash}
          title="Antécédents personnels"
          subTitles={[
            'Cardio-vasculaire',
            'Neurologie',
            'Pneumologie',
            'Néphrologie',
            'Urologie',
            'Système digestif',
            'Hématologie',
            'Rhumatologie',
            'Orthopédie',
            'Dermatologie',
            'Endocrinologie',
            'Gynécologie',
            'ORL',
            'Maladies Infectieuses',
            'Ophtalmologie',
            'Allergies',
            'Dentaire',
            'Santé mentale',
            'Autre',
          ]}
        />
        <SummarySection
          hash={subHash}
          title="Antécédents familiaux"
          subTitles={['Père', 'Mère', 'Enfants', 'Frères', 'Soeurs', 'Autre']}
        />
        <SummarySection
          hash={subHash}
          title="Observations"
          subTitles={['Générales', 'Privées']}
        />
        <SummarySection
          hash={subHash}
          title="Situation personnelle et habitudes"
          subTitles={[
            'Taille',
            'Poids',
            'IMC',
            'Profession',
            'Alcool',
            'Tabac',
            'Activité physique',
            'Alimentation',
            'Sommeil',
            'Gestion du stress',
            'Entourage social',
            'Autre',
          ]}
        />
        <SummarySection
          hash={subHash}
          title="Vacccins"
          subTitles={vaccines?.map((v) => v.name) || []}
        />
      </div>
      <form className="w-[calc(100%-180px)] overflow-y-scroll">
        <SectionTitle title="Traitements" className="mb-10" />
        <div className="grid grid-cols-1 gap-10">
          <StandardInput
            register={register}
            id="medicalRecord.surgeryHistory"
            label="Chirurgies passées"
            type="textarea"
            placeholder="Chirurgies passées"
            error={errors.medicalRecord?.surgeryHistory}
          />

          <StandardInput
            register={register}
            id="medicalRecord.longTermMedication"
            label="Traitements à long terme"
            type="textarea"
            placeholder="Traitements à long terme"
            error={errors.medicalRecord?.longTermMedication}
          />

          <StandardInput
            register={register}
            id="medicalRecord.nonDrugTherapy"
            label="Traitements non médicamenteux"
            type="textarea"
            placeholder="Traitements non médicamenteux"
            error={errors.medicalRecord?.nonDrugTherapy}
          />
        </div>

        <SectionTitle title="Antécédents personnels" className="my-10" />
        <div className="grid grid-cols-1 gap-10">
          <StandardInput
            register={register}
            id="medicalRecord.antecedents.cardiovascular"
            label="Cardio-vasculaire"
            type="textarea"
            placeholder="Cardio-vasculaire"
            error={errors.medicalRecord?.antecedents?.cardiovascular}
          />
          <StandardInput
            register={register}
            id="medicalRecord.antecedents.neurology"
            label="Neurologie"
            type="textarea"
            placeholder="Neurologie"
            error={errors.medicalRecord?.antecedents?.neurology}
          />
          <StandardInput
            register={register}
            id="medicalRecord.antecedents.pulmonology"
            label="Pneumologie"
            type="textarea"
            placeholder="Pneumologie"
            error={errors.medicalRecord?.antecedents?.pulmonology}
          />
          <StandardInput
            register={register}
            id="medicalRecord.antecedents.nephrology"
            label="Néphrologie"
            type="textarea"
            placeholder="Néphrologie"
            error={errors.medicalRecord?.antecedents?.nephrology}
          />
          <StandardInput
            register={register}
            id="medicalRecord.antecedents.urology"
            label="Urologie"
            type="textarea"
            placeholder="Urologie"
            error={errors.medicalRecord?.antecedents?.urology}
          />
          <StandardInput
            register={register}
            id="medicalRecord.antecedents.digestion"
            label="Système digestif"
            type="textarea"
            placeholder="Système digestif"
            error={errors.medicalRecord?.antecedents?.digestion}
          />
          <StandardInput
            register={register}
            id="medicalRecord.antecedents.hematology"
            label="Hématologie"
            type="textarea"
            placeholder="Hématologie"
            error={errors.medicalRecord?.antecedents?.hematology}
          />
          <StandardInput
            register={register}
            id="medicalRecord.antecedents.rheumatology"
            label="Rhumatologie"
            type="textarea"
            placeholder="Rhumatologie"
            error={errors.medicalRecord?.antecedents?.rheumatology}
          />
          <StandardInput
            register={register}
            id="medicalRecord.antecedents.orthopedics"
            label="Orthopédie"
            type="textarea"
            placeholder="Orthopédie"
            error={errors.medicalRecord?.antecedents?.orthopedics}
          />
          <StandardInput
            register={register}
            id="medicalRecord.antecedents.dermatology"
            label="Dermatologie"
            type="textarea"
            placeholder="Dermatologie"
            error={errors.medicalRecord?.antecedents?.dermatology}
          />
          <StandardInput
            register={register}
            id="medicalRecord.antecedents.endocrinology"
            label="Endocrinologie"
            type="textarea"
            placeholder="Endocrinologie"
            error={errors.medicalRecord?.antecedents?.endocrinology}
          />

          {gender !== Genders.M && (
            <StandardInput
              register={register}
              id="medicalRecord.antecedents.gynecology"
              label="Gynécologie"
              type="textarea"
              placeholder="Gynécologie"
              error={errors.medicalRecord?.antecedents?.gynecology}
            />
          )}

          <StandardInput
            register={register}
            id="medicalRecord.antecedents.ent"
            label="ORL"
            type="textarea"
            placeholder="ORL"
            error={errors.medicalRecord?.antecedents?.ent}
          />
          <StandardInput
            register={register}
            id="medicalRecord.antecedents.infectiousDiseases"
            label="Maladies Infectieuses"
            type="textarea"
            placeholder="Maladies Infectieuses"
            error={errors.medicalRecord?.antecedents?.infectiousDiseases}
          />
          <StandardInput
            register={register}
            id="medicalRecord.antecedents.ophthalmology"
            label="Ophtalmologie"
            type="textarea"
            placeholder="Ophtalmologie"
            error={errors.medicalRecord?.antecedents?.ophthalmology}
          />
          <StandardInput
            register={register}
            id="medicalRecord.antecedents.allergies"
            label="Allergies"
            type="textarea"
            placeholder="Allergies"
            error={errors.medicalRecord?.antecedents?.allergies}
          />
          <StandardInput
            register={register}
            id="medicalRecord.antecedents.dental"
            label="Dentaire"
            type="textarea"
            placeholder="Dentaire"
            error={errors.medicalRecord?.antecedents?.dental}
          />
          <StandardInput
            register={register}
            id="medicalRecord.antecedents.psychiatry"
            label="Santé mentale"
            type="textarea"
            placeholder="Santé mentale"
            error={errors.medicalRecord?.antecedents?.psychiatry}
          />
          <StandardInput
            register={register}
            id="medicalRecord.antecedents.other"
            label="Autre"
            type="textarea"
            placeholder="Autre"
            error={errors.medicalRecord?.antecedents?.other}
          />
        </div>

        <SectionTitle title="Antécédents familiaux" className="my-10" />
        <div className="grid grid-cols-1 gap-10">
          <StandardInput
            register={register}
            id="medicalRecord.familyAntecedents.father"
            label="Père"
            type="textarea"
            placeholder="Père"
            error={errors.medicalRecord?.familyAntecedents?.father}
          />
          <StandardInput
            register={register}
            id="medicalRecord.familyAntecedents.mother"
            label="Mère"
            type="textarea"
            placeholder="Mère"
            error={errors.medicalRecord?.familyAntecedents?.mother}
          />
          <StandardInput
            register={register}
            id="medicalRecord.familyAntecedents.children"
            label="Enfants"
            type="textarea"
            placeholder="Enfants"
            error={errors.medicalRecord?.familyAntecedents?.children}
          />
          <StandardInput
            register={register}
            id="medicalRecord.familyAntecedents.brother"
            label="Frères"
            type="textarea"
            placeholder="Frères"
            error={errors.medicalRecord?.familyAntecedents?.brother}
          />
          <StandardInput
            register={register}
            id="medicalRecord.familyAntecedents.sister"
            label="Soeurs"
            type="textarea"
            placeholder="Soeurs"
            error={errors.medicalRecord?.familyAntecedents?.sister}
          />
          <StandardInput
            register={register}
            id="medicalRecord.familyAntecedents.other"
            label="Autre"
            type="textarea"
            placeholder="Autre"
            error={errors.medicalRecord?.familyAntecedents?.other}
          />
        </div>

        <SectionTitle title="Observations" className="my-10" />
        <StandardInput
          register={register}
          id="medicalRecord.observations"
          label="Observations"
          type="textarea"
          placeholder="Observations"
          error={errors.medicalRecord?.observations}
        />
        <div className="mt-10">
          <StandardInput
            register={register}
            id="medicalRecord.privateNotes"
            label="Notes privées"
            type="textarea"
            placeholder="Notes privées"
            error={errors.medicalRecord?.privateNotes}
          />
        </div>

        <SectionTitle
          title="Situation personnelle et habitudes"
          className="mt-8 mb-12"
        />
        <div className="grid grid-cols-1 gap-10">
          <StandardInput
            register={register}
            id="medicalRecord.height"
            label="Taille (cm)"
            type="number"
            placeholder="Taille"
            error={errors.medicalRecord?.height}
          />
          <StandardInput
            register={register}
            id="medicalRecord.weight"
            label="Poids (kg)"
            type="number"
            placeholder="Poids"
            error={errors.medicalRecord?.weight}
          />
          {!!imc && (
            <p className="font-main text-main mt-2">
              <span className="font-semibold mr-1">IMC :</span>
              {imc}
            </p>
          )}

          <StandardInput
            register={register}
            id="medicalRecord.profession"
            label="Profession"
            type="textarea"
            placeholder="Profession"
            error={errors.medicalRecord?.profession}
          />
          <StandardInput
            register={register}
            id="medicalRecord.alcohol"
            label="Alcool"
            type="textarea"
            placeholder="Alcool"
            error={errors.medicalRecord?.alcohol}
          />
          <StandardInput
            register={register}
            id="medicalRecord.smoker"
            label="Tabac"
            type="textarea"
            placeholder="Tabac"
            error={errors.medicalRecord?.smoker}
          />
          <StandardInput
            register={register}
            id="medicalRecord.exercise"
            label="Activité physique"
            type="textarea"
            placeholder="Activité physique"
            error={errors.medicalRecord?.exercise}
          />
          <StandardInput
            register={register}
            id="medicalRecord.alimentation"
            label="Alimentation"
            type="textarea"
            placeholder="Alimentation"
            error={errors.medicalRecord?.alimentation}
          />
          <StandardInput
            register={register}
            id="medicalRecord.sleep"
            label="Sommeil"
            type="textarea"
            placeholder="Sommeil"
            error={errors.medicalRecord?.sleep}
          />
          <StandardInput
            register={register}
            id="medicalRecord.stress"
            label="Gestion du stress"
            type="textarea"
            placeholder="Gestion du stress"
            error={errors.medicalRecord?.stress}
          />
          <StandardInput
            register={register}
            id="medicalRecord.social"
            label="Entourage social"
            type="textarea"
            placeholder="Entourage social"
            error={errors.medicalRecord?.social}
          />
          <StandardInput
            register={register}
            id="medicalRecord.otherSituation"
            label="Autre"
            type="textarea"
            placeholder="Autre"
            error={errors.medicalRecord?.otherSituation}
          />
        </div>

        <SectionTitle title="Vaccins" className="mt-8 mb-12" />
        <div className="flex flex-col mt-6 w-fit">
          <ul className="flex flex-col space-y-12">
            {vaccines.map((field, index) => (
              <div
                key={index}
                id={field?.name ? formatId(field.name) : ''}
                className="flex flex-row items-center space-x-4"
              >
                <div className="grow-0">
                  <StandardInput
                    register={register}
                    id={`medicalRecord.vaccines.${index}.name`}
                    label={`Vaccin ${index + 1}`}
                    placeholder="Nom du vaccin"
                    type="text"
                  />
                </div>

                <div className="grow-0">
                  <DatePickerInput
                    id={`medicalRecord.vaccines.${index}.date`}
                    control={control}
                    label="Date de vaccination"
                  />
                </div>
                <button type="button" onClick={() => removeVaccine(index)}>
                  <RiDeleteBinLine size={24} />
                </button>
              </div>
            ))}
          </ul>

          <button
            type="button"
            className="mt-6 p-1.5 bg-sky-600 text-white rounded-lg flex flex-row items-center w-fit"
            onClick={() => appendVaccine({} as Unpatient.Vaccine)}
          >
            <IoIosAddCircleOutline size={20} />
            <span className="ml-0.5">Ajouter un vaccin</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export const PatientMedicalContactsForm: React.FC<{
  form: any;
}> = ({ form }) => {
  const { control, watch, setValue } = form;

  const [treatingDoctor, specialistIds] = watch([
    'medicalRecord.treatingDoctor',
    'medicalRecord.specialists',
  ]);

  const {
    fields: specialists,
    append: appendSpecialist,
    remove: removeSpecialist,
  } = useFieldArray({
    control,
    name: 'medicalRecord.specialists',
  });

  return (
    <form className="">
      <SectionTitle title="Médecin traitant" className="mb-4" />
      <div className="flex flex-row items-center w-96">
        <SelectPractitionerContactInput
          control={control}
          values={treatingDoctor}
          id="medicalRecord.treatingDoctor"
          label="Médecin traitant"
          placeholder="Choisissez un médecin traitant"
        />

        {!!treatingDoctor?.id && (
          <Link
            className="ml-4 mt-5 italic underline font-main text-main text-xs"
            target="_blank"
            rel="noopener noreferrer"
            to={`/practitioner-contacts/${treatingDoctor.id}`}
          >
            Lien
          </Link>
        )}

        {!!treatingDoctor && (
          <button
            type="button"
            onClick={() => {
              setValue('medicalRecord.treatingDoctor', '');
            }}
            className="ml-6 mt-5"
          >
            <RiDeleteBinLine
              size={24}
              className="bg-red-500 p-1 rounded-full text-white"
            />
          </button>
        )}
      </div>

      <div className="flex flex-col mt-8">
        <SectionTitle title="Praticiens spécialistes" className="mb-4" />
        <ul className="flex flex-col space-y-6">
          {specialists.map((field: any, index: number) => (
            <div key={index} className="flex flex-row items-center w-96">
              <SelectPractitionerContactInput
                control={control}
                id={`medicalRecord.specialists[${index}]`}
                values={specialistIds?.[index]}
                label={`Praticien spécialiste #${index + 1}`}
                placeholder="Choisissez un praticien spécialiste"
              />

              {!!specialistIds?.[index] && (
                <Link
                  className="ml-4 mt-5 italic underline font-main text-main text-xs"
                  target="_blank"
                  rel="noopener noreferrer"
                  to={`/practitioner-contacts/${specialistIds?.[index].id}`}
                >
                  Lien
                </Link>
              )}

              <button
                type="button"
                onClick={() => removeSpecialist(index)}
                className="ml-6 mt-5"
              >
                <RiDeleteBinLine
                  size={24}
                  className="bg-red-500 p-1 rounded-full text-white"
                />
              </button>
            </div>
          ))}
        </ul>

        <button
          type="button"
          className="mt-6 p-1.5 bg-sky-600 text-white rounded-md flex flex-row items-center w-fit"
          onClick={() => appendSpecialist({} as Unpatient.PractitionerContact)}
        >
          <IoIosAddCircleOutline size={20} />
          <span className="ml-0.5">Ajouter un spécialiste</span>
        </button>
      </div>
    </form>
  );
};
