import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import toast from 'react-hot-toast';
import debounce from 'lodash.debounce';
import omit from 'lodash.omit';
import isEqual from 'lodash.isequal';
import cx from 'classnames';
import {
  useCreateTaskMutation,
  usePatchTaskMutation,
  useGetTaskQuery,
} from '@@/services/task';
import { TaskStatuses } from '@@/constants/task';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import TaskForm, { schema } from '@@/components/Forms/TaskForm';
import { getPractitionerContactLabel } from '@@/components/Inputs/SelectPractitionerContactInput';

const NEW_TASK = 'new';

const emptyPayload = {
  patientId: { id: '', label: '' },
  practitionerContactId: { id: '', label: '' },
  status: undefined,
  type: null,
  deadline: undefined,
  eventDate: undefined,
  eventId: undefined,
  ownerId: '67b23e8db094cf7dbc89b4bc', // Lucille
  request: '',
  response: '',
};

type TaskFormType = Omit<
  Unpatient.Task,
  'practitionerContactId' | 'patientId' | 'ownerId'
> & {
  patientId: { id: string; label: string };
  practitionerContactId: { id: string; label: string };
  ownerId: string;
};

const Task: React.FC<{ taskId: string }> = ({ taskId }) => {
  const [createTask, { isSuccess }] = useCreateTaskMutation();

  const [patchTask, { isSuccess: isPatchSuccess }] = usePatchTaskMutation();

  const isNewTask = useMemo(() => taskId === NEW_TASK, [taskId]);

  const { data: task } = useGetTaskQuery(taskId || '', {
    skip: !taskId || isNewTask,
  });

  const form = useForm<TaskFormType>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: {
      // @ts-ignore
      ownerId: '67b23e8db094cf7dbc89b4bc', // Lucille
      status: TaskStatuses.TO_DO,
    },
  });

  const onSubmit = useCallback(
    async (data: TaskFormType) => {
      if (data.id) {
        patchTask({
          ...data,
          patientId: data.patientId?.id,
          practitionerContactId: data.practitionerContactId?.id,
        });
      } else {
        createTask({
          ...data,
          patientId: data.patientId?.id,
          practitionerContactId: data.practitionerContactId?.id,
        });
      }
    },
    [createTask, patchTask],
  );

  useEffect(() => {
    if (task?.id) {
      const data = omit(task, [
        '_id',
        'createdAt',
        'updatedAt',
        'creatorId',
        'ownerId',
        'patientId',
        'practitionerContactId',
        'eventId',
        'documentId',
        'prescriptionId',
        'referralId',
      ]) as any;

      if (task?.practitionerContactId) {
        data.practitionerContactId = {
          id: task.practitionerContactId.id,
          label: getPractitionerContactLabel(task.practitionerContactId),
        };
      }

      if (task?.patientId) {
        data.patientId = {
          id: task.patientId.id,
          label: `${task.patientId.firstName} ${task.patientId.lastName}`,
        };
      }

      if (task?.ownerId) {
        data.ownerId = task.ownerId.id;
      }

      if (task?.eventId) {
        data.eventId = task.eventId.id;
      }

      if (task?.documentId) {
        data.documentId = task.documentId.id;
      }

      if (task?.prescriptionId) {
        data.prescriptionId = task.prescriptionId.id;
      }

      if (task?.referralId) {
        data.referralId = task.referralId.id;
      }

      form.reset({
        ...emptyPayload,
        ...data,
      });
    }
  }, [task?.id, form.reset]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(`Votre tâche a bien été ajoutée`, {
        position: 'top-right',
      });

      form.reset(emptyPayload);
    }
  }, [isSuccess, form.reset]);

  useEffect(() => {
    if (isPatchSuccess) {
      toast.success(`La tâche a bien été modifiée`, {
        position: 'top-right',
      });
    }
  }, [isPatchSuccess]);

  const watchedValues = form.watch();
  const prevValuesRef = useRef<TaskFormType | undefined>();
  const debouncedMagicUpdate = useCallback(
    debounce((data: TaskFormType) => {
      if (task?.id) {
        patchTask({
          ...data,
          id: task.id,
          patientId: data.patientId?.id,
          practitionerContactId: data.practitionerContactId?.id,
        });
      }
    }, 1000),
    [task?.id, patchTask],
  );
  useEffect(() => {
    if (task?.id) {
      const prevValues = prevValuesRef.current;

      if (
        form.formState?.isDirty &&
        form.formState?.isValid &&
        !form.formState?.isSubmitting &&
        watchedValues?.id === task.id &&
        !isEqual(prevValues, watchedValues)
      ) {
        try {
          schema.validateSync(watchedValues);
          prevValuesRef.current = { ...watchedValues };
          debouncedMagicUpdate(watchedValues);
        } catch (e) {
          // do nothing
        }
      }
    }
  }, [
    task?.id,
    watchedValues,
    form?.formState?.isDirty,
    form.formState?.isValid,
    form.formState?.isSubmitting,
    debouncedMagicUpdate,
  ]);

  return (
    <div className="m-4">
      <TaskForm form={form} />

      {isNewTask && (
        <div className="flex flex-row justify-end">
          <button
            type="button"
            onClick={form.handleSubmit(onSubmit)}
            disabled={form.formState.isSubmitting}
            className={cx(
              'rounded-lg py-2 px-3 bg-sky-600  hover:bg-sky-700',
              form.formState.isSubmitting && 'opacity-50 cursor-not-allowed',
            )}
          >
            <span className="text-white">Valider</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default Task;
