import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { setConversation } from '@@/features/conversation';
import { Controller } from 'react-hook-form';
import { TiDelete } from 'react-icons/ti';
import {
  LuPaperclip,
  LuMessageSquareText,
  LuBot,
  LuClockArrowUp,
  LuVideo,
} from 'react-icons/lu';
import { useGetTemplateMessagesQuery } from '@@/services/templateMessage';
import { useGetMeQuery } from '@@/services/user';
import SelectInput from '@@/components/Inputs/SelectInput';
import { RootState } from '@@/store';

export const schema = yup
  .object({
    message: yup.string(),
  })
  .required();

const MessageForm: React.FC<{
  form: any;
  conversationId: string;
  cbSuggestions: () => void;
  onSubmit: (arg: any) => void;
  onDelayedSubmit: (arg: any) => void;
  handleGoToVideoLink: () => void;
  isSubmitButtonDisabled: boolean;
}> = ({
  form,
  conversationId,
  cbSuggestions,
  onSubmit,
  onDelayedSubmit,
  handleGoToVideoLink,
  isSubmitButtonDisabled,
}) => {
  const { data: me } = useGetMeQuery();
  const { data: templateMessages } = useGetTemplateMessagesQuery();
  const {
    register,
    control,
    watch,
    setValue,
    formState: { errors },
  } = form;

  const dispatch = useDispatch();
  const trackCurrentConversation = useCallback(
    (message: string) => {
      dispatch(setConversation({ message, id: conversationId }));
      form.setValue('message', message);
    },
    [dispatch, conversationId],
  );

  const files = watch('files');
  const message = useSelector(
    (state: RootState) => state.conversation[conversationId]?.message ?? '',
  );

  const [showMessageTemplateSelection, setShowMessageTemplateSelection] =
    useState(false);
  const toggleShowMessageTemplateSelection = useCallback(() => {
    setShowMessageTemplateSelection((prev) => !prev);
  }, [setShowMessageTemplateSelection]);

  return (
    <div className="mx-4">
      <Controller
        name="message"
        control={control}
        render={({ field }) => {
          return (
            <div className="relative w-full">
              <textarea
                {...field}
                value={message}
                onChange={(event) => {
                  trackCurrentConversation(event.target?.value);
                }}
                id="message"
                rows={5}
                placeholder="Nouveau message"
                className="peer block w-full ring-1 ring-gray-200 shadow-sm py-3 px-4 border-slate-200 rounded-md placeholder-transparent text-slate-700 text-sm"
              />

              <label
                id="message"
                htmlFor="message"
                className="absolute pointer-events-none font-main text-slate-300 text-sm -top-7 left-0 transition-all peer-focus:-top-6 peer-focus:left-0 peer-focus:text-sm peer-placeholder-shown:top-3 peer-placeholder-shown:left-3.5 peer-placeholder-shown:text-base"
              >
                Nouveau message
              </label>
              {errors?.message && (
                <p className="italic font-main text-sm text-red-500 m-1">
                  {errors.message}
                </p>
              )}
            </div>
          );
        }}
      />

      <div className="flex flex-row items-center justify-between mt-2">
        <div className="flex flex-row items-center space-x-2">
          <label htmlFor="files" className="cursor-pointer">
            <LuPaperclip size={16} className="text-slate-600" />

            <Controller
              control={control}
              name="files"
              render={({ field }) => {
                return (
                  <input
                    {...field}
                    value={field.value?.fileName}
                    onChange={(event) => {
                      field.onChange(event.target?.files);
                    }}
                    type="file"
                    id="files"
                    className="hidden"
                  />
                );
              }}
            />
          </label>

          <button className="" onClick={cbSuggestions}>
            <LuBot size={16} className="text-slate-600" />
          </button>

          {!!templateMessages?.length && (
            <button onClick={toggleShowMessageTemplateSelection}>
              <LuMessageSquareText size={16} />
            </button>
          )}

          {!!me?.videoRoomLink && (
            <button className="text-slate-600" onClick={handleGoToVideoLink}>
              <LuVideo size={16} />
            </button>
          )}
        </div>

        <div className="flex flex-row items-center space-x-2">
          <button
            className="text-slate-600"
            disabled={isSubmitButtonDisabled}
            onClick={onDelayedSubmit}
          >
            <LuClockArrowUp size={16} />
          </button>
          <button
            onClick={onSubmit}
            disabled={isSubmitButtonDisabled}
            className="p-2 bg-emerald-700 rounded-lg"
          >
            <span className="text-white">Envoyer</span>
          </button>
        </div>
      </div>

      {!!templateMessages?.length && showMessageTemplateSelection && (
        <SelectInput
          register={register}
          id="template"
          options={[{ id: '', label: 'Sélectionnez un message' }].concat(
            templateMessages.map((message) => ({
              id: message.body,
              label: message.title,
            })),
          )}
          callback={(message: string) => {
            trackCurrentConversation(message);
            form.setValue('template', '');
          }}
        />
      )}

      <div className="my-2">
        {!!files?.length &&
          Array.from(files).map((file: any, i) => (
            <div key={i} className="flex flex-row items-center">
              <p className="text-slate-700 text-sm">{file.name}</p>
              <button
                className="ml-4"
                onClick={() => setValue('files', undefined)}
              >
                <TiDelete className="text-red-500" size={20} />
              </button>
            </div>
          ))}
      </div>
    </div>
  );
};

export default MessageForm;
