import React, { useEffect, useCallback } from 'react';
import toast from 'react-hot-toast';
import { useParams, Link } from 'react-router-dom';
import cx from 'classnames';
import {
  useGetEncounterNoteQuery,
  usePatchEncounterNoteMutation,
} from '@@/services/encounterNote';
import { EncounterNoteTypes } from '@@/constants/document';
import { IoIosArrowRoundBack } from 'react-icons/io';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import EncouterNoteForm, {
  schema,
} from '@@/components/Forms/EncounterNoteForm';

type FormType = {
  CUSTOM__CHIEF_COMPLAINT: string;
  CUSTOM__OVERVIEW?: string;
  CUSTOM__MEDICAL_FOLLOW_UP?: string;
  CUSTOM__PATIENT_CONCERNS?: string;
  CUSTOM__MEDICAL_HISTORY?: string;
  CUSTOM__SURGICAL_HISTORY?: string;
  CUSTOM__FAMILY_HISTORY?: string;
  CUSTOM__LIFESTYLE?: string;
  CUSTOM__CURRENT_TREATMENTS?: string;
  CUSTOM__COMPLEMENTARY_EXAMS?: string;
  CUSTOM__ASSESSMENT: string;
  CUSTOM__PLAN: string;
};

const EncounterNotePage: React.FC = () => {
  const { encounterNoteId } = useParams();
  const { data: encounterNote } = useGetEncounterNoteQuery(
    encounterNoteId || '',
    {
      skip: !encounterNoteId,
    },
  );

  const [patchEncounterNote, { isSuccess: isPatchSuccess }] =
    usePatchEncounterNoteMutation();

  useEffect(() => {
    if (isPatchSuccess) {
      toast.success('Le compte rendu a bien été mis à jour');
    }
  }, [isPatchSuccess]);

  const form = useForm<FormType>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  useEffect(() => {
    if (encounterNote?.id) {
      form.reset({
        CUSTOM__CHIEF_COMPLAINT:
          encounterNote.CUSTOM__CHIEF_COMPLAINT ||
          encounterNote?.note?.sections?.find(
            (section) => section.key === 'CHIEF_COMPLAINT',
          )?.text,
        CUSTOM__ASSESSMENT:
          encounterNote.CUSTOM__ASSESSMENT ||
          encounterNote?.note?.sections?.find(
            (section) => section.key === 'ASSESSMENT',
          )?.text,
        CUSTOM__PLAN:
          encounterNote.CUSTOM__PLAN ||
          encounterNote?.note?.sections?.find(
            (section) => section.key === 'PLAN',
          )?.text,
        CUSTOM__MEDICAL_HISTORY: encounterNote.CUSTOM__MEDICAL_HISTORY,
        CUSTOM__SURGICAL_HISTORY: encounterNote.CUSTOM__SURGICAL_HISTORY,
        CUSTOM__FAMILY_HISTORY: encounterNote.CUSTOM__FAMILY_HISTORY,
        CUSTOM__LIFESTYLE: encounterNote.CUSTOM__LIFESTYLE,
        CUSTOM__CURRENT_TREATMENTS: encounterNote.CUSTOM__CURRENT_TREATMENTS,
        CUSTOM__COMPLEMENTARY_EXAMS: encounterNote.CUSTOM__COMPLEMENTARY_EXAMS,
      });
    }
  }, [encounterNote?.id, form.reset]);

  const onSubmit = useCallback(
    (data: FormType) => {
      if (encounterNoteId) {
        patchEncounterNote({
          id: encounterNoteId,
          ...data,
        });
      }
    },
    [encounterNoteId, patchEncounterNote],
  );

  return (
    <div className="px-4 w-full">
      <Link
        className="p-2 my-2 flex flex-row justify-start"
        to={`/admin/encounter-notes#${encounterNoteId}`}
      >
        <IoIosArrowRoundBack size={20} />
        <span className="ml-1">Retour</span>
      </Link>

      <EncouterNoteForm
        form={form}
        isOnboarding={encounterNote?.type === EncounterNoteTypes.ONBOARDING}
      />

      <button
        type="button"
        onClick={form.handleSubmit(onSubmit)}
        disabled={form.formState.isSubmitting}
        className={cx(
          'block ml-auto my-2 rounded-lg p-2 bg-cyan-400 text-white',
          form.formState.isSubmitting && 'opacity-50 cursor-not-allowed',
        )}
      >
        <span className="ml-1">Valider</span>
      </button>
    </div>
  );
};

export default EncounterNotePage;
