import React, { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useAddScreeshotToUserDocumentsMutation } from '@@/services/document';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import StandardInput from '@@/components/Inputs/StandardInput';

const screenshotSchema = yup
  .object({
    url: yup.string().url().required('Veuillez entrer une URL valide'),
  })
  .required();

const Screenshot: React.FC<{ userId: string }> = ({ userId }) => {
  const [addScreeshot, { isSuccess }] =
    useAddScreeshotToUserDocumentsMutation();
  const addScreenshotToUserDocuments = useCallback(
    (data: { url: string }) => {
      if (userId) {
        addScreeshot({ userId, url: data.url });
      }
    },
    [addScreeshot, userId],
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    url: string;
  }>({
    resolver: yupResolver(screenshotSchema),
    mode: 'onBlur',
    defaultValues: {
      url: '',
    },
  });

  useEffect(() => {
    if (isSuccess) {
      toast.loading(
        `Screenshot en cours... Cela peut prendre quelques secondes`,
        {
          position: 'top-right',
          duration: 20000,
        },
      );
    }
  }, [isSuccess]);
  return (
    <div className="flex flex-row items-center">
      <div className="w-96">
        <StandardInput
          register={register}
          id="url"
          label="URL"
          type="text"
          placeholder="https://www.example.com"
          error={errors.url}
        />
      </div>
      <button
        className="bg-sky-600 rounded-lg py-2 px-3 hover:bg-sky-700 ml-4"
        onClick={handleSubmit(addScreenshotToUserDocuments)}
      >
        <span className="text-white">Capture d'écran</span>
      </button>
    </div>
  );
};

export default Screenshot;
