import { api } from '@@/services/api';

export const encounterNoteApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getEncounterNotes: builder.query<Nabla.EncounterNote[], void>({
      query: () => `encounter-notes`,
      providesTags: ['EncounterNotes'],
    }),
    getEncounterNote: builder.query<Nabla.EncounterNote, string>({
      query: (id) => `encounter-notes/${id}`,
      providesTags: ['EncounterNotes'],
    }),
    patchEncounterNote: builder.mutation<
      Nabla.EncounterNote,
      {
        id: string;
        patientId?: string;
        type?: string;
        CUSTOM__CHIEF_COMPLAINT?: string;
        CUSTOM__ASSESSMENT?: string;
        CUSTOM__PLAN?: string;
      }
    >({
      query: ({ id, ...body }) => {
        return {
          url: `/encounter-notes/${id}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['EncounterNotes'],
    }),
    deleteEncounterNote: builder.mutation<{}, string>({
      query: (id) => ({
        url: `encounter-notes/${id}`,
        method: 'DELETE',
        body: {},
      }),
      invalidatesTags: ['EncounterNotes'],
    }),
  }),
});

export const {
  useGetEncounterNotesQuery,
  useGetEncounterNoteQuery,
  useDeleteEncounterNoteMutation,
  usePatchEncounterNoteMutation,
} = encounterNoteApi;
