import isEqual from 'lodash.isequal';
import { api } from '@@/services/api';
import { Roles } from '@@/constants/user';
import { convertToFormData } from '@@/utils/formData';

type MedicalRecordFormType = Omit<
  Unpatient.MedicalRecord,
  'treatingDoctor' | 'specialists'
> & {
  treatingDoctor?: string;
  specialists?: string[];
};
type UserFormType = Omit<Unpatient.User, 'medicalRecord'> & {
  medicalRecord?: MedicalRecordFormType;
};

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMe: builder.query<Unpatient.User, void>({
      query: () => `users/me`,
      providesTags: ['Users'],
    }),
    getAllPatients: builder.query<Unpatient.User[], void>({
      query: () => `users?role=${Roles.PATIENT}`,
      providesTags: ['Users'],
    }),
    getPopulatedPatient: builder.query<Unpatient.User, string>({
      query: (patientId) => `/users/${patientId}?populate=true`,
      providesTags: ['Users'],
    }),
    searchPatients: builder.query<Unpatient.User[], string>({
      query: (s) => `/users/search?s=${s}&role=${Roles.PATIENT}`,
      providesTags: ['Users'],
    }),
    searchPatientMedicalRecord: builder.query<
      { key: string; txt: string }[],
      { userId: string; s: string }
    >({
      query: ({ userId, s }) => `/users/${userId}/medical-record/search?s=${s}`,
      providesTags: ['Users'],
    }),
    findPatientByPhone: builder.query<
      Unpatient.User,
      { phone: string; userId?: string }
    >({
      query: ({ phone, userId }) =>
        userId
          ? `/users/find-by-phone?phone=${phone}&id=${userId}&populate=true`
          : `/users/find-by-phone?phone=${phone}&populate=true`,
      providesTags: ['Users'],
    }),
    createPatient: builder.mutation<Unpatient.User, UserFormType>({
      query: ({ photo, ...body }) => {
        const formData = new FormData();

        if (photo) {
          formData.set('file', photo);
        }

        return {
          url: `/users`,
          method: 'POST',
          body: convertToFormData(body, formData),
          formData: true,
        };
      },
      invalidatesTags: ['Users'],
    }),
    patchUser: builder.mutation<Unpatient.User, UserFormType>({
      query: ({ id, photo, ...body }) => {
        const formData = new FormData();

        if (photo) {
          formData.set('file', photo);
        }

        return {
          url: `/users/${id}`,
          method: 'PATCH',
          body: convertToFormData(body, formData),
          formData: true,
        };
      },
      invalidatesTags: ['Users', 'Histories'],
    }),
    patchUserOptimistic: builder.mutation<Unpatient.User, UserFormType>({
      query: ({ id, photo, ...body }) => {
        const formData = new FormData();

        if (photo) {
          formData.set('file', photo);
        }

        return {
          url: `/users/${id}`,
          method: 'PATCH',
          body: convertToFormData(body, formData),
          formData: true,
        };
      },
      async onQueryStarted(
        { id, ...patch },
        { dispatch, getState, queryFulfilled },
      ) {
        const selectPopulatedPatient =
          userApi.endpoints.getPopulatedPatient.select(id);
        const currentCache = selectPopulatedPatient(getState()).data;

        const shouldRelyOnOptimisticUpdate =
          currentCache?.medicalRecord?.treatingDoctor?.id ===
            patch.medicalRecord?.treatingDoctor &&
          isEqual(
            currentCache?.medicalRecord?.specialists?.map((s) => s?.id),
            patch.medicalRecord?.specialists,
          );

        if (shouldRelyOnOptimisticUpdate) {
          const patchResult = dispatch(
            userApi.util.updateQueryData('getPopulatedPatient', id, (draft) => {
              Object.assign(draft, patch, {
                medicalRecord: {
                  ...draft.medicalRecord,
                  ...patch.medicalRecord,
                  treatingDoctor: draft.medicalRecord?.treatingDoctor,
                  specialists: draft.medicalRecord?.specialists,
                },
              });
            }),
          );

          try {
            await queryFulfilled;
            dispatch(userApi.util.invalidateTags(['Histories']));
          } catch (error) {
            patchResult.undo();

            throw error;
          }
        } else {
          await queryFulfilled;
          dispatch(userApi.util.invalidateTags(['Users', 'Histories']));
        }
      },
    }),
    handleSignature: builder.mutation<
      Unpatient.User,
      { id: string; blob: Blob }
    >({
      query: ({ id, blob }) => {
        const formData = new FormData();

        if (blob) {
          formData.set('file', blob);
        }

        return {
          url: `/users/${id}/signature`,
          method: 'POST',
          body: formData,
          formData: true,
        };
      },
      invalidatesTags: ['Users', 'Histories'],
    }),
    sendPaymentLink: builder.mutation<
      void,
      {
        userId: string;
        trialPeriodInDays?: number;
        coupon?: string;
        period: string;
      }
    >({
      query: ({ userId, ...body }) => {
        return {
          url: `/users/${userId}/send-payment-link`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [],
    }),
    getPaymentLink: builder.mutation<
      { message: string },
      {
        userId: string;
        trialPeriodInDays?: number;
        coupon?: string;
        period: string;
      }
    >({
      query: ({ userId, ...body }) => {
        return {
          url: `/users/${userId}/create-payment-link`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [],
    }),
    getAllPractitioners: builder.query<Unpatient.User[], void>({
      query: () => `users?role=${Roles.PRACTITIONER}`,
      providesTags: ['Practitioners'],
    }),
    fetchUserCalendar: builder.query<Unpatient.ICSEvent[], string>({
      query: (userId) => `users/${userId}/ics`,
      providesTags: ['ICS'],
    }),
  }),
});

export const {
  useGetMeQuery,
  useGetPopulatedPatientQuery,
  useGetAllPatientsQuery,
  useSearchPatientsQuery,
  useCreatePatientMutation,
  usePatchUserMutation,
  useHandleSignatureMutation,
  useSendPaymentLinkMutation,
  useFindPatientByPhoneQuery,
  useGetAllPractitionersQuery,
  useFetchUserCalendarQuery,
  useGetPaymentLinkMutation,
  useSearchPatientMedicalRecordQuery,
  usePatchUserOptimisticMutation,
} = userApi;
