import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import moment from 'moment';
import toast from 'react-hot-toast';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { RiDeleteBinLine } from 'react-icons/ri';
import { IoWarningOutline } from 'react-icons/io5';
import { LuUpload, LuSearch, LuFileText } from 'react-icons/lu';
import { IoMdUnlock, IoMdLock } from 'react-icons/io';
import { MdOutlineModeEdit } from 'react-icons/md';
import {
  useGetUserDocumentsQuery,
  useCreateUserDocumentsMutation,
  useDeleteDocumentMutation,
  usePatchDocumentMutation,
  useSearchUserDocumentsQuery,
} from '@@/services/document';
import { openDocumentEditionModal } from '@@/features/modal';
import { DocumentTypes, translateDocumentType } from '@@/constants/document';
import { MIN_SEARCH_LENGTH } from '@@/constants/search';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import StandardInput from '@@/components/Inputs/StandardInput';

import AuthenticatedImage from '@@/components/AuthenticatedImage';
import AuthenticatedLink from '@@/components/AuthenticatedLink';
import Loader from '@@/components/Loader';
import DocumentEditionModal from '@@/components/Modals/DocumentEditionModal';
import Screenshot from '@@/components/Screenshot';

const Document: React.FC<{ document: Unpatient.Document }> = ({ document }) => {
  const dispatch = useDispatch();
  const [isFocused, setIsFocused] = useState(false);
  const [showTranscriptFR, setShowTranscriptFR] = useState(false);
  const [
    deleteDocument,
    { isSuccess: isDeletionSuccess, isLoading: isDeletionLoading },
  ] = useDeleteDocumentMutation();

  const toggleShowTranscriptFR = useCallback(() => {
    if (!document.transcriptFR) {
      return;
    }

    setShowTranscriptFR((prev) => !prev);
  }, [setShowTranscriptFR, document.transcriptFR]);

  const onEdit = useCallback(() => {
    setIsFocused(true);
    setTimeout(() => {
      dispatch(openDocumentEditionModal());
    }, 0);
  }, [dispatch]);

  const onCloseModal = useCallback(() => {
    setIsFocused(false);
  }, [setIsFocused]);

  const onDelete = useCallback(() => {
    if (
      document?.id &&
      confirm('Souhaitez vous vraiment supprimer ce document ?')
    ) {
      deleteDocument(document.id);
    }
  }, [deleteDocument, document?.id]);

  useEffect(() => {
    if (isDeletionSuccess) {
      toast.success(`Le document a bien été supprimé`, {
        position: 'top-right',
      });
    }
  }, [isDeletionSuccess]);

  const creationDate = useMemo(
    () =>
      document.documentCreationDate
        ? moment(document.documentCreationDate).format('DD/MM/YYYY')
        : moment(document.createdAt).format('DD/MM/YYYY'),
    [document.documentCreationDate, document.createdAt],
  );

  const [
    patchDocument,
    { isSuccess: isPatchSuccess, isLoading: isPatchLoading },
  ] = usePatchDocumentMutation();

  const togglePrivacy = useCallback(() => {
    if (document?.id) {
      patchDocument({
        isPrivate: !document.isPrivate,
        documentId: document.id,
      });
    }
  }, [patchDocument, document]);

  useEffect(() => {
    if (isPatchSuccess) {
      toast.success(`La visiblité du document a bien été mise à jour`, {
        position: 'top-right',
      });
    }
  }, [isPatchSuccess]);

  return (
    <div className="w-full">
      <div className="flex flex-row items-center p-2 border-b border-gray-200 justify-between">
        {isFocused && (
          <DocumentEditionModal
            documentId={document.id}
            onClose={onCloseModal}
          />
        )}

        <AuthenticatedLink
          documentId={document.id}
          className="flex flex-row items-center"
        >
          {document.mimetype.includes('image') ? (
            <AuthenticatedImage
              documentId={document.id}
              alt={document.name}
              className="h-10 w-10 content-center object-cover rounded-lg"
            />
          ) : (
            <LuFileText className="text-sky-600 bg-sky-100 w-8 h-8 p-1 rounded-lg" />
          )}
          <div className="ml-1">
            <p className="text-sm">{document.label || document.name}</p>
            <p className="text-xs">{creationDate}</p>
          </div>
        </AuthenticatedLink>

        <div className="flex flex-row items-center space-x-1">
          {!!document.language && document.language !== 'und' && (
            <button
              className="w-6 h-6 bg-rose-500 rounded-lg flex items-center justify-center"
              onClick={toggleShowTranscriptFR}
            >
              <span className="text-white text-xs">{document.language}</span>
            </button>
          )}

          <button
            className=""
            onClick={togglePrivacy}
            disabled={isPatchLoading}
          >
            {document.isPrivate ? (
              <IoMdLock size={20} />
            ) : (
              <IoMdUnlock size={20} />
            )}
          </button>

          <button className="" onClick={onEdit}>
            <MdOutlineModeEdit size={20} />
          </button>

          <button className="" onClick={onDelete} disabled={isDeletionLoading}>
            <RiDeleteBinLine size={20} />
          </button>
        </div>
      </div>

      {showTranscriptFR && !!document.transcriptFR && (
        <div
          dangerouslySetInnerHTML={{ __html: document.transcriptFR }}
          className="m-4"
        />
      )}
    </div>
  );
};

const schema = yup
  .object({
    s: yup
      .string()
      .min(
        MIN_SEARCH_LENGTH,
        `La recherche doit contenir au moins ${MIN_SEARCH_LENGTH} caractères`,
      )
      .required('Veuillez entrer une recherche'),
  })
  .required();

const DocumentSection: React.FC<{ userId: string }> = ({ userId }) => {
  const { data: userDocuments } = useGetUserDocumentsQuery(userId, {
    skip: !userId,
  });
  const [createDocuments, { isSuccess, isLoading }] =
    useCreateUserDocumentsMutation();

  const onDrop = useCallback(
    (files: File[]) => {
      createDocuments({ userId, files });
    },
    [createDocuments, userId],
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const dragZone = useMemo(() => {
    if (isLoading) {
      return (
        <div className="flex flex-row items-center">
          <Loader />
          <span className="ml-1">chargement en cours...</span>
        </div>
      );
    }

    if (isDragActive) {
      return <p className="underline p-4">Déposez vos documents ici</p>;
    }

    return (
      <div className="flex flex-row items-center">
        <LuUpload size={16} />
        <span className="ml-1 underline">Ajoutez vos documents</span>
      </div>
    );
  }, [isLoading, isDragActive]);

  const { register, watch } = useForm<{
    s: string;
  }>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: {
      s: '',
    },
  });
  const s = watch('s');
  const { data: searchedDocuments } = useSearchUserDocumentsQuery(
    { s, userId },
    {
      skip: s?.length < MIN_SEARCH_LENGTH || !userId,
    },
  );

  useEffect(() => {
    if (isSuccess) {
      toast.success(`Les documents ont bien été ajoutés`, {
        position: 'top-right',
      });
    }
  }, [isSuccess]);

  const documents = useMemo(() => {
    if (s?.length < MIN_SEARCH_LENGTH) {
      return userDocuments;
    }

    return searchedDocuments;
  }, [searchedDocuments, userDocuments, s]);

  const sections = useMemo(() => {
    const res = [];

    for (const type of Object.values(DocumentTypes)) {
      const section = {
        type,
        documents: documents?.filter((doc) => doc.type === type) || [],
      };

      if (section.type === DocumentTypes.OTHER) {
        section.documents = section.documents.concat(
          documents?.filter((doc) => !doc.type) || [],
        );
      }

      if (section.documents.length) {
        res.push(section);
      }
    }

    return res;
  }, [documents]);

  return (
    <div className="relative">
      <div className="flex flex-row">
        <div className="w-[240px] fixed">
          <div
            {...getRootProps()}
            className={cx(
              'border border-gray-400 border-dotted p-2 flex justify-center items-center rounded-lg mt-2 cursor-pointer  text-xs',
              isDragActive
                ? 'bg-sky-100 text-sky-700 p-4'
                : 'p-2 bg-sky-600 text-white',
            )}
          >
            <input {...getInputProps()} />
            {dragZone}
          </div>
          <div className="mt-2">
            <StandardInput
              register={register}
              id="s"
              placeholder=""
              type="text"
              label={
                s ? (
                  ''
                ) : (
                  <div className="flex flex-row items-center">
                    <LuSearch size={12} className="text-sky-500" />
                    <span className="text-slate-400 ml-1">Recherche...</span>
                  </div>
                )
              }
              inputClassName="peer block w-full p-1.5 placeholder-transparent text-slate-400 text-xs bg-slate-50 border border-slate-200 focus:border-slate-400 rounded-lg focus:outline-none"
              labelClassName="absolute pointer-events-none text-slate-400 text-xs transition-all top-1.5 left-1.5 peer-focus:hidden"
            />
          </div>

          <div className="mt-4 grid grid-cols-2 gap-2">
            {sections?.map((section) => (
              <Link
                key={section.type}
                to={`#documents@${section.type}`}
                className="p-2 border border-gray-200 rounded-lg text-slate-600"
              >
                <p className="font-bold text-lg">{section.documents.length}</p>
                <p className="text-xs">
                  {translateDocumentType(section.type, true)}
                </p>
              </Link>
            ))}
          </div>
        </div>

        <div className="w-[calc(100%-260px)] ml-[260px]">
          <div className="flex flex-col space-y-4 mt-1">
            {sections?.map((section) => {
              return (
                <div key={section.type} id={section.type} className="mb-4">
                  <p className="font-semibold mb-1">
                    {translateDocumentType(section.type, true)}
                    <span className="ml-1 text-xs py-1 px-2 bg-sky-100 text-sky-700 rounded-full">
                      {section.documents.length}
                    </span>
                  </p>

                  <div className="flex flex-col space-y-2">
                    {section.documents.map((document) => (
                      <Document key={document.id} document={document} />
                    ))}
                  </div>
                </div>
              );
            })}
          </div>

          {!documents?.length && (
            <p className="flex flex-row items-center">
              <IoWarningOutline size={24} />
              <span className="mx-1">Aucun document ajouté</span>
              <IoWarningOutline size={24} />
            </p>
          )}
        </div>
      </div>
      <div className="mt-8">
        <Screenshot userId={userId} />
      </div>
    </div>
  );
};

export default DocumentSection;
