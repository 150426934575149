import React, { useMemo, useState, useEffect } from 'react';
import cx from 'classnames';
import toast from 'react-hot-toast';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import { LuTrash, LuPanelRightOpen } from 'react-icons/lu';
import { CiEdit } from 'react-icons/ci';
import {
  useGetEncounterNotesQuery,
  useDeleteEncounterNoteMutation,
  usePatchEncounterNoteMutation,
} from '@@/services/encounterNote';
import { useViewDocumentQuery } from '@@/services/document';
import { useGetAllPatientsQuery, useGetMeQuery } from '@@/services/user';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
  getFilteredRowModel,
} from '@tanstack/react-table';
import EditableCell from '@@/components/Table/EditableCell';
import { Filter } from '@@/components/Table/Filter';
import { selectInputEncounterNoteTypes } from '@@/constants/document';

import { usePDF, PDFViewer } from '@react-pdf/renderer';
import EncounterNote from '@@/components/PDF/EncounterNote';

const columnHelper = createColumnHelper<Nabla.EncounterNote>();

const EncounterNotesPage: React.FC = () => {
  const location = useLocation();
  const [encounterNoteId, setEncounterNoteId] = useState<string | null>(
    location.hash?.replace('#', '') || null,
  );
  const { data: me } = useGetMeQuery();

  const { data: patients } = useGetAllPatientsQuery();
  const { data: encounterNotes } = useGetEncounterNotesQuery();
  const [deleteEncounterNote, { isSuccess: isDeletionSuccess }] =
    useDeleteEncounterNoteMutation();

  const [patchEncounterNote, { isSuccess: isPatchSuccess }] =
    usePatchEncounterNoteMutation();

  useEffect(() => {
    if (isDeletionSuccess) {
      toast.success('Le CR Nabla a bien été supprimé');
    }
  }, [isDeletionSuccess]);

  useEffect(() => {
    if (isPatchSuccess) {
      toast.success("L'attribution a bien été mise à jour");
    }
  }, [isPatchSuccess]);

  const selectPatientsInput = useMemo(() => {
    if (patients) {
      return [...patients]
        .filter((patient) => !!patient.activeSubscription)
        .map((patient) => ({
          id: patient.id,
          label: `${patient.lastName.toUpperCase()} ${patient.firstName.slice(0, 1)}.`,
        }))
        .sort((c1, c2) => {
          if (c1.label < c2.label) {
            return -1;
          }

          if (c1.label > c2.label) {
            return 1;
          }

          return 0;
        });
    }

    return [];
  }, [patients]);

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        id: 'createdAt',
        header: () => <p className="text-xs sm:text-sm">Date de création</p>,
        enableColumnFilter: false,
        cell: (row) => {
          const { createdAt } = row.getValue();

          if (!createdAt) {
            return null;
          }

          return (
            <p className="text-xs">
              {moment(createdAt).format('DD/MM/YY HH:mm')}
            </p>
          );
        },
      }),
      columnHelper.accessor('patientId.id', {
        header: () => <p className="text-xs sm:text-sm">Patient</p>,
        enableColumnFilter: false,
        cell: EditableCell,
        meta: {
          type: 'select',
          options: [{ id: '', label: '' }].concat(selectPatientsInput),
          getClassName: () => {
            return 'text-xs bg-transparent mx-4';
          },
          width: 'w-32',
        },
      }),
      columnHelper.accessor('type', {
        header: () => <p className="text-xs sm:text-sm">Type</p>,
        enableColumnFilter: false,
        cell: EditableCell,
        meta: {
          type: 'select',
          options: [{ id: '', label: '' }].concat(
            selectInputEncounterNoteTypes,
          ),

          getClassName: () => {
            return 'text-xs bg-transparent mx-4';
          },
        },
      }),
      columnHelper.accessor((row) => row, {
        id: 'note.title',
        header: () => <p className="text-xs sm:text-sm">Titre</p>,
        enableColumnFilter: false,
        cell: (row) => {
          const { note } = row.getValue();

          if (!note?.title) {
            return null;
          }

          return (
            <p
              className={cx('text-xs mx-4', encounterNoteId && 'max-w-[160px]')}
            >
              {note.title}
            </p>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        id: 'delete',
        header: ' ',
        enableColumnFilter: false,
        cell: (row) => {
          const { id } = row.getValue();

          return (
            <div className="flex flex-row items-center">
              {encounterNoteId !== id ? (
                <button onClick={() => setEncounterNoteId(id)} className="">
                  <LuPanelRightOpen size={20} className="text-blue-500" />
                </button>
              ) : (
                <Link
                  to={`/admin/encounter-notes/${id}`}
                  className=""
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <CiEdit size={20} className="text-yellow-500" />
                </Link>
              )}

              <button
                onClick={() => {
                  confirm(
                    'Etes vous sûr de vouloir supprimer ce compte rendu ?',
                  ) && deleteEncounterNote(id);
                }}
                className="ml-2"
              >
                <LuTrash size={20} className="text-red-500" />
              </button>
            </div>
          );
        },
      }),
    ],
    [deleteEncounterNote, selectPatientsInput, encounterNoteId],
  );

  const data = useMemo(() => {
    if (!encounterNotes) {
      return [];
    }
    return encounterNotes;
  }, [encounterNotes]);

  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    meta: {
      updateData: (id: string, columnId: string, value: string) => {
        switch (columnId) {
          case 'patientId.id':
          case 'patientId_id': {
            patchEncounterNote({ id, patientId: value });
            break;
          }
          case 'type': {
            patchEncounterNote({ id, type: value });
            break;
          }

          default:
            break;
        }
      },
    },
  });

  const encounterNote = useMemo(() => {
    return encounterNotes?.find((note) => note.id === encounterNoteId);
  }, [encounterNoteId, encounterNotes]);

  const signatureDocumentId = useMemo(
    () =>
      encounterNote?.practitionerId?.signatureDocumentId ||
      me?.signatureDocumentId,
    [encounterNote, me],
  );

  const { data: signature } = useViewDocumentQuery(signatureDocumentId, {
    skip: !signatureDocumentId,
  });

  const encounterNotePDF = useMemo(() => {
    return (
      <EncounterNote
        encounterNote={encounterNote}
        patient={encounterNote?.patientId}
        practitioner={encounterNote?.practitionerId || me}
        signature={signature}
      />
    );
  }, [encounterNote, me, signature]);

  const [pdf, updatePdf] = usePDF({
    document: encounterNotePDF,
  });

  useEffect(() => {
    updatePdf(encounterNotePDF);
  }, [updatePdf, encounterNotePDF]);

  if (!patients) {
    return null;
  }

  return (
    <div className="p-4 w-full flex flex-row items-start justify-between">
      <table className="mr-2">
        <thead>
          {table.getHeaderGroups().map((headerGroup, i) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} className="text-center mx-2">
                  {header.isPlaceholder ? null : (
                    <>
                      <div
                        className={
                          header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : ''
                        }
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {{
                          asc: <span className="pl-2">↑</span>,
                          desc: <span className="pl-2">↓</span>,
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>

                      {header.column.getCanFilter() ? (
                        <Filter column={header.column} />
                      ) : null}
                    </>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody>
          {table.getRowModel().rows.map((row) => {
            const isFocused = encounterNoteId === row.original.id;

            return (
              <tr
                key={row.id}
                className={cx(
                  'border-b border-slate-200 hover:bg-slate-200',
                  isFocused && 'bg-sky-100',
                )}
              >
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className="py-0.5">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      {!!encounterNote && (
        <PDFViewer style={{ width: '450px', height: '660px' }}>
          {encounterNotePDF}
        </PDFViewer>
      )}
    </div>
  );
};

export default EncounterNotesPage;
