import React, { useMemo } from 'react';
import moment from 'moment';
import { FieldError } from 'react-hook-form';
import { useGetUserReferralsQuery } from '@@/services/referral';
import SelectInput from '@@/components/Inputs/SelectInput';

const SelectPatientReferralInput: React.FC<{
  id: string;
  patientId: string;
  label?: string;
  placeholder?: string;
  error?: FieldError;
  register: any;
}> = ({ id, patientId, label, placeholder, error, register }) => {
  const { data: referrals } = useGetUserReferralsQuery(patientId);

  const selectReferralsInput = useMemo(() => {
    if (referrals) {
      return [...referrals]
        .map((r) => {
          const date = moment(r.createdAt);
          return {
            id: r.id,
            label: `${date.format('DD/MM/YY')} - ${r.reason}`,
            date,
          };
        })
        .sort((d1, d2) => {
          return d2.date.unix() - d1.date.unix();
        });
    }

    return [];
  }, [referrals]);

  if (!referrals?.length) {
    return null;
  }

  return (
    <SelectInput
      register={register}
      id={id}
      label={label}
      options={selectReferralsInput}
      placeholder={placeholder}
      error={error}
    />
  );
};

export default SelectPatientReferralInput;
