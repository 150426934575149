import React, { useMemo } from 'react';
import { FieldError } from 'react-hook-form';
import { useGetPractitionerContactsQuery } from '@@/services/practitionerContact';
import { translateSpeciality } from '@@/constants/practitioner';
import MultiSelectInput from '@@/components/Inputs/MultiSelectInput';

export const getPractitionerContactLabel = (
  contact: Unpatient.PractitionerContact,
) =>
  contact?.speciality
    ? `${contact.title || 'Dr'} ${contact.name} - ${translateSpeciality(contact.speciality)}`
    : `${contact?.title || 'Dr'} ${contact?.name}`;

const SelectPractitionerContactInput: React.FC<{
  id: string;
  label: string;
  control: any;
  values?: { id: string; label: string }[];
  placeholder?: string;
  error?: FieldError;
  disabled?: boolean;
  cb?: (arg: any) => void;
}> = ({ id, label, placeholder, values, error, disabled, control, cb }) => {
  const { data: practitionerContacts } = useGetPractitionerContactsQuery();

  const selectPractitionerContactsInput = useMemo(() => {
    if (practitionerContacts) {
      return [...practitionerContacts]
        .sort((c1, c2) => {
          if (c1.name < c2.name) {
            return -1;
          }

          if (c1.name > c2.name) {
            return 1;
          }

          return 0;
        })
        .map((contact) => ({
          ...contact,
          label: getPractitionerContactLabel(contact),
        }));
    }

    return [];
  }, [practitionerContacts]);

  if (!practitionerContacts) {
    return null;
  }

  return (
    <MultiSelectInput
      control={control}
      id={id}
      values={values}
      label={label}
      placeholder={placeholder}
      error={error}
      options={selectPractitionerContactsInput}
      callback={(practitioner: Unpatient.PractitionerContact) => {
        if (typeof cb === 'function') {
          cb(practitioner);
        }
      }}
      isMulti={false}
      isDisabled={!!disabled}
    />
  );
};

export default SelectPractitionerContactInput;
