import React, { useEffect, useCallback } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import { RiDeleteBinLine } from 'react-icons/ri';
import { LuMailOpen } from 'react-icons/lu';
import { useDeleteReferralMutation } from '@@/services/referral';
import { useGetPractitionerContactQuery } from '@@/services/practitionerContact';
import { translateSpeciality } from '@@/constants/practitioner';

const Referral: React.FC<{ referral: Unpatient.Referral }> = ({ referral }) => {
  const { data: practitionerContact } = useGetPractitionerContactQuery(
    referral.referredPractitioner?.id,
    { skip: !referral.referredPractitioner?.id },
  );

  const [
    deleteReferral,
    { isSuccess: isDeletionSuccess, isLoading: isDeletionLoading },
  ] = useDeleteReferralMutation();

  const onDelete = useCallback(
    (referralId: string) => {
      if (
        confirm("Souhaitez vous vraiment supprimer cette lettre d'adressage ?")
      ) {
        deleteReferral(referralId);
      }
    },
    [deleteReferral],
  );

  useEffect(() => {
    if (isDeletionSuccess) {
      toast.success(`L'adressage a bien été supprimé`, {
        position: 'top-right',
      });
    }
  }, [isDeletionSuccess]);

  if (!practitionerContact) {
    return null;
  }

  let name = `${moment(referral.createdAt).format('DD/MM/YYYY')} - ${
    practitionerContact.title || 'Dr'
  } ${practitionerContact.name}`;

  if (practitionerContact.speciality) {
    name += ` - ${translateSpeciality(practitionerContact.speciality)}`;
  }

  return (
    <div
      key={referral.id}
      className="flex flex-row items-center justify-between p-2 border-b border-gray-200 rounded-lg"
    >
      <Link
        to={`/patients/${referral.patientId}/referrals/${referral.id}`}
        target="_blank"
        rel="noopener noreferrer"
        className="flex flex-row items-center"
      >
        <LuMailOpen className="text-sky-600 bg-sky-100 w-8 h-8 p-1 rounded-lg" />
        <span className="ml-1 font-main text-main">{name}</span>
      </Link>

      <button
        className="ml-2"
        onClick={() => onDelete(referral.id)}
        disabled={isDeletionLoading}
      >
        <RiDeleteBinLine size={24} />
      </button>
    </div>
  );
};

export default Referral;
