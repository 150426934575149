import React, { useMemo, useState, useEffect, useCallback } from 'react';
import toast from 'react-hot-toast';
import moment from 'moment';
import cx from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import {
  LuSearch,
  LuCircleUser,
  LuTrash,
  LuPanelRightOpen,
} from 'react-icons/lu';
import { IoMdClose } from 'react-icons/io';
import {
  useGetTasksQuery,
  usePatchTaskMutation,
  useDeleteTaskMutation,
  useDeleteManyTasksMutation,
  TaskInputType,
} from '@@/services/task';
import {
  selectInputTaskTypes,
  selectInputTaskStatuses,
  TaskStatuses,
  TaskTypes,
  ordereTaskType,
  translateTaskType,
  translateTaskStatus,
} from '@@/constants/task';
import { useGetMeQuery } from '@@/services/user';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
  getFilteredRowModel,
} from '@tanstack/react-table';
import { MIN_SEARCH_LENGTH } from '@@/constants/search';
import Task from '@@/components/Task';
import EditableCell from '@@/components/Table/EditableCell';
import AuthenticatedImage from '@@/components/AuthenticatedImage';
import { Filter } from '@@/components/Table/Filter';
import { set, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import StandardInput from '@@/components/Inputs/StandardInput';
import MultiSelectInput from '@@/components/Inputs/MultiSelectInput';
import SelectPatientInput from '@@/components/Inputs/SelectPatientInput';
import { isAtLeastReact17 } from '@sentry/react/types/errorboundary';

const schema = yup
  .object({
    s: yup
      .string()
      .min(
        MIN_SEARCH_LENGTH,
        `La recherche doit contenir au moins ${MIN_SEARCH_LENGTH} caractères`,
      ),
    statuses: yup.array(
      yup.object({
        id: yup.string(),
        label: yup.string(),
      }),
    ),
    types: yup.array(
      yup.object({
        id: yup.string(),
        label: yup.string(),
      }),
    ),
    patients: yup.array(
      yup.object({
        id: yup.string(),
        label: yup.string(),
      }),
    ),
  })
  .required();

enum Tabs {
  MINE = 'mine',
  TO_VALIDATE = 'to_validate',
  ALL = 'all',
  SEARCH = 'search',
}

const Tab: React.FC<{
  id: string;
  title: string | JSX.Element;
  isActive: boolean;
  cb: Function;
}> = ({ id, isActive, title, cb }) => {
  return (
    <button
      onClick={() => {
        window.location.hash = id;
        cb(id);
      }}
    >
      <div
        className={cx(
          'font-semibold text-sm',
          isActive ? 'text-sky-500' : 'text-slate-700',
        )}
      >
        {title}
      </div>
    </button>
  );
};

const getTaskStatusClassName = (row: Unpatient.Task) => {
  if (row.status === TaskStatuses.DONE) {
    return 'mx-2 bg-green-100 p-1 rounded-lg text-green-700 text-xs text-center';
  }

  if (!row.deadline) {
    return 'mx-2 bg-yellow-100 p-1 rounded-lg text-yellow-700 text-xs text-center';
  }

  const now = moment();
  const deadlineMoment = moment(row.deadline);
  if (
    deadlineMoment.isSame(now, 'week') ||
    deadlineMoment.diff(now, 'days') < 4
  ) {
    return 'mx-2 bg-red-100 p-1 rounded-lg text-red-700 text-xs text-center';
  }

  if (deadlineMoment.diff(now, 'days') < 15) {
    return 'mx-2 bg-orange-100 p-1 rounded-lg text-orange-700 text-xs text-center';
  }

  return 'mx-2 bg-yellow-100 p-1 rounded-lg text-yellow-700 text-xs text-center';
};

const getTypeClassName = (row: Unpatient.Task) => {
  switch (row.type) {
    case TaskTypes.CONTACT_SEARCH:
      return 'bg-orange-100 p-1 rounded-lg text-orange-700 text-xs text-wrap h-10 text-center';
    case TaskTypes.PATIENT_REMINDER:
      return 'bg-amber-100 p-1 rounded-lg text-amber-700 text-xs text-center';
    case TaskTypes.TRANSCRIPT_FETCH:
      return 'bg-indigo-100 p-1 rounded-lg text-indigo-700 text-xs text-wrap h-10 text-center';
    case TaskTypes.RDV_CREATION:
      return 'bg-pink-100 p-1 rounded-lg text-pink-700 text-xs text-center';
    case TaskTypes.RDV_PATCH:
      return 'bg-purple-100 p-1 rounded-lg text-purple-700 text-xs text-center';
    case TaskTypes.RDV_DELETION:
      return 'bg-red-100 p-1 rounded-lg text-red-700 text-xs text-center';
    case TaskTypes.OTHER:
      return 'bg-slate-100 p-1 rounded-lg text-slate-700 text-xs text-center';
    default:
      return '';
  }
};

const columnHelper = createColumnHelper<Unpatient.Task>();

const TasksPage: React.FC = () => {
  const location = useLocation();
  const [taskId, setTaskId] = useState<string | null>(null);
  const [rowSelection, setRowSelection] = useState<(string | undefined)[]>([]);
  const { data: me } = useGetMeQuery();
  const [deleteTask, { isSuccess: isDeletionSuccess }] =
    useDeleteTaskMutation();

  const [deleteManyTasks, { isSuccess: isDeleteManyTasksSuccess }] =
    useDeleteManyTasksMutation();

  const [patchTask, { isSuccess: isPatchSuccess }] = usePatchTaskMutation();

  const [activeTab, setActiveTab] = useState(Tabs.MINE);
  const safeSetActiveTab = useCallback(
    (tabId: Tabs) => {
      setActiveTab(tabId);
      setValue('s', '');
    },
    [setActiveTab],
  );
  const [showCompletedTasks, setShowCompletedTasks] = useState(false);
  const toggleShowCompletedTasks = useCallback(() => {
    setShowCompletedTasks((showCompletedTasks) => !showCompletedTasks);
  }, [setShowCompletedTasks]);

  const { data: tasks, isLoading } = useGetTasksQuery(showCompletedTasks);

  const toggleRowSelection = useCallback(
    (id: string) => {
      setRowSelection((selection) => {
        if (selection?.includes(id)) {
          return selection.filter((s) => s !== id);
        }

        return [...selection, id];
      });
    },
    [setRowSelection],
  );
  const deleteSection = useCallback(() => {
    if (
      confirm(`Voulez-vous vraiment supprimer ${rowSelection.length} tâches ?`)
    ) {
      deleteManyTasks(rowSelection as string[]);
    }
  }, [rowSelection, deleteManyTasks]);

  const { register, control, watch, setValue } = useForm<{
    s: string;
    statuses: {
      id: string;
      label: string;
    }[];
    types: {
      id: string;
      label: string;
    }[];
    patients: {
      id: string;
      label: string;
    }[];
  }>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: {
      s: '',
    },
  });

  const [s, statuses, types, patients] = watch([
    's',
    'statuses',
    'types',
    'patients',
  ]);

  useEffect(() => {
    if (location?.hash) {
      const [tab, id] = location.hash.replace('#', '').split('@');
      if (tab) {
        setActiveTab(tab as Tabs);
      }

      if (id) {
        setTaskId(id);
        if (!isLoading) {
          setTimeout(() => {
            const element = document.getElementById(id);
            if (element) {
              element.scrollIntoView({ block: 'start', behavior: 'smooth' });
            }
          }, 100);
        }
      }
    }
  }, [location?.hash, setActiveTab, isLoading]);

  useEffect(() => {
    if (isDeletionSuccess) {
      toast.success('La tâche a bien été supprimée');
    }
  }, [isDeletionSuccess]);

  useEffect(() => {
    if (isDeleteManyTasksSuccess) {
      toast.success('Les tâches ont bien été supprimées');
      setRowSelection([]);
    }
  }, [isDeleteManyTasksSuccess, setRowSelection]);

  useEffect(() => {
    if (isPatchSuccess) {
      toast.success('La tâche a bien été modifiée');
    }
  }, [isPatchSuccess]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        id: 'ignore',
        enableColumnFilter: false,
        header: () => <span />,
        cell: EditableCell,
        meta: {
          type: 'checkbox',
          width: 'w-4',
        },
      }),
      columnHelper.accessor((row) => row, {
        id: 'ownerId',
        enableColumnFilter: false,
        header: () => (
          <div className="flex flex-row justify-center">
            <LuCircleUser size={26} className="" />
          </div>
        ),
        cell: (row) => {
          const { ownerId, creatorId } = row.getValue();

          const owner = ownerId || creatorId;

          if (!owner) {
            return null;
          }

          return owner.photoDocumentId ? (
            <AuthenticatedImage
              documentId={owner.photoDocumentId}
              placeholder="/user-profile-placeholder.jpg"
              alt={`${owner.firstName} ${owner.lastName}`}
              className="mx-2 w-6 sm:w-10 bg-white font-main rounded-full"
            />
          ) : (
            <div className="mx-2 w-10 h-10 font-main rounded-full bg-slate-100 border border-slate-200 flex items-center justify-center">
              <span className="text-sky-600 font-semibold">
                {owner.firstName?.slice(0, 1)}
                {owner.lastName?.slice(0, 1)}
              </span>
            </div>
          );
        },
      }),
      columnHelper.accessor('status', {
        header: () => <p className="text-xs sm:text-sm">Statut</p>,
        enableColumnFilter: false,
        cell: EditableCell,
        meta: {
          type: 'select',
          options: [{ id: '', label: '' }].concat(selectInputTaskStatuses),
          width: 'w-21',
          getClassName: getTaskStatusClassName,
        },
      }),
      columnHelper.accessor('type', {
        header: () => <p className="text-xs sm:text-sm">Type</p>,
        enableColumnFilter: false,
        cell: EditableCell,
        meta: {
          type: 'select',
          options: [{ id: '', label: '' }].concat(selectInputTaskTypes),
          width: 'w-36',
          getClassName: getTypeClassName,
        },
      }),
      columnHelper.accessor((row) => row, {
        id: 'patientId',
        header: () => <p className="text-xs sm:text-sm">Patient</p>,
        enableColumnFilter: false,
        cell: (row) => {
          const { id, patientId } = row.getValue();

          if (!patientId) {
            return null;
          }

          return (
            <Link
              key={id}
              className="text-xs mx-2 block text-center"
              to={`/conversations/c.us/${patientId.wsPhone || patientId.phone}`}
            >
              {patientId.firstName}
              <span className="ml-1 font-semibold">{patientId.lastName}</span>
            </Link>
          );
        },
      }),
      columnHelper.accessor('request', {
        id: 'request',
        enableColumnFilter: false,
        header: () => <p className="text-xs sm:text-sm">Demande</p>,
        cell: EditableCell,
        meta: {
          type: 'textarea',
          rows: 2,
          width: 'w-[280px]',
        },
      }),
      columnHelper.accessor('response', {
        id: 'response',
        enableColumnFilter: false,
        header: () => <p className="text-xs sm:text-sm">Réponse</p>,
        cell: EditableCell,
        meta: {
          type: 'textarea',
          rows: 2,
          width: 'w-[280px]',
        },
      }),
      columnHelper.accessor((row) => row, {
        id: 'actions',
        header: () => <span />,
        enableColumnFilter: false,
        cell: (row) => {
          const { id } = row.getValue();

          return (
            <div key={id} className="flex flex-row items-center">
              <button onClick={() => setTaskId(id)} className="ml-1">
                <LuPanelRightOpen size={20} className="text-blue-500" />
              </button>
              <button
                onClick={() => {
                  confirm('Voulez-vous vraiment supprimer cette tâche ?') &&
                    deleteTask(id);
                }}
                className="ml-2"
              >
                <LuTrash size={20} className="text-red-500" />
              </button>
            </div>
          );
        },
      }),
    ],
    [deleteTask, setTaskId],
  );

  const metrics = useMemo(() => {
    return {
      [Tabs.MINE]: tasks?.filter(
        (t) =>
          t.ownerId?.id === me?.id &&
          ![TaskStatuses.TO_VALIDATE, TaskStatuses.DONE].includes(t.status),
      ).length,
      [Tabs.TO_VALIDATE]: tasks?.filter(
        (t) =>
          t.ownerId?.id === me?.id && t.status === TaskStatuses.TO_VALIDATE,
      ).length,
      [Tabs.ALL]: tasks?.filter((t) => t.status !== TaskStatuses.DONE).length,
    };
  }, [tasks]);
  const data = useMemo(() => {
    if (!tasks) {
      return [];
    }

    return tasks
      .filter((task) => {
        if (showCompletedTasks) {
          return true;
        }

        return task.status !== TaskStatuses.DONE;
      })
      .filter((task) => {
        if (statuses?.length) {
          return statuses.map((s) => s.id).includes(task.status);
        }

        return true;
      })
      .filter((task) => {
        if (types?.length) {
          return types.map((t) => t.id).includes(task.type);
        }

        return true;
      })
      .filter((task) => {
        if (patients?.length) {
          return patients.map((p) => p.id).includes(task.patientId?.id);
        }

        return true;
      })
      .filter((task) => {
        switch (activeTab) {
          case Tabs.MINE:
            return (
              task.ownerId?.id === me?.id &&
              task.status !== TaskStatuses.TO_VALIDATE
            );
          case Tabs.TO_VALIDATE:
            return (
              task.ownerId?.id === me?.id &&
              task.status === TaskStatuses.TO_VALIDATE
            );
          case Tabs.SEARCH:
            if (s.length >= MIN_SEARCH_LENGTH) {
              return (
                task.request?.toLowerCase().includes(s.toLowerCase()) ||
                task.response?.toLowerCase().includes(s.toLowerCase()) ||
                task.patientId?.firstName
                  ?.toLowerCase()
                  .includes(s.toLowerCase()) ||
                task.patientId?.lastName
                  ?.toLowerCase()
                  .includes(s.toLowerCase()) ||
                task.ownerId?.firstName
                  ?.toLowerCase()
                  .includes(s.toLowerCase()) ||
                task.ownerId?.lastName
                  ?.toLowerCase()
                  .includes(s.toLowerCase()) ||
                translateTaskStatus(task.status)
                  .toLowerCase()
                  .includes(s.toLowerCase()) ||
                translateTaskType(task.type)
                  .toLowerCase()
                  .includes(s.toLowerCase())
              );
            }

            return true;

          case Tabs.ALL:
          default:
            return true;
        }
      })
      .sort((a, b) => {
        if (a.status === TaskStatuses.DONE && b.status !== TaskStatuses.DONE) {
          return 1;
        }

        if (b.status === TaskStatuses.DONE && a.status !== TaskStatuses.DONE) {
          return -1;
        }

        const lvl1 = +new Date(a.deadline) - +new Date(b.deadline);

        if (lvl1 !== 0) {
          return lvl1;
        }

        return ordereTaskType(a.type) - ordereTaskType(b.type);
      });
  }, [
    tasks,
    showCompletedTasks,
    me?.id,
    activeTab,
    statuses,
    types,
    patients,
    s,
  ]);

  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility: {
        response: !taskId,
        type: !taskId,
      },
      sorting,
      columnFilters,
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    meta: {
      updateData: (id: string, columnId: string, value: string) => {
        if (columnId === 'ignore') {
          toggleRowSelection(id);
          return;
        }

        const body = {
          [columnId]: value,
        } as unknown as TaskInputType;

        patchTask({ ...body, id });
      },
    },
  });

  return (
    <div className="flex flex-row relative w-full h-full">
      <div
        className={cx(
          'm-4 mt-0 h-[1500px] overflow-y-scroll',
          !!taskId ? 'w-2/3' : 'w-full',
        )}
      >
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-row items-center h-20">
            <h2 className="text-slate-700 text-lg font-semibold">Tâches</h2>
            <div className="flex flex-row items-center space-x-6 px-6 mx-6 border-x border-slate-200">
              <Tab
                id={Tabs.MINE}
                title={
                  <p>
                    Mes tâches
                    <span className="ml-1 py-0.5 px-1.5 bg-sky-100 border border-sky-100 rounded-full text-sky-700 text-xs">
                      {metrics[Tabs.MINE]}
                    </span>
                  </p>
                }
                isActive={activeTab === Tabs.MINE}
                cb={safeSetActiveTab}
              />
              <Tab
                id={Tabs.TO_VALIDATE}
                title={
                  <p>
                    A valider
                    <span className="ml-1 py-0.5 px-1.5 bg-red-50 border border-red-100 rounded-full text-red-700 text-xs">
                      {metrics[Tabs.TO_VALIDATE]}
                    </span>
                  </p>
                }
                isActive={activeTab === Tabs.TO_VALIDATE}
                cb={safeSetActiveTab}
              />
              <Tab
                id={Tabs.ALL}
                title={
                  <p>
                    Toutes
                    <span className="ml-1 py-0.5 px-1.5 bg-slate-100 border border-slate-200 rounded-full text-slate-700 text-xs">
                      {metrics[Tabs.ALL]}
                    </span>
                  </p>
                }
                isActive={activeTab === Tabs.ALL}
                cb={safeSetActiveTab}
              />
            </div>

            <div className={cx('w-52', !!taskId ? 'sm:w-52' : 'sm:w-80')}>
              <StandardInput
                register={register}
                id="s"
                placeholder="Recherche..."
                type="text"
                label={
                  <div className="flex flex-row items-center">
                    <LuSearch className="text-sky-500" />
                    <span className="text-slate-400 ml-1">Recherche...</span>
                  </div>
                }
                inputClassName="peer block w-full p-2 placeholder-transparent font-main text-slate-400 text-xs bg-slate-50 border border-slate-100 focus:border-slate-400 rounded-md focus:outline-none"
                labelClassName="absolute pointer-events-none font-main text-slate-400 text-sm -top-5 left-0 transition-all peer-focus:-top-5 peer-focus:left-0 peer-focus:text-xs peer-placeholder-shown:top-2.5 peer-placeholder-shown:left-2 peer-placeholder-shown:text-sm"
                errorClassName="italic font-main text-xs text-red-500 m-1"
                cb={(value: string) => {
                  switch (value.length) {
                    case 0:
                    case 1:
                    case 2: {
                      setActiveTab(Tabs.MINE);
                      break;
                    }
                    default:
                      setActiveTab(Tabs.SEARCH);
                      break;
                  }
                }}
              />
            </div>
          </div>
          {!taskId && (
            <button
              onClick={() => setTaskId('new')}
              className="bg-sky-600 rounded-lg py-2 px-3 hover:bg-sky-700"
            >
              <span className="text-white">+ Nouvelle tâche</span>
            </button>
          )}
        </div>

        <div className="flex flex-row items-center space-x-4 mb-4">
          <MultiSelectInput
            control={control}
            id="types"
            placeholder={
              <p className="font-main text-xs">Filtrer sur un type</p>
            }
            options={selectInputTaskTypes}
            isMulti
          />
          <MultiSelectInput
            control={control}
            id="statuses"
            placeholder={
              <p className="font-main text-xs">Filtrer sur un statut</p>
            }
            options={selectInputTaskStatuses}
            isMulti
          />
          <SelectPatientInput
            control={control}
            id="patients"
            placeholder={
              <p className="font-main text-xs">Filtrer sur un patient</p>
            }
            isMulti
          />
        </div>

        {!!rowSelection.length && (
          <button
            onClick={deleteSection}
            className="p-2 bg-red-100 border border-red-200 rounded-lg flex flex-row items-center ml-auto"
          >
            <LuTrash size={18} className="text-red-700" />
            <span className="text-red-700 ml-2">
              Supprimer {rowSelection.length} tâche(s)
            </span>
          </button>
        )}

        {!!data?.length ? (
          <table className="my-2">
            <thead>
              {table.getHeaderGroups().map((headerGroup, i) => (
                <tr key={headerGroup.id} className="">
                  {headerGroup.headers.map((header) => (
                    <th key={header.id} className="text-center pb-2">
                      {header.isPlaceholder ? null : (
                        <>
                          <div
                            className={
                              header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : ''
                            }
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                            {{
                              asc: <span className="pl-2">↑</span>,
                              desc: <span className="pl-2">↓</span>,
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>

                          {header.column.getCanFilter() ? (
                            <Filter column={header.column} />
                          ) : null}
                        </>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody>
              {table.getRowModel().rows.map((row) => {
                const isFocused = taskId === row.original.id;
                return (
                  <tr
                    key={row.id}
                    id={row.original.id || row.id}
                    className={cx(
                      'border-b border-slate-200 hover:bg-slate-100',
                      isFocused && 'bg-sky-100',
                    )}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id} className="py-0.5">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <div className="flex flex-col items-center justify-center">
            <p className="font-main my-2">🎉🎉 Bravo, vous êtes à jour! 🎉🎉</p>
          </div>
        )}

        {activeTab !== Tabs.TO_VALIDATE && (
          <button
            className="block mx-auto p-2"
            onClick={toggleShowCompletedTasks}
          >
            <span className="font-main text-sm underline">
              {showCompletedTasks
                ? 'Cacher les tâches finalisées'
                : 'Afficher les tâches finalisées'}
            </span>
          </button>
        )}
      </div>
      {!!taskId && (
        <div className="relative w-1/3 h-full bg-white">
          <div className="flex flex-row justify-end">
            <button onClick={() => setTaskId(null)} className="p-1 mr-2 mt-1">
              <IoMdClose size={20} className="text-slate-700" />
            </button>
          </div>

          <Task taskId={taskId} />
        </div>
      )}
    </div>
  );
};

export default TasksPage;
