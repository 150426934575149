import { api } from '@@/services/api';
import { convertToFormData } from '@@/utils/formData';

export type EventFormType = {
  name: string;
  notes?: string;
  location?: string;
  targetPractitioner?: string;
  startDate: Date;
  endDate: Date;
  shouldSendCalendarInvite: boolean;
  shouldSendScheduledMessage?: boolean;
  scheduledMessageDate?: Date;
  scheduledMessageBody?: Date;
  recurringFrequency?: string;
};

export const eventApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getEvent: builder.query<Unpatient.Event, string>({
      query: (eventId) => `events/${eventId}`,
      providesTags: ['Events'],
    }),
    getUserEvents: builder.query<Unpatient.Event[], string>({
      query: (userId) => `users/${userId}/events`,
      providesTags: ['Events'],
    }),
    getUserTimelineEvents: builder.query<
      Unpatient.EnrichedTimelineEvent[],
      string
    >({
      query: (userId) => `users/${userId}/timeline`,
      providesTags: ['Events', 'Timelines', 'Plans'],
    }),
    getEvents: builder.query<Unpatient.Event[], void>({
      query: () => `events`,
      providesTags: ['Events'],
    }),
    createUserEvent: builder.mutation<
      Unpatient.Event[],
      { userId: string; body: EventFormType; attachments?: File[] }
    >({
      query: ({ userId, attachments, body }) => {
        const formData = new FormData();

        if (attachments) {
          for (const attachment of attachments) {
            formData.append('file', attachment);
          }
        }

        return {
          url: `users/${userId}/events`,
          method: 'POST',
          body: convertToFormData(body, formData),
          formData: true,
        };
      },
      invalidatesTags: ['Events', 'Users'],
    }),
    patchEvent: builder.mutation<
      Unpatient.Event[],
      { eventId: string; body: EventFormType; attachments?: File[] }
    >({
      query: ({ eventId, attachments, body }) => {
        const formData = new FormData();

        if (attachments) {
          for (const attachment of attachments) {
            formData.append('file', attachment);
          }
        }

        return {
          url: `/events/${eventId}`,
          method: 'PATCH',
          body: convertToFormData(body, formData),
          formData: true,
        };
      },
      invalidatesTags: ['Events', 'Users'],
    }),
    deleteEvent: builder.mutation<{}, string>({
      query: (eventId) => ({
        url: `events/${eventId}`,
        method: 'DELETE',
        body: {},
      }),
      invalidatesTags: ['Events'],
    }),
    deleteEventDocument: builder.mutation<
      Unpatient.Event,
      { eventId: string; attachmentId: string }
    >({
      query: ({ eventId, attachmentId }) => ({
        url: `events/${eventId}/documents/${attachmentId}`,
        method: 'DELETE',
        body: {},
      }),
      invalidatesTags: ['Events'],
    }),
    handleEventNoShow: builder.mutation<{}, Unpatient.Event>({
      query: (event) => ({
        url: `events/${event.id}/no-show`,
        method: 'POST',
        body: {
          isNoShow: !event.isNoShow,
        },
      }),
      invalidatesTags: ['Events', 'Users'],
    }),
  }),
});

export const {
  useGetEventQuery,
  useGetUserEventsQuery,
  useGetEventsQuery,
  useCreateUserEventMutation,
  useDeleteEventMutation,
  useHandleEventNoShowMutation,
  usePatchEventMutation,
  useDeleteEventDocumentMutation,
  useGetUserTimelineEventsQuery,
} = eventApi;
