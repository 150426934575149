import React, { useEffect, useCallback } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import { RiDeleteBinLine } from 'react-icons/ri';
import { IoWarningOutline } from 'react-icons/io5';
import { FaHouseMedicalCircleCheck } from 'react-icons/fa6';
import { LuPencilLine } from 'react-icons/lu';
import {
  useGetUserPlansQuery,
  useCreateUserPlanMutation,
  useDeletePlanMutation,
} from '@@/services/plan';

const PlanSection: React.FC<{ patientId: string }> = ({ patientId }) => {
  const { data: plans } = useGetUserPlansQuery(patientId, {
    skip: !patientId,
  });
  const [createPlan, { isSuccess, isLoading }] = useCreateUserPlanMutation();
  const [
    deletePlan,
    { isSuccess: isDeletionSuccess, isLoading: isDeletionLoading },
  ] = useDeletePlanMutation();

  const onDelete = useCallback(
    (planId: string) => {
      if (confirm('Souhaitez vous vraiment supprimer ce plan de soins ?')) {
        deletePlan(planId);
      }
    },
    [deletePlan],
  );

  const onSubmit = useCallback(async () => {
    if (patientId) {
      createPlan(patientId);
    }
  }, [createPlan, patientId]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(
        `Le plan de soins est en cours de création. Cela peut prendre plusieurs minutes...`,
        {
          position: 'top-right',
        },
      );
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isDeletionSuccess) {
      toast.success(`Le plan de soins a bien été supprimé`, {
        position: 'top-right',
      });
    }
  }, [isDeletionSuccess]);

  return (
    <div className="mr-4">
      <div id="plans" className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center">
          <span className="text-xl font-bold">Plan(s) de soins</span>
          <span className="ml-1 text-xs py-1 px-2 bg-sky-100 text-sky-700 rounded-full">
            {plans?.length}
          </span>
        </div>
        <button
          className="bg-sky-600 rounded-lg p-1.5 flex flex-row items-center text-white w-fit"
          onClick={() => onSubmit()}
          disabled={isLoading}
        >
          <LuPencilLine size={16} />
          <span className="ml-1 text-sm">Nouveau Plan</span>
        </button>
      </div>
      <div className="flex flex-col space-y-4 mt-4">
        {plans?.map((plan) => (
          <div
            key={plan.id}
            className="flex flex-row items-center justify-between p-2 border-b border-gray-200 rounded-lg"
          >
            <Link
              to={`/patients/${patientId}/plans/${plan.id}`}
              target="_blank"
              rel="noopener noreferrer"
              className="flex flex-row items-center"
            >
              <FaHouseMedicalCircleCheck className="text-sky-600 bg-sky-100 w-8 h-8 p-1 rounded-lg" />
              <span className="ml-1 font-main text-main">
                {moment(plan.startDate).format('DD/MM/YYYY')} -{' '}
                {moment(plan.endDate).format('DD/MM/YYYY')}
              </span>
            </Link>

            <button
              className="ml-2"
              onClick={() => onDelete(plan.id)}
              disabled={isDeletionLoading}
            >
              <RiDeleteBinLine size={24} />
            </button>
          </div>
        ))}

        {!plans?.length && (
          <p className="font-main text-main flex flex-row items-center">
            <IoWarningOutline size={24} />
            <span className="mx-1">Aucun plan de soins</span>
            <IoWarningOutline size={24} />
          </p>
        )}
      </div>
    </div>
  );
};

export default PlanSection;
