export enum Genders {
  M = 'male',
  F = 'female',
  O = 'other',
}

export const selectInputGenders = [
  {
    id: Genders.M,
    label: 'Homme',
  },
  {
    id: Genders.F,
    label: 'Femme',
  },
  {
    id: Genders.O,
    label: 'Autre',
  },
];

export const translateGender = (gender: string) => {
  switch (gender) {
    case Genders.M:
      return 'Homme';
    case Genders.F:
      return 'Femme';
    case Genders.O:
      return 'Autre';
    default:
      return 'Non renseigné';
  }
};

export const getTitle = (gender: string) => {
  switch (gender) {
    case Genders.M:
      return 'M.';
    case Genders.F:
      return 'Mme';
    default:
      return 'M. ou Mme';
  }
};

export const translateAntecedentSection = (antededent: string) => {
  switch (antededent) {
    case 'cardiovascular':
      return 'Cardio-vasculaire';
    case 'neurology':
      return 'Neurologie';
    case 'pulmonology':
      return 'Pneumologie';
    case 'nephrology':
      return 'Néphrologie';
    case 'urology':
      return 'Urologie';
    case 'digestion':
      return 'Système digestif';
    case 'hematology':
      return 'Hématologie';
    case 'rheumatology':
      return 'Rhumatologie';
    case 'orthopedics':
      return 'Orthopédie';
    case 'dermatology':
      return 'Dermatologie';
    case 'endocrinology':
      return 'Endocrinologie';
    case 'gynecology':
      return 'Gynécologie';
    case 'ent':
      return 'O.R.L';
    case 'infectiousDiseases':
      return 'Maladies infectieuses';
    case 'ophthalmology':
      return 'Ophtalmologie';
    case 'allergies':
      return 'Allergies';
    case 'dental':
      return 'Dentaire';
    case 'psychiatry':
      return 'Santé mentale';
    case 'other':
      return 'Autre';

    default:
      return antededent;
  }
};

export const translateAllFields = (field: string) => {
  switch (field) {
    case 'longTermMedication':
      return 'Traitements de longue durée';
    case 'surgeryHistory':
      return 'Chirurgie';
    case 'profession':
      return 'Profession';
    case 'alcool':
      return 'Alcool';
    case 'smoker':
      return 'Tabac';
    case 'exercise':
      return 'Activité physique';
    case 'alimentation':
      return 'Alimentation';
    case 'sleep':
      return 'Sommeil';
    case 'stress':
      return 'Stress';
    case 'social':
      return 'Social';
    case 'otherSituation':
      return 'Autre';
    case 'nonDrugTherapy':
      return 'Thérapie non médicamenteuse';
    case 'observations':
      return 'Observations';
    case 'privateNotes':
      return 'Notes privées';

    default: {
      const [parentField, childField] = field.split('.');

      if (childField) {
        switch (parentField) {
          case 'antecedents':
            return translateAntecedentSection(childField);
          case 'familyAntecedents': {
            switch (childField) {
              case 'father':
                return 'Antécédents du Père';
              case 'mother':
                return 'Antécédents de la Mère';
              case 'siblings':
                return 'Antécédents des Frères et Soeurs';
              case 'children':
                return 'Antécédents des Enfants';
              case 'other':
                return 'Autres antécédents familiaux';
              default:
                return childField;
            }
          }
          default:
            return childField;
        }
      }

      return field;
    }
  }
};
