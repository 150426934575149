import React from 'react';
import * as yup from 'yup';
import {
  TaskTypes,
  selectInputTaskTypes,
  selectInputTaskStatuses,
} from '@@/constants/task';
import StandardInput from '@@/components/Inputs/StandardInput';
import SelectInput from '@@/components/Inputs/SelectInput';
import DatePickerInput from '@@/components/Inputs/DatePickerInput';
import SelectPractitionerContactInput from '@@/components/Inputs/SelectPractitionerContactInput';
import SelectPatientInput from '@@/components/Inputs/SelectPatientInput';
import SelectPractitionerInput from '@@/components/Inputs/SelectPractitionerInput';
import SelectPatientEventInput from '@@/components/Inputs/SelectPatientEventInput';
import SelectPatientDocumentInput from '@@/components/Inputs/SelectPatientDocumentInput';
import SelectPatientPrescriptionInput from '@@/components/Inputs/SelectPatientPrescriptionInput';
import SelectPatientReferralInput from '@@/components/Inputs/SelectPatientReferralInput';

export const schema = yup
  .object({
    ownerId: yup.string(),
    patientId: yup.object({
      id: yup.string(),
      label: yup.string(),
    }),
    practitionerContactId: yup.object({
      id: yup.string(),
      label: yup.string(),
    }),
    eventId: yup.string(),
    documentId: yup.string(),
    prescriptionId: yup.string(),
    referralId: yup.string(),
    status: yup.string(),
    type: yup.string().required('Veuillez renseigner le type de la tâche'),
    deadline: yup.date(),
    eventDate: yup.date(),
    comments: yup.string(),
  })
  .required();

const TaskForm: React.FC<{
  form: any;
}> = ({ form }) => {
  const {
    register,
    control,
    watch,
    formState: { errors },
  } = form;

  const [type, patientId, practitionerContactId] = watch([
    'type',
    'patientId',
    'practitionerContactId',
  ]);

  return (
    <div className="my-8">
      <div className="-mt-6">
        <SelectInput
          register={register}
          id="type"
          label="Type (*)"
          options={selectInputTaskTypes}
          placeholder="Choisissez un type de tâche (*)"
          error={errors.type}
        />
      </div>

      <div className="grid grid-cols-2 gap-2 mt-10">
        <div className="-mt-6">
          <SelectInput
            register={register}
            id="status"
            label="Statut"
            options={selectInputTaskStatuses}
            placeholder="Choisissez un statut"
            error={errors.status}
          />
        </div>
        <div className="mt-1">
          <DatePickerInput
            id="deadline"
            control={control}
            label="deadline"
            showTimeSelect
            error={errors?.deadline}
          />
        </div>
      </div>

      {type === TaskTypes.RDV_CREATION && (
        <div className="mt-10">
          <DatePickerInput
            id="eventDate"
            control={control}
            label="Date du RDV"
            showTimeSelect
            error={errors?.eventDate}
          />
        </div>
      )}

      <div className="mt-6">
        <SelectPatientInput
          control={control}
          values={patientId}
          id="patientId"
          label="Patient"
          placeholder="Choisissez un patient"
        />
      </div>

      <div className="mt-6">
        <SelectPractitionerInput
          register={register}
          id="ownerId"
          label="Destinataire"
          placeholder="Choisissez le destinataire de la tâche"
        />
      </div>

      <div className="mt-6">
        <SelectPractitionerContactInput
          control={control}
          values={practitionerContactId}
          id="practitionerContactId"
          label="Praticien lié"
          placeholder="Choisissez un praticien"
        />
      </div>

      <div className="mt-10">
        <StandardInput
          register={register}
          id="request"
          label="Demande"
          type="textarea"
          placeholder="demande"
          error={errors.request}
        />
      </div>
      <div className="mt-10">
        <StandardInput
          register={register}
          id="response"
          label="Réponse"
          type="textarea"
          placeholder="Réponse"
          error={errors.response}
        />
      </div>

      {!!patientId?.id && (
        <div className="mt-6">
          <SelectPatientEventInput
            register={register}
            patientId={patientId?.id}
            id="eventId"
            label="Evènement lié"
            placeholder="Choisissez un évènement"
          />
        </div>
      )}

      {!!patientId?.id && (
        <div className="mt-6">
          <SelectPatientDocumentInput
            register={register}
            patientId={patientId?.id}
            id="documentId"
            label="Document lié"
            placeholder="Choisissez un document"
          />
        </div>
      )}

      {!!patientId?.id && (
        <div className="mt-6">
          <SelectPatientPrescriptionInput
            register={register}
            patientId={patientId?.id}
            id="prescriptionId"
            label="Ordonnance liée"
            placeholder="Choisissez une ordonnance"
          />
        </div>
      )}

      {!!patientId?.id && (
        <div className="mt-6">
          <SelectPatientReferralInput
            register={register}
            patientId={patientId?.id}
            id="referralId"
            label="Adressage lié"
            placeholder="Choisissez une lettre d'Adressage"
          />
        </div>
      )}
    </div>
  );
};

export default TaskForm;
