import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { LiaNewspaper } from 'react-icons/lia';
import { LuSearch } from 'react-icons/lu';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { useSearchPatientMedicalRecordQuery } from '@@/services/user';
import { useSearchUserDocumentsQuery } from '@@/services/document';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import StandardInput from '@@/components/Inputs/StandardInput';
import AuthenticatedImage from '@@/components/AuthenticatedImage';
import AuthenticatedLink from '@@/components/AuthenticatedLink';

import { MIN_SEARCH_LENGTH } from '@@/constants/search';
import { translateAllFields } from '@@/constants/medicalRecord';
import { computeDocumentName } from '@@/utils/document';
import { highlightText, formatId } from '@@/utils/string';

const schema = yup
  .object({
    s: yup
      .string()
      .min(
        MIN_SEARCH_LENGTH,
        `La recherche doit contenir au moins ${MIN_SEARCH_LENGTH} caractères`,
      )
      .required('Veuillez entrer une recherche'),
  })
  .required();

const SearchUserDataSection: React.FC<{
  userId: string;
  disableError?: boolean;
}> = ({ userId, disableError }) => {
  const {
    register,
    watch,
    formState: { errors },
  } = useForm<{
    s: string;
  }>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: {
      s: '',
    },
  });
  const s = watch('s');

  const skip = useMemo(
    () => s?.length < MIN_SEARCH_LENGTH || !userId,
    [s, userId],
  );

  const { data: matchingDocuments } = useSearchUserDocumentsQuery(
    { s, userId },
    {
      skip,
    },
  );

  const { data: matchingIntels } = useSearchPatientMedicalRecordQuery(
    { s, userId },
    {
      skip,
    },
  );

  return (
    <div className="w-56 relative">
      <StandardInput
        register={register}
        id="s"
        placeholder=""
        type="text"
        label={
          s ? (
            ''
          ) : (
            <div className="flex flex-row items-center">
              <LuSearch size={12} className="text-sky-500" />
              <span className="text-slate-400 ml-1">Recherche...</span>
            </div>
          )
        }
        error={disableError ? undefined : errors.s}
        inputClassName="peer block w-full p-1.5 placeholder-transparent text-slate-400 text-xs bg-slate-50 border border-slate-200 focus:border-slate-400 rounded-2xl focus:outline-none"
        labelClassName="absolute pointer-events-none text-slate-400 text-xs transition-all top-1.5 left-1.5 peer-focus:hidden"
      />

      {!skip && (!!matchingIntels?.length || !!matchingDocuments?.length) && (
        <div className="absolute z-10 bg-sky-100 p-2 rounded-lg mt-4 w-96 -left-40">
          {matchingIntels?.map((intel, i) => (
            <Link
              to={`#medical-info@medicalrecord.${formatId(intel.key)}`}
              key={i}
              className="flex flex-row items-center mt-1"
            >
              <LiaNewspaper size={20} className="mr-1" />
              <span className="underline">{translateAllFields(intel.key)}</span>
              &nbsp;:&nbsp;
              <span
                className="ml-1"
                dangerouslySetInnerHTML={{
                  __html: highlightText(intel.txt, s),
                }}
              />
            </Link>
          ))}
          {matchingDocuments?.map((doc) => (
            <AuthenticatedLink
              key={doc.id}
              documentId={doc.id}
              className="my-2 p-2 border border-gray-400 rounded-lg flex flex-row items-center w-fit"
            >
              {doc.mimetype.includes('image') ? (
                <AuthenticatedImage
                  documentId={doc.id}
                  alt={doc.name}
                  className="h-10 w-10 content-center object-cover rounded-lg"
                />
              ) : (
                <IoDocumentTextOutline size={24} />
              )}
              <span className="ml-1 font-main text-main">
                {computeDocumentName(doc)}
              </span>
            </AuthenticatedLink>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchUserDataSection;
