import React, { useCallback, useMemo } from 'react';
import toast from 'react-hot-toast';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { IoMdLock } from 'react-icons/io';
import { MdOutlineAudioFile, MdOndemandVideo } from 'react-icons/md';
import { AiOutlineNotification } from 'react-icons/ai';
import { LuSticker, LuPhoneCall } from 'react-icons/lu';
import { TiDelete } from 'react-icons/ti';
import { useGetMessageMediaQuery } from '@@/services/whatsapp';
import { ChatTypes } from '@@/constants/whatsapp';
import { getExtensionFromMimetype, base64ToBlob } from '@@/utils/file';
import { formatWhatsAppText } from '@@/utils/whatsapp';

const Message: React.FC<{
  message: Unpatient.ChatMessage;
  disableLinks?: boolean;
}> = ({ message, disableLinks }) => {
  const chatId = message?.id?.remote;
  const messageId = message?.id?.id;

  const { data: media } = useGetMessageMediaQuery(
    { chatId: chatId, messageId },
    {
      skip:
        !chatId ||
        !messageId ||
        ![
          ChatTypes.IMAGE,
          ChatTypes.DOCUMENT,
          ChatTypes.VIDEO,
          ChatTypes.PTT,
        ].includes(message?.type as ChatTypes),
    },
  );

  const mediaURL = useMemo(() => {
    if (media) {
      const blob = base64ToBlob(media.data, media.mimetype);
      const url = URL.createObjectURL(blob);
      return url;
    }
  }, [media]);

  const downloadMedia = useCallback(() => {
    if (disableLinks) {
      return;
    }

    if (!media || !mediaURL) {
      toast.error(
        'Il semble que ce document ne soit pas disponible via whatsapp',
        {
          position: 'top-right',
        },
      );
      return;
    }

    const link = document.createElement('a');
    link.href = mediaURL;
    link.setAttribute(
      'download',
      media.filename ||
        `${messageId}.${getExtensionFromMimetype(media.mimetype)}`,
    );
    document.body.appendChild(link);
    link.click();
  }, [disableLinks, media?.filename, media?.mimetype, mediaURL]);

  if (!message?.type) {
    return <p className="italic text-yellow-400">Aucun message</p>;
  }

  switch (message?.type) {
    case ChatTypes.CHAT:
      return (
        <div
          dangerouslySetInnerHTML={{ __html: formatWhatsAppText(message.body) }}
        />
      );

    case ChatTypes.REVOKED:
      return (
        <div className="flex flex-row items-center">
          <TiDelete />
          <p>Message supprimé</p>
        </div>
      );

    case ChatTypes.IMAGE: {
      const mediaB64 = media?.data ?? message?._data?.body;
      const caption = message._data.caption;

      return (
        <div>
          <button onClick={downloadMedia} disabled={!media}>
            <img
              className="object-cover"
              src={`data:${message._data?.mimetype};base64, ${mediaB64}`}
              alt="image"
            />
          </button>

          {!!caption && (
            <div
              className="mt-1"
              dangerouslySetInnerHTML={{
                __html: formatWhatsAppText(caption),
              }}
            />
          )}
        </div>
      );
    }

    case ChatTypes.DOCUMENT: {
      const caption = message._data.caption;
      const filename = message._data.filename || 'Fichier inconnu';

      return (
        <div className="">
          <button
            className="flex flex-row items-center"
            onClick={downloadMedia}
          >
            <IoDocumentTextOutline size={40} />
            <span className="ml-1 underline">{filename}</span>
          </button>
          {!!caption && (
            <div
              className="mt-1"
              dangerouslySetInnerHTML={{
                __html: formatWhatsAppText(caption),
              }}
            />
          )}
        </div>
      );
    }

    case ChatTypes.PTT: {
      return (
        <div className="flex flex-row items-center">
          <MdOutlineAudioFile size={30} className="mr-1" />
          {!!mediaURL && !!media && (
            <audio controls>
              <source src={mediaURL} type={media.mimetype} />
              Your browser does not support the audio element.
            </audio>
          )}
        </div>
      );
    }

    case ChatTypes.STICKER:
      return (
        <div className="flex flex-row items-center">
          <LuSticker size={30} />
          <span>Emoji (todo)</span>
        </div>
      );

    case ChatTypes.VCARD:
      return <p>{message.body}</p>;

    case ChatTypes.VIDEO: {
      return (
        <div>
          <div className="flex flex-row items-center mb-2">
            <MdOndemandVideo size={30} className="mr-1" />
            <span>
              Message video {!media ? ' (Contenu non disponible)' : ''}
            </span>
          </div>
          <video
            controls
            className="w-96"
            poster={`data:${message._data?.mimetype};base64,${message._data?.body}`}
            src={mediaURL}
          >
            Votre navigateur ne supporte pas la balise vidéo.
          </video>
        </div>
      );
    }

    case ChatTypes.GP2:
      return <p>{message._data.body}</p>;

    case ChatTypes.CIPHERTEXT:
      return (
        <div className="flex flex-row items-center">
          <IoMdLock />
          <p>Message chiffré</p>
        </div>
      );

    case ChatTypes.NOTIFICATION_TEMPLATE:
      return (
        <div className="flex flex-row items-center">
          <AiOutlineNotification />
          <p className="mx-1">{message._data.subtype}</p>
          <AiOutlineNotification />
        </div>
      );

    case ChatTypes.CALL_LOG: {
      if (message.fromMe) {
        return (
          <div className="flex flex-row items-center">
            <LuPhoneCall size={20} />
            <span className="ml-2">
              {message._data.callOutcome === 'Missed'
                ? 'Appel émis resté sans réponse'
                : 'Appel émis et décroché'}
            </span>
          </div>
        );
      }
      return (
        <div className="flex flex-row items-center">
          <LuPhoneCall size={20} />
          <span className="ml-2">
            {message._data.callOutcome === 'Missed'
              ? 'Appel Manqué'
              : 'Appel Reçu'}
          </span>
        </div>
      );
    }

    default: {
      return <p>MESSAGE NON RECONNU</p>;
    }
  }
};

export default Message;
