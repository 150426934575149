import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';

const defaultErrorMessage = 'Une erreur est survenue';
const authRoutes = ['login', 'resetPassword', 'changePassword'];

export const rtkQueryErrorHandler: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      const { meta, payload } = action;
      const { data } = payload;

      const { auth } = api.getState();
      if (!auth.accessToken && !authRoutes.includes(meta.arg.endpointName)) {
        return next(action);
      }

      if (data.code === 11000) {
        toast.error(
          data?.errorResponse?.errmsg
            ? `CONFLIT : ${data.errorResponse?.errmsg}`
            : 'Conflit',
          {
            position: 'top-right',
          },
        );
        return next(action);
      }

      const validationErrorMessage = data?.details?.[0]?.message;

      const message =
        validationErrorMessage || data?.messageText || defaultErrorMessage;

      toast.error(message, {
        position: 'top-right',
      });
    }

    return next(action);
  };
