import React, { useCallback, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import toast from 'react-hot-toast';
import omit from 'lodash.omit';
import debounce from 'lodash.debounce';
import isEqual from 'lodash.isequal';
import { useParams } from 'react-router-dom';
import {
  useGetPopulatedPatientQuery,
  useFindPatientByPhoneQuery,
  usePatchUserOptimisticMutation,
} from '@@/services/user';
import { useViewDocumentQuery } from '@@/services/document';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  schema,
  UserFormType,
  MedicalRecordFormType,
} from '@@/components/Forms/PatientForm';
import { getPractitionerContactLabel } from '@@/components/Inputs/SelectPractitionerContactInput';

const emptyPayload = {
  firstName: undefined,
  lastName: undefined,
  role: undefined,
  email: undefined,
  phone: undefined,
  birthDate: undefined,
  status: undefined,
  address: undefined,
  city: undefined,
  postcode: undefined,
  country: undefined,
  medicalRecord: {},
};

export type WithFormProps = {
  patient: Unpatient.User;
  isPage: boolean;
  patientForm: ReturnType<typeof useForm<UserFormType>>;
  handleRevisionChange: (patient: Unpatient.User, revision?: string) => void;
};

export const withPatientForm = <P extends object>(
  WrappedComponent: React.ComponentType<P & WithFormProps>,
) => {
  return (props: P) => {
    const { patientId, phone } = useParams();
    const isPage = !!patientId;

    const { data: patientFromId, isLoading: isLoadingFromId } =
      useGetPopulatedPatientQuery(patientId || '', {
        skip: !patientId,
      });
    const { data: patientFromPhone, isLoading: isLoadingFromPhone } =
      useFindPatientByPhoneQuery(
        { phone: phone || '', userId: patientId || '' },
        {
          skip: !phone || !!patientId,
        },
      );

    const patient = patientFromId || patientFromPhone;
    const { data: photoUrl } = useViewDocumentQuery(
      patient?.photoDocumentId || '',
      { skip: !patient?.photoDocumentId },
    );

    const [shouldAutoSave, setShouldAutoSave] = useState(true);
    const [patchUser, { isSuccess }] = usePatchUserOptimisticMutation();

    const form = useForm<UserFormType>({
      resolver: yupResolver(schema),
      mode: 'onBlur',
    });

    // Init logic
    const handleRevisionChange = useCallback(
      (patient: Unpatient.User, revision?: string) => {
        if (patient) {
          const data = omit(patient, [
            '_id',
            'photoDocumentId',
            'doctolibId',
            'medicalRecord',
            'doctolibSignedId',
            'wsPhone',
            'reminderD5SentAt',
            'reminderD15SentAt',
            'reminderD20SentAt',
            'reminderD30SentAt',
            'reminderD60SentAt',
            'isDeleted',
            'createdAt',
            'updatedAt',
          ]) as UserFormType;

          const medicalRecord = omit(
            patient.medicalRecord,
            'treatingDoctor',
          ) as MedicalRecordFormType;

          if (medicalRecord) {
            if (patient.medicalRecord?.treatingDoctor) {
              medicalRecord.treatingDoctor = {
                id: patient.medicalRecord.treatingDoctor.id,
                label: getPractitionerContactLabel(
                  patient.medicalRecord.treatingDoctor,
                ),
              };
            }
            if (patient.medicalRecord?.specialists) {
              medicalRecord.specialists = patient.medicalRecord.specialists.map(
                (s) => ({
                  id: s?.id,
                  label: getPractitionerContactLabel(s),
                }),
              );
            }
            data.medicalRecord = medicalRecord;
          }

          if (revision) {
            setShouldAutoSave(false);
            form.reset();
          }

          form.reset({
            ...emptyPayload,
            ...data,
            photo: photoUrl,
          });
        }
      },
      [form, photoUrl],
    );
    useEffect(() => {
      if (patient) {
        handleRevisionChange(patient);
      }
    }, [patient, handleRevisionChange]);

    // Autosave logic
    const handleManualPatchUser = useCallback(
      async (data: UserFormType) => {
        await patchUser({
          ...data,
          medicalRecord: {
            ...data.medicalRecord,
            treatingDoctor: data.medicalRecord?.treatingDoctor?.id,
            specialists: data.medicalRecord?.specialists?.map((s) => s.id),
          },
          photo: Array.isArray(data.photo) ? data.photo[0] : undefined,
        });
        setShouldAutoSave(true);
      },
      [patchUser, setShouldAutoSave],
    );

    const watchedValues = form.watch();
    const prevValuesRef = useRef<UserFormType | undefined>();
    const debouncedMagicUpdate = useCallback(
      debounce((data: UserFormType) => {
        patchUser({
          ...data,
          medicalRecord: {
            ...data.medicalRecord,
            treatingDoctor: data.medicalRecord?.treatingDoctor?.id,
            specialists: data.medicalRecord?.specialists?.map((s) => s.id),
          },
          photo: Array.isArray(data.photo) ? data.photo[0] : undefined,
        });
      }, 1000),
      [patchUser],
    );
    useEffect(() => {
      if (patient?.id) {
        const prevValues = prevValuesRef.current;

        if (
          shouldAutoSave &&
          watchedValues?.id === patient.id &&
          !form.formState?.isSubmitting &&
          form.formState?.isValid &&
          form.formState?.isDirty &&
          !isEqual(prevValues, watchedValues)
        ) {
          try {
            schema.validateSync(watchedValues);
            prevValuesRef.current = { ...watchedValues };
            debouncedMagicUpdate(watchedValues);
          } catch (e) {
            // do nothing
          }
        }
      }
    }, [
      patient?.id,
      shouldAutoSave,
      watchedValues,
      form?.formState?.isDirty,
      form.formState?.isValid,
      form.formState?.isSubmitting,
      debouncedMagicUpdate,
    ]);
    useEffect(() => {
      if (isSuccess && !shouldAutoSave) {
        toast.success(`Le patient a bien été modifié`, {
          position: 'top-right',
        });
      }
    }, [isSuccess, shouldAutoSave]);

    if (isLoadingFromId || isLoadingFromPhone) {
      return <p className="mt-10 mx-auto text-lg">Chargement...</p>;
    }

    if (!patient) {
      return (
        <p className="mt-10 mx-auto text-lg">
          Patient inconnu. Veuillez le créer au préalable.
        </p>
      );
    }

    return (
      <div className="relative">
        {!shouldAutoSave && (
          <div
            className={cx('z-10 right-4 top-8', isPage ? 'fixed' : 'absolute')}
          >
            <button
              type="button"
              onClick={form.handleSubmit(handleManualPatchUser)}
              disabled={form.formState.isSubmitting}
              className="my-6 bg-cyan-400 rounded-lg p-2"
            >
              <span className="text-white ml-1">Valider</span>
            </button>
          </div>
        )}
        <WrappedComponent
          {...props}
          patientForm={form}
          patient={patient}
          isPage={isPage}
          handleRevisionChange={handleRevisionChange}
        />
      </div>
    );
  };
};
