import React, { useEffect, useCallback } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import { RiDeleteBinLine } from 'react-icons/ri';
import { IoWarningOutline } from 'react-icons/io5';
import { MdFavorite, MdFavoriteBorder } from 'react-icons/md';
import { LuPencilLine, LuFileText } from 'react-icons/lu';
import { useGetMeQuery } from '@@/services/user';
import {
  useGetUserPrescriptionsQuery,
  useDeletePrescriptionMutation,
  usePatchPrescriptionMutation,
} from '@@/services/prescription';
import { translatePrescriptionType } from '@@/constants/prescription';

const PrescriptionSection: React.FC<{ patientId: string }> = ({
  patientId,
}) => {
  const { data: me } = useGetMeQuery();
  const { data: prescriptions } = useGetUserPrescriptionsQuery(patientId, {
    skip: !patientId,
  });
  const [
    deletePrescription,
    { isSuccess: isDeletionSuccess, isLoading: isDeletionLoading },
  ] = useDeletePrescriptionMutation();

  const [patchPrescription, { isLoading: isPatchLoading }] =
    usePatchPrescriptionMutation();

  const onDelete = useCallback(
    (prescriptionId: string) => {
      if (confirm('Souhaitez vous vraiment supprimer cette ordonnance ?')) {
        deletePrescription(prescriptionId);
      }
    },
    [deletePrescription],
  );

  const toggleFavorite = useCallback(
    (prescription: Unpatient.Prescription) => {
      patchPrescription({
        id: prescription.id,
        patientId: prescription.patientId,
        isTemplate: !prescription.isTemplate,
      });
    },
    [patchPrescription],
  );

  useEffect(() => {
    if (isDeletionSuccess) {
      toast.success(`La prescription a bien été supprimée`, {
        position: 'top-right',
      });
    }
  }, [isDeletionSuccess]);

  return (
    <div className="mr-4">
      <div
        id="prescriptions"
        className="flex flex-row items-center justify-between"
      >
        <div className="flex flex-row items-center">
          <span className="text-xl font-bold">Ordonnance(s)</span>
          <span className="ml-1 text-xs py-1 px-2 bg-sky-100 text-sky-700 rounded-full">
            {prescriptions?.length}
          </span>
        </div>
        <Link
          className="bg-sky-600 rounded-lg p-1.5 flex flex-row items-center text-white w-fit"
          to={`/patients/${patientId}/prescriptions/new`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <LuPencilLine size={16} />
          <span className="ml-1 text-sm">Nouvelle ordonnance</span>
        </Link>
      </div>
      <div className="flex flex-col space-y-4 my-4">
        {prescriptions?.map((prescription) => (
          <div
            key={prescription.id}
            className="flex flex-row items-center justify-between p-1 border-b border-gray-200 rounded-lg"
          >
            <Link
              to={`/patients/${prescription.patientId}/prescriptions/${prescription.id}`}
              target="_blank"
              rel="noopener noreferrer"
              className="flex flex-row items-center"
            >
              <LuFileText className="text-sky-600 bg-sky-100 w-8 h-8 p-1 rounded-lg" />
              <div className="ml-1">
                <p className="text-sm">
                  {prescription.title ||
                    translatePrescriptionType(prescription.type)}
                </p>
                <p className="text-xs">
                  {moment(prescription.createdAt).format('DD/MM/YYYY')}
                </p>
              </div>
            </Link>

            <div>
              {prescription.practitionerId === me?.id && (
                <button
                  className="ml-2"
                  onClick={() => toggleFavorite(prescription)}
                  disabled={isPatchLoading || isDeletionLoading}
                >
                  {prescription.isTemplate ? (
                    <MdFavorite size={24} />
                  ) : (
                    <MdFavoriteBorder size={24} />
                  )}
                </button>
              )}

              {prescription.practitionerId === me?.id && (
                <button
                  className="ml-2"
                  onClick={() => onDelete(prescription.id)}
                  disabled={isDeletionLoading}
                >
                  <RiDeleteBinLine size={24} />
                </button>
              )}
            </div>
          </div>
        ))}
      </div>

      {!prescriptions?.length && (
        <p className="font-main text-main flex flex-row items-center">
          <IoWarningOutline size={24} />
          <span className="mx-1">Aucune ordonnance</span>
          <IoWarningOutline size={24} />
        </p>
      )}
    </div>
  );
};

export default PrescriptionSection;
