export enum Sources {
  WEBAPP = 'webapp',
  WHATSAPP = 'whatsapp',
}

export enum DocumentTypes {
  TRANSCRIPT = 'transcript',
  PRESCRIPTION = 'prescription',
  IMAGERY = 'imagery',
  BIOLOGY = 'biology',
  REFERRAL = 'referral',
  FUNCTIONAL = 'functional',
  PLAN = 'plan',
  LOCKED = 'locked',
  OTHER = 'other',

  SIGNATURE = 'signature',
  AVATAR = 'avatar',
  EVENT_ATTACHMENT = 'event_attachment',
}

export const translateDocumentType = (type: string, plural = false) => {
  if (plural) {
    switch (type) {
      case DocumentTypes.PLAN:
        return 'Plans de traitement';
      case DocumentTypes.TRANSCRIPT:
        return 'Comptes rendus médicaux';
      case DocumentTypes.PRESCRIPTION:
        return 'Ordonnances :';
      case DocumentTypes.FUNCTIONAL:
        return 'Exploration fonctionnelle';
      case DocumentTypes.IMAGERY:
        return "Résultats d'imagerie";
      case DocumentTypes.BIOLOGY:
        return "Résultats d'analyses";
      case DocumentTypes.REFERRAL:
        return "Lettres d'adressage";
      case DocumentTypes.OTHER:
        return 'Autres documents';
      default:
        return type;
    }
  }
  switch (type) {
    case DocumentTypes.PLAN:
      return 'Plan de traitement';
    case DocumentTypes.TRANSCRIPT:
      return 'Compte rendu médical';
    case DocumentTypes.PRESCRIPTION:
      return 'Ordonnance';
    case DocumentTypes.FUNCTIONAL:
      return 'Exploration fonctionnelle :';
    case DocumentTypes.IMAGERY:
      return 'Imagerie';
    case DocumentTypes.BIOLOGY:
      return "Résultats d'analyse(s)";
    case DocumentTypes.REFERRAL:
      return "Lettre d'adressage";
    case DocumentTypes.OTHER:
      return 'Autre';
    case DocumentTypes.LOCKED:
      return 'Chiffré';
    default:
      return type;
  }
};

export const selectInputDocumentTypes = Object.values(DocumentTypes)
  .filter(
    (t) =>
      ![
        DocumentTypes.SIGNATURE,
        DocumentTypes.AVATAR,
        DocumentTypes.EVENT_ATTACHMENT,
      ].includes(t),
  )
  .map((type) => ({
    id: type,
    label: translateDocumentType(type),
  }));

export enum EncounterNoteTypes {
  ONBOARDING = 'onboarding',
  FOLLOW_UP = 'follow_up',
}

export const translateEncounterNoteType = (type: string) => {
  switch (type) {
    case EncounterNoteTypes.ONBOARDING:
      return 'Onboarding';
    case EncounterNoteTypes.FOLLOW_UP:
      return 'Suivi';
    default:
      return type;
  }
};

export const selectInputEncounterNoteTypes = Object.values(
  EncounterNoteTypes,
).map((type) => ({
  id: type,
  label: translateEncounterNoteType(type),
}));
