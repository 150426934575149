export enum Statuses {
  LOADING = 'loading',
  READY = 'ready',
}

export enum ChatTypes {
  CHAT = 'chat',
  REVOKED = 'revoked',
  IMAGE = 'image',
  DOCUMENT = 'document',
  PTT = 'ptt',
  STICKER = 'sticker',
  VCARD = 'vcard',
  VIDEO = 'video',
  E2E_NOTIFICATION = 'e2e_notification',
  GP2 = 'gp2',
  CIPHERTEXT = 'ciphertext',
  NOTIFICATION_TEMPLATE = 'notification_template',
  CALL_LOG = 'call_log',
}
