import React, { useMemo } from 'react';
import moment from 'moment';
import { FieldError } from 'react-hook-form';
import { useGetUserPrescriptionsQuery } from '@@/services/prescription';
import SelectInput from '@@/components/Inputs/SelectInput';

const SelectPatientPrescriptionInput: React.FC<{
  id: string;
  patientId: string;
  label?: string;
  placeholder?: string;
  error?: FieldError;
  register: any;
}> = ({ id, patientId, label, placeholder, error, register }) => {
  const { data: prescriptions } = useGetUserPrescriptionsQuery(patientId);

  const selectPrescriptionsInput = useMemo(() => {
    if (prescriptions) {
      return [...prescriptions]
        .map((p) => {
          const date = moment(p.createdAt);
          return {
            id: p.id,
            label: `${date.format('DD/MM/YY')} - ${p.title}`,
            date,
          };
        })
        .sort((d1, d2) => {
          return d2.date.unix() - d1.date.unix();
        });
    }

    return [];
  }, [prescriptions]);

  if (!prescriptions?.length) {
    return null;
  }

  return (
    <SelectInput
      register={register}
      id={id}
      label={label}
      options={selectPrescriptionsInput}
      placeholder={placeholder}
      error={error}
    />
  );
};

export default SelectPatientPrescriptionInput;
