import React, { useMemo } from 'react';
import moment from 'moment';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { getTitle, Genders } from '@@/constants/medicalRecord';
import { EncounterNoteTypes } from '@@/constants/document';
import { mainColor, bigFontSize, biggerFontSize } from '@@/constants/pdf';
import { getAge } from '@@/utils/date';
import Header from '@@/components/PDF/Header';
import Footer from '@@/components/PDF/Footer';

const styles = StyleSheet.create({
  page: {},
  date: {
    color: mainColor,
    fontFamily: 'Helvetica',
    fontSize: bigFontSize,
    marginRight: '16px',
    textAlign: 'right',
  },
  title: {
    fontFamily: 'Helvetica-Bold',
    color: mainColor,
    fontSize: '20px',
    marginBottom: '2px',
    textAlign: 'center',
  },
  profile: {
    margin: '0 12px',
  },
  textCenter: {
    color: mainColor,
    fontFamily: 'Helvetica',
    fontSize: bigFontSize,
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '20px 12px',
  },
  sectionTtile: {
    fontFamily: 'Helvetica-Bold',
    fontSize: biggerFontSize,
    color: mainColor,
    textDecoration: 'underline',
  },
  text: {
    color: mainColor,
    fontFamily: 'Helvetica',
    fontSize: bigFontSize,
    marginTop: '4px',
  },
});

const EncounterNote: React.FC<{
  encounterNote?: Nabla.EncounterNote;
  patient?: Unpatient.User;
  practitioner?: Unpatient.User;
  signature?: string;
}> = ({ encounterNote, patient, practitioner, signature }) => {
  const chiefComplaintSection = useMemo(() => {
    return encounterNote?.note?.sections?.find(
      (section) => section.key === 'CHIEF_COMPLAINT',
    );
  }, [encounterNote?.note]);

  const assessmentSection = useMemo(() => {
    return encounterNote?.note?.sections?.find(
      (section) => section.key === 'ASSESSMENT',
    );
  }, [encounterNote?.note]);

  const planSection = useMemo(() => {
    return encounterNote?.note?.sections?.find(
      (section) => section.key === 'PLAN',
    );
  }, [encounterNote?.note]);

  const body = useMemo(() => {
    switch (encounterNote?.type) {
      case EncounterNoteTypes.ONBOARDING:
        return (
          <View>
            <View style={styles.container}>
              <Text style={styles.sectionTtile}>Motif de téléconsultation</Text>
              <Text style={styles.text}>
                {encounterNote?.CUSTOM__CHIEF_COMPLAINT ||
                  chiefComplaintSection?.text}
              </Text>
            </View>

            <View style={styles.container}>
              <Text style={styles.sectionTtile}>Antécédents médicaux</Text>
              <Text style={styles.text}>
                {encounterNote?.CUSTOM__MEDICAL_HISTORY || ''}
              </Text>
            </View>

            <View style={styles.container}>
              <Text style={styles.sectionTtile}>Antécédents chirurgicaux</Text>
              <Text style={styles.text}>
                {encounterNote?.CUSTOM__SURGICAL_HISTORY || ''}
              </Text>
            </View>

            <View style={styles.container}>
              <Text style={styles.sectionTtile}>Antécédents familiaux</Text>
              <Text style={styles.text}>
                {encounterNote?.CUSTOM__FAMILY_HISTORY || ''}
              </Text>
            </View>

            <View style={styles.container}>
              <Text style={styles.sectionTtile}>Mode de vie</Text>
              <Text style={styles.text}>
                {encounterNote?.CUSTOM__LIFESTYLE || ''}
              </Text>
            </View>

            <View style={styles.container}>
              <Text style={styles.sectionTtile}>Traitement(s) en cours</Text>
              <Text style={styles.text}>
                {encounterNote?.CUSTOM__CURRENT_TREATMENTS || ''}
              </Text>
            </View>

            {!!encounterNote?.CUSTOM__COMPLEMENTARY_EXAMS && (
              <View style={styles.container}>
                <Text style={styles.sectionTtile}>Examens complémentaires</Text>
                <Text style={styles.text}>
                  {encounterNote?.CUSTOM__COMPLEMENTARY_EXAMS}
                </Text>
              </View>
            )}

            <View style={styles.container}>
              <Text style={styles.sectionTtile}>
                {assessmentSection?.title}
              </Text>
              <Text style={styles.text}>
                {encounterNote?.CUSTOM__ASSESSMENT || assessmentSection?.text}
              </Text>
            </View>

            <View style={styles.container}>
              <Text style={styles.sectionTtile}>{planSection?.title}</Text>
              <Text style={styles.text}>
                {encounterNote?.CUSTOM__PLAN || planSection?.text}
              </Text>
            </View>
          </View>
        );
      case EncounterNoteTypes.FOLLOW_UP:
        return (
          <View>
            <View style={styles.container}>
              <Text style={styles.sectionTtile}>Motif de téléconsultation</Text>
              <Text style={styles.text}>
                {encounterNote?.CUSTOM__CHIEF_COMPLAINT ||
                  chiefComplaintSection?.text}
              </Text>
            </View>

            <View style={styles.container}>
              <Text style={styles.sectionTtile}>
                {assessmentSection?.title}
              </Text>

              <Text style={styles.text}>
                {encounterNote?.CUSTOM__ASSESSMENT || assessmentSection?.text}
              </Text>
            </View>

            <View style={styles.container}>
              <Text style={styles.sectionTtile}>{planSection?.title}</Text>
              <Text style={styles.text}>
                {encounterNote?.CUSTOM__PLAN || planSection?.text}
              </Text>
            </View>
          </View>
        );
      default:
        return null;
    }
  }, [encounterNote, patient, practitioner, signature]);

  if (!patient?.medicalRecord?.birthDate || !practitioner) {
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <Header practitioner={practitioner} />
          <View style={{ margin: '16px' }}>
            {!patient?.medicalRecord?.birthDate && (
              <Text style={styles.text}>
                Veuillez sélectionner un patient et veiller à ce que sa date de
                naissance soit renseignée dans son dossier médical
              </Text>
            )}
          </View>
        </Page>
      </Document>
    );
  }

  return (
    <Document>
      <Page size="A4" style={styles.page} wrap={false}>
        <Header practitioner={practitioner} />

        <Text style={styles.date}>
          Le&nbsp;
          {moment(encounterNote?.createdAt).format('DD/MM/YYYY')},
        </Text>

        <Text style={styles.title}>Compte rendu de télé-consultation</Text>
        <View style={styles.profile}>
          <Text style={styles.textCenter}>
            {getTitle(patient.medicalRecord.gender)} {patient.firstName}&nbsp;
            {patient.lastName}
          </Text>

          {!!patient.medicalRecord.birthDate && (
            <Text style={styles.textCenter}>
              {getAge(patient.medicalRecord.birthDate)} ans, né
              {patient.medicalRecord.gender === Genders.F ? 'e' : ''} le&nbsp;
              {moment(patient.medicalRecord.birthDate).format('DD/MM/YYYY')}
            </Text>
          )}
        </View>

        {body}

        <Footer practitioner={practitioner} signature={signature} />
      </Page>
    </Document>
  );
};

export default EncounterNote;
