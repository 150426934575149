import React, { useMemo } from 'react';
import moment from 'moment';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import Header from '@@/components/PDF/Header';
import { getAge } from '@@/utils/date';
import { translateSpeciality } from '@@/constants/practitioner';
import { Genders, getTitle } from '@@/constants/medicalRecord';
import { mainColor } from '@@/constants/pdf';

const styles = StyleSheet.create({
  page: {},
  content: {
    margin: '16px',
  },
  title: {
    fontFamily: 'Helvetica-Bold',
    color: mainColor,
    fontSize: '20px',
    marginBottom: '2px',
    textAlign: 'center',
  },
  info: {
    fontFamily: 'Helvetica',
    color: mainColor,
    fontSize: '12px',
    textAlign: 'center',
  },
  profile: {
    marginTop: '16px',
  },
  name: {
    fontFamily: 'Helvetica-Bold',
    color: mainColor,
    fontSize: '20px',
  },
  text: {
    fontFamily: 'Helvetica',
    fontSize: '12px',
    color: mainColor,
  },
  h1: {
    fontFamily: 'Helvetica-Bold',
    color: mainColor,
    fontSize: '16px',
    marginTop: '20px',
    marginBottom: '12px',
    borderBottom: `2px solid ${mainColor}`,
  },
  h2: {
    fontFamily: 'Helvetica-Bold',
    color: mainColor,
    fontSize: '14px',
  },
  grid: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  boxContainer: {
    width: '270px',
    marginTop: '8px',
    marginBottom: '8px',
    marginRight: '8px',
  },
  box: {
    padding: '8px',
    border: `1px solid ${mainColor}`,
    minHeight: '100px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '8px',
  },
});

const Box: React.FC<{ title: string; text: string }> = ({ title, text }) => {
  return (
    <View style={styles.boxContainer} wrap={false}>
      <Text style={styles.h2}>{title}</Text>
      <View style={styles.box}>
        <Text style={styles.text}>{text}</Text>
      </View>
    </View>
  );
};

const Contact: React.FC<
  Unpatient.PractitionerContact & { isTreatingDoctor?: boolean }
> = ({ name, speciality, email, isTreatingDoctor }) => {
  return (
    <View style={styles.row}>
      <Text style={styles.text}>Dr. {name}</Text>
      {!!speciality && (
        <Text style={styles.text}>
          &nbsp;- {translateSpeciality(speciality)}
        </Text>
      )}
      {!!isTreatingDoctor && (
        <Text style={styles.text}>&nbsp;(Médecin traitant)</Text>
      )}
      {!!email && <Text style={styles.text}>&nbsp;- {email}</Text>}
    </View>
  );
};

const Vaccine: React.FC<Unpatient.Vaccine> = ({ name, date }) => {
  return (
    <View style={{ marginBottom: '8px' }}>
      <Text style={styles.text}>
        {name} : {moment(date).year()}
      </Text>
    </View>
  );
};

const PatientSummary: React.FC<{
  patient?: Unpatient.User;
  practitioner?: Unpatient.User;
}> = ({ patient, practitioner }) => {
  const imc = useMemo(() => {
    if (!patient?.medicalRecord?.weight || !patient?.medicalRecord?.height) {
      return 0;
    }

    const { weight, height } = patient.medicalRecord;

    return Math.round((weight / (height * height)) * 10000 * 100) / 100;
  }, [patient?.medicalRecord?.weight, patient?.medicalRecord?.weight]);

  if (!patient?.medicalRecord || !practitioner) {
    return <Document></Document>;
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Header practitioner={practitioner} />

        <View style={styles.content}>
          <Text style={styles.title}>Dossier médical - confidentiel</Text>
          <Text style={styles.info}>
            mis à jour le {moment(patient.updatedAt).format('DD/MM/YYYY')}
          </Text>

          <View style={styles.profile}>
            <Text style={styles.name}>
              {getTitle(patient.medicalRecord.gender)} {patient.firstName}{' '}
              {patient.lastName}
            </Text>

            {!!patient.medicalRecord.birthDate && (
              <Text style={styles.text}>
                {getAge(patient.medicalRecord.birthDate)} ans, né
                {patient.medicalRecord.gender === Genders.F ? 'e' : ''} le&nbsp;
                {moment(patient.medicalRecord.birthDate).format('DD/MM/YYYY')}
              </Text>
            )}
            {!!patient.medicalRecord.height &&
              !!patient.medicalRecord.weight && (
                <Text style={styles.text}>
                  {patient.medicalRecord.height} cm -&nbsp;
                  {patient.medicalRecord.weight} kg - IMC :&nbsp;
                  {imc}
                </Text>
              )}
            {!!patient.medicalRecord.socialSecurityNumber && (
              <Text style={styles.text}>
                Num SS : {patient.medicalRecord.socialSecurityNumber}
              </Text>
            )}
            {!!patient.medicalRecord.profession && (
              <Text style={styles.text}>
                Profession : {patient.medicalRecord.profession}
              </Text>
            )}
            <Text style={styles.text}>
              Adresse: {patient.address}, {patient.postcode} {patient.city}
              ,&nbsp;
              {patient.country}
            </Text>

            <Text style={styles.text}>Email : {patient.email}</Text>
          </View>

          <Text style={styles.h1}>Situation personnelle et habitudes</Text>
          <View style={styles.grid}>
            <Box title="Alcool" text={patient.medicalRecord.alcohol || ''} />
            <Box title="Tabac" text={patient.medicalRecord.smoker || ''} />
            <Box
              title="Activité physique"
              text={patient.medicalRecord.exercise || ''}
            />
            <Box
              title="Alimentation"
              text={patient.medicalRecord.alimentation || ''}
            />
            <Box title="Sommeil" text={patient.medicalRecord.sleep || ''} />
            <Box
              title="Gestion du stress"
              text={patient.medicalRecord.stress || ''}
            />
            <Box
              title="Entourage social"
              text={patient.medicalRecord.social || ''}
            />
            <Box
              title="Autre"
              text={patient.medicalRecord.otherSituation || ''}
            />
          </View>

          <Text style={styles.h1}>Traitements</Text>
          <View style={styles.grid}>
            <Box
              title="Chirurgie passée"
              text={patient.medicalRecord.surgeryHistory || ''}
            />
            <Box
              title="Traitements à long terme"
              text={patient.medicalRecord.longTermMedication || ''}
            />
            <Box
              title="Traitements non médicamenteux"
              text={patient.medicalRecord.nonDrugTherapy || ''}
            />
          </View>

          <Text style={styles.h1}>Vaccins</Text>
          {patient.medicalRecord.vaccines?.map((v, i) => (
            <Vaccine key={i} {...v} />
          ))}

          <Text style={styles.h1}>Antécédents personnels</Text>
          <View style={styles.grid}>
            <Box
              title="Cardio-vasculaire"
              text={patient.medicalRecord.antecedents?.cardiovascular || ''}
            />
            <Box
              title="Neurologie"
              text={patient.medicalRecord.antecedents?.neurology || ''}
            />
            <Box
              title="Pneumologie"
              text={patient.medicalRecord.antecedents?.pulmonology || ''}
            />
            <Box
              title="Néphrologie"
              text={patient.medicalRecord.antecedents?.nephrology || ''}
            />
            <Box
              title="Urologie"
              text={patient.medicalRecord.antecedents?.urology || ''}
            />
            <Box
              title="Système digestif"
              text={patient.medicalRecord.antecedents?.digestion || ''}
            />
            <Box
              title="Hématologie"
              text={patient.medicalRecord.antecedents?.hematology || ''}
            />
            <Box
              title="Rhumatologie"
              text={patient.medicalRecord.antecedents?.rheumatology || ''}
            />
            <Box
              title="Orthopédie"
              text={patient.medicalRecord.antecedents?.orthopedics || ''}
            />
            <Box
              title="Dermatologie"
              text={patient.medicalRecord.antecedents?.dermatology || ''}
            />
            <Box
              title="Endocrinologie"
              text={patient.medicalRecord.antecedents?.endocrinology || ''}
            />

            {patient.medicalRecord.gender !== Genders.M && (
              <Box
                title="Gynécologie"
                text={patient.medicalRecord.antecedents?.gynecology || ''}
              />
            )}
            <Box
              title="ORL"
              text={patient.medicalRecord.antecedents?.ent || ''}
            />
            <Box
              title="Maladies Infectieuses"
              text={patient.medicalRecord.antecedents?.infectiousDiseases || ''}
            />
            <Box
              title="Ophtalmologie"
              text={patient.medicalRecord.antecedents?.ophthalmology || ''}
            />

            <Box
              title="Allergies"
              text={patient.medicalRecord.antecedents?.allergies || ''}
            />
            <Box
              title="Dentaire"
              text={patient.medicalRecord.antecedents?.dental || ''}
            />
            <Box
              title="Santé mentale"
              text={patient.medicalRecord.antecedents?.psychiatry || ''}
            />
            <Box
              title="Autre"
              text={patient.medicalRecord.antecedents?.other || ''}
            />
          </View>

          <Text style={styles.h1}>Antécédents familiaux</Text>
          <View style={styles.grid}>
            <Box
              title="Père"
              text={patient.medicalRecord.familyAntecedents?.father || ''}
            />
            <Box
              title="Mère"
              text={patient.medicalRecord.familyAntecedents?.mother || ''}
            />
            <Box
              title="Enfants"
              text={patient.medicalRecord.familyAntecedents?.children || ''}
            />
            <Box
              title="Frères"
              text={patient.medicalRecord.familyAntecedents?.brother || ''}
            />
            <Box
              title="Soeurs"
              text={patient.medicalRecord.familyAntecedents?.sister || ''}
            />
            <Box
              title="Autre"
              text={patient.medicalRecord.familyAntecedents?.other || ''}
            />
          </View>

          <Text style={styles.h1}>Correspondants</Text>
          {patient.medicalRecord.treatingDoctor && (
            <Contact
              {...(patient.medicalRecord
                .treatingDoctor as unknown as Unpatient.PractitionerContact)}
              isTreatingDoctor
            />
          )}
          {patient.medicalRecord.specialists?.map((s, i) => (
            <Contact
              key={i}
              {...(s as unknown as Unpatient.PractitionerContact)}
            />
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default PatientSummary;
