import React from 'react';
import { Link } from 'react-router-dom';
import { useGetUserPlansQuery } from '@@/services/plan';
import { useGetUserPrescriptionsQuery } from '@@/services/prescription';
import { useGetUserReferralsQuery } from '@@/services/referral';

import { IoWarningOutline } from 'react-icons/io5';
import { LuPencilLine } from 'react-icons/lu';

import PlanSection from '@@/components/Prescribers/PlanSection';
import PrescriptionSection from '@@/components/Prescribers/PrescriptionSection';
import Referral from '@@/components/Prescribers/Referral';

const PrescriberSection: React.FC<{ patientId: string }> = ({ patientId }) => {
  const { data: plans } = useGetUserPlansQuery(patientId, {
    skip: !patientId,
  });
  const { data: prescriptions } = useGetUserPrescriptionsQuery(patientId, {
    skip: !patientId,
  });
  const { data: referrals } = useGetUserReferralsQuery(patientId, {
    skip: !patientId,
  });

  return (
    <div className="relative flex flex-row">
      <div className="w-[200px] fixed">
        <Link
          to="#prescribers@prescriptions"
          className="block p-3 mb-2 border border-gray-200 rounded-lg text-slate-600"
        >
          <p className="font-bold text-lg">{prescriptions?.length}</p>
          <p className="text-xs">Prescription(s)</p>
        </Link>
        <Link
          to="#prescribers@referrals"
          className="block p-3 mb-2 border border-gray-200 rounded-lg text-slate-600"
        >
          <p className="font-bold text-lg">{referrals?.length}</p>
          <p className="text-xs">Lettre(s) d'adressage</p>
        </Link>
        <Link
          to="#prescribers@plans"
          className="block p-3 border border-gray-200 rounded-lg text-slate-600"
        >
          <p className="font-bold text-lg">{plans?.length}</p>
          <p className="text-xs">Plan(s) de soins</p>
        </Link>
      </div>

      <div className="w-[calc(100%-220px)] ml-[220px]">
        <PrescriptionSection patientId={patientId} />

        <div className="mt-12 mr-4">
          <div
            id="referrals"
            className="flex flex-row items-center justify-between"
          >
            <div className="flex flex-row items-center">
              <span className="text-xl font-bold">Lettre(s) d'adressage</span>
              <span className="ml-1 text-xs py-1 px-2 bg-sky-100 text-sky-700 rounded-full">
                {referrals?.length}
              </span>
            </div>
            <Link
              className="bg-sky-600 rounded-lg p-1.5 flex flex-row items-center text-white w-fit"
              to={`/patients/${patientId}/referrals/new`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LuPencilLine size={16} />
              <span className="ml-1 text-sm">Nouvel Adressage</span>
            </Link>
          </div>

          <div className="flex flex-col space-y-4 mt-4">
            {referrals?.map((referral) => (
              <Referral key={referral.id} referral={referral} />
            ))}

            {!referrals?.length && (
              <p className="flex flex-row items-center">
                <IoWarningOutline size={24} />
                <span className="mx-1">Aucun adressage</span>
                <IoWarningOutline size={24} />
              </p>
            )}
          </div>
        </div>

        <div className="mt-12">
          <PlanSection patientId={patientId} />
        </div>
      </div>
    </div>
  );
};

export default PrescriberSection;
