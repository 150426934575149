import React, {
  useEffect,
  useMemo,
  useCallback,
  useState,
  useRef,
} from 'react';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import moment from 'moment';
import cx from 'classnames';
import { useForm } from 'react-hook-form';
import { RootState } from '@@/store';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  RiDeleteBin5Line,
  RiChatFollowUpLine,
  RiChatOffLine,
} from 'react-icons/ri';
import { IoWarningOutline } from 'react-icons/io5';
import { CiEdit } from 'react-icons/ci';
import {
  LuCake,
  LuMail,
  LuPhone,
  LuMapPin,
  LuArrowUpRight,
  LuPill,
  LuTriangleAlert,
  LuArrowLeft,
  LuHistory,
} from 'react-icons/lu';
import { TbGenderFemale, TbGenderMale } from 'react-icons/tb';

import {
  useGetChatMessagesQuery,
  useSendMessageMutation,
  useDeleteMessageMutation,
  useGetChatsStatusQuery,
} from '@@/services/whatsapp';
import { useGetMeQuery } from '@@/services/user';
import { useCreateNextMorningScheduledMessageMutation } from '@@/services/scheduledMessage';
import { useGetUserTasksQuery } from '@@/services/task';
import { openMessageEditionModal } from '@@/features/modal';

import { ChatTypes, Statuses } from '@@/constants/whatsapp';
import { Genders } from '@@/constants/medicalRecord';
import { getAge } from '@@/utils/date';
import AuthenticatedImage from '@@/components/AuthenticatedImage';
import MessageForm, { schema } from '@@/components/Forms/MessageForm';
import Message from '@@/components/Message';
import MessageEditionModal from '@@/components/Modals/MessageEditionModal';
import Acknowledgement from '@@/components/Acknowledgement';

import { withPatientForm, WithFormProps } from '@@/hocs/withPatientForm';
import {
  PatientMedicalForm,
  PatientMedicalContactsForm,
  PatientProfileForm,
  PatientPreferrencesForm,
} from '@@/components/Forms/PatientForm';
import TimelineSection from '@@/components/TimelineSection';
import SuggestionPage from '@@/pages/SuggestionPage';
import DocumentSection from '@@/components/DocumentSection';
import PrescribersSection from '@@/components/Prescribers';
import InvoiceSection from '@@/components/InvoiceSection';
import SearchUserDataSection from '@@/components/SearchUserDataSection';
import HistoryForm from '@@/components/Forms/HistoryForm';
import TaskCard from '@@/components/TaskCard';

enum Tabs {
  PREVIEW = 'preview',
  MEDICAL_INFO = 'medical-info',
  DOCUMENTS = 'documents',
  PRESCRIBERS = 'prescribers',
  CONTACTS = 'contacts',
  SUGGESTIONS = 'suggestions',
  ADMINISTRATIVE = 'administrative',
}

const Tab: React.FC<{
  id: string;
  title: string;
  activeTab: string;
  cb: Function;
}> = ({ id, activeTab, title, cb }) => {
  return (
    <button
      className={cx('p-2', activeTab === id && 'border-b-2 border-sky-500')}
      onClick={() => {
        cb(id);
      }}
    >
      <span
        className={cx(
          'font-semibold text-sm',
          activeTab === id ? 'text-sky-500' : 'text-slate-700',
        )}
      >
        {title}
      </span>
    </button>
  );
};

const ConversationPage: React.FC<WithFormProps> = ({
  patient,
  patientForm,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const contentRef = useRef<HTMLDivElement>(null);

  const { server, phone } = useParams();
  const conversationId = useMemo(() => `${phone}@${server}`, [server, phone]);

  const { data: me } = useGetMeQuery();
  const { data: wsStatus } = useGetChatsStatusQuery();
  const { data: messages } = useGetChatMessagesQuery(conversationId || '', {
    skip: !conversationId,
  });
  const { data: tasks } = useGetUserTasksQuery(patient?.id || '', {
    skip: !patient?.id,
  });

  const [sendMessage, { isSuccess, isLoading }] = useSendMessageMutation();
  const [deleteMessage, { isLoading: isDeletionLoading }] =
    useDeleteMessageMutation();

  const onEditMessage = useCallback(
    (messageId: string, messageBody: string) => {
      dispatch(
        openMessageEditionModal({ conversationId, messageId, messageBody }),
      );
    },
    [dispatch, conversationId],
  );

  const [
    createNextMorningScheduleMessage,
    { isSuccess: isScheduleMessageCreationSuccess },
  ] = useCreateNextMorningScheduledMessageMutation();

  const form = useForm<{ message: string; files: FileList }>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: {},
  });

  const message = useSelector(
    (state: RootState) => state.conversation[conversationId]?.message ?? '',
  );
  const [files] = form.watch(['files']);

  const isSubmitButtonDisabled = useMemo(() => {
    return isLoading || (!message && !files?.length);
  }, [message, files, isLoading]);

  const onSubmit = useCallback(
    ({ files }: { files: FileList }) => {
      sendMessage({ message, files, chatId: conversationId });
    },
    [sendMessage, conversationId, message],
  );

  const onDelayedSubmit = useCallback(
    ({ message, files }: { message: string; files: FileList }) => {
      if (files?.length) {
        toast.error('Vous ne pouvez pas envoyer de fichiers en différé', {
          position: 'top-right',
        });
        return;
      }

      if (!patient?.id) {
        return;
      }

      if (
        confirm(
          'Souhaitez-vous envoyer ce message en différé (prochain créneau à 8h) ?',
        )
      ) {
        createNextMorningScheduleMessage({
          userId: patient.id,
          body: message,
        });
      }
    },
    [createNextMorningScheduleMessage, patient?.id],
  );

  const handleGoToVideoLink = useCallback(() => {
    if (me?.videoRoomLink) {
      sendMessage({
        message: me?.videoRoomLink,
        chatId: conversationId,
      });

      window.open(me?.videoRoomLink, '_blank');
    }
  }, [sendMessage, conversationId, me?.videoRoomLink]);

  useEffect(() => {
    if (messages?.length) {
      const inputBar = document.getElementById('input-bar');
      if (inputBar) {
        setTimeout(() => {
          inputBar.scrollIntoView({ block: 'end', behavior: 'smooth' });
        }, 500);
      }
    }
  }, [messages?.length]);

  useEffect(() => {
    if (isSuccess) {
      form.reset({ message: '', files: undefined });
      toast.success('Votre message a bien été envoyé', {
        position: 'top-right',
      });
    }
  }, [form.reset, isSuccess]);

  useEffect(() => {
    if (isScheduleMessageCreationSuccess) {
      form.reset({ message: '', files: undefined });
      toast.success('Votre message a bien été programmé', {
        position: 'top-right',
      });

      navigate('/scheduled-messages');
    }
  }, [form.reset, isScheduleMessageCreationSuccess]);

  const [activeTab, setActiveTab] = useState(Tabs.PREVIEW);
  const setActiveTabWithHash = useCallback(
    (id: Tabs) => {
      setActiveTab(id);
      window.location.hash = id;
    },
    [setActiveTab],
  );

  useEffect(() => {
    if (location?.hash) {
      const [hash, subHash] = location.hash.split('@');
      setActiveTab(hash.replace('#', '') as Tabs);

      if (contentRef.current) {
        contentRef.current.scrollTop = 0;
      }

      if (subHash) {
        setTimeout(() => {
          const element = document.getElementById(subHash);
          if (element) {
            element.scrollIntoView({ block: 'start', behavior: 'smooth' });
          }
        }, 500);
      }
    }
  }, [location?.hash, setActiveTab]);

  const header = useMemo(() => {
    const birthDate =
      patient?.medicalRecord?.birthDate &&
      `${moment(patient?.medicalRecord.birthDate).format('ll')} (${getAge(patient?.medicalRecord.birthDate)} ans)`;

    return (
      <div className="">
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center space-x-4">
            <button
              className="flex flex-row items-center text-slate-600"
              onClick={() => {
                navigate('/patients');
              }}
            >
              <LuArrowLeft size={16} />
              <span className="ml-1 text-sm">Retour</span>
            </button>
            {!!patient?.id && (
              <div className="flex flex-row items-center">
                <LuHistory size={12} className="text-slate-600 -mr-3" />
                <HistoryForm patientId={patient.id} callback={() => {}} />
              </div>
            )}
          </div>
          <div className="flex flex-row items-center space-x-2">
            <Link
              className="flex flew-row items-center"
              to={`/patients/${patient?.id}/summary`}
            >
              <LuArrowUpRight size={12} />
              <span className="ml-0.5 text-xs">PDF</span>
            </Link>
            {patient?.id && (
              <SearchUserDataSection userId={patient.id} disableError />
            )}
          </div>
        </div>
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center space-x-2">
            <h1 className="text-2xl font-bold">
              {patient?.firstName} {patient?.lastName}
            </h1>
            {patient?.medicalRecord?.gender === Genders.M && (
              <div className="py-0.5 bg-blue-100 rounded-md text-blue-700">
                <TbGenderMale size={16} />
              </div>
            )}
            {patient?.medicalRecord?.gender === Genders.F && (
              <div className="py-0.5 bg-pink-100 rounded-md text-pink-700">
                <TbGenderFemale size={16} />
              </div>
            )}

            {!!patient?.medicalRecord?.longTermMedication &&
              patient?.medicalRecord?.longTermMedication.length > 3 && (
                <div className="flex flex-row items-center text-sky-700 bg-sky-100 p-0.5 rounded-md">
                  <LuPill size={12} />
                  <span className="text-xs ml-0.5">Traitements en cours</span>
                </div>
              )}

            {!!patient?.medicalRecord?.antecedents?.allergies &&
              patient?.medicalRecord?.antecedents?.allergies.length > 3 && (
                <div className="flex flex-row items-center text-red-700 bg-red-100 p-0.5 rounded-md">
                  <LuTriangleAlert size={12} />
                  <span className="text-xs ml-0.5">Allergies</span>
                </div>
              )}
          </div>
        </div>
        <div className="flex flex-row items-center text-slate-600 space-x-4">
          {patient?.photoDocumentId ? (
            <AuthenticatedImage
              documentId={patient?.photoDocumentId}
              placeholder="/user-profile-placeholder.jpg"
              alt={`${patient?.firstName} ${patient?.lastName}`}
              className="w-8 h-8 font-main rounded-full bg-slate-100 border border-slate-200"
            />
          ) : (
            <div className="w-10 h-10 font-main rounded-full bg-slate-100 border border-slate-200 flex items-center justify-center">
              <span className="text-sky-600 font-semibold">
                {patient?.firstName?.slice(0, 1)}
                {patient?.lastName?.slice(0, 1)}
              </span>
            </div>
          )}
          {!!birthDate && (
            <div className="flex flex-row items-center">
              <LuCake size={16} />
              <span className="text-xs ml-0.5">{birthDate}</span>
            </div>
          )}
          {!!patient?.phone && (
            <div className="flex flex-row items-center">
              <LuPhone size={16} />
              <span className="text-xs ml-0.5">+{patient.phone}</span>
            </div>
          )}
          {!!patient?.email && (
            <div className="flex flex-row items-center">
              <LuMail size={16} />
              <span className="text-xs ml-0.5">{patient.email}</span>
            </div>
          )}
          {!!patient?.city && !!patient?.postcode && (
            <div className="flex flex-row items-center">
              <LuMapPin size={16} />
              <span className="text-xs ml-0.5">
                {patient.city} {patient.postcode}
              </span>
            </div>
          )}
        </div>
        <div className="flex flex-row items-center space-x-3 bg-slate-100 mb-4">
          <Tab
            id={Tabs.PREVIEW}
            title="Aperçu"
            activeTab={activeTab}
            cb={setActiveTabWithHash}
          />
          <Tab
            id={Tabs.MEDICAL_INFO}
            title="Infos médicales"
            activeTab={activeTab}
            cb={setActiveTabWithHash}
          />
          <Tab
            id={Tabs.DOCUMENTS}
            title="Documents"
            activeTab={activeTab}
            cb={setActiveTabWithHash}
          />
          <Tab
            id={Tabs.PRESCRIBERS}
            title="Prescripteurs"
            activeTab={activeTab}
            cb={setActiveTabWithHash}
          />
          <Tab
            id={Tabs.CONTACTS}
            title="Correspondants"
            activeTab={activeTab}
            cb={setActiveTabWithHash}
          />
          <Tab
            id={Tabs.SUGGESTIONS}
            title="Suggestions"
            activeTab={activeTab}
            cb={setActiveTabWithHash}
          />
          <Tab
            id={Tabs.ADMINISTRATIVE}
            title="Administratif"
            activeTab={activeTab}
            cb={setActiveTabWithHash}
          />
        </div>
      </div>
    );
  }, [patient, activeTab, setActiveTabWithHash]);

  const formValues = patientForm.watch();
  const tabContent = useMemo(() => {
    if (!patient?.id) {
      return null;
    }

    switch (activeTab) {
      case Tabs.MEDICAL_INFO:
        return <PatientMedicalForm form={patientForm} />;

      case Tabs.DOCUMENTS:
        return <DocumentSection userId={patient.id} />;

      case Tabs.PRESCRIBERS:
        return <PrescribersSection patientId={patient.id} />;

      case Tabs.CONTACTS:
        return <PatientMedicalContactsForm form={patientForm} />;

      case Tabs.SUGGESTIONS:
        return <SuggestionPage targetPatientId={patient.id} />;

      case Tabs.ADMINISTRATIVE:
        return (
          <div>
            <PatientProfileForm form={patientForm} />
            <InvoiceSection userId={patient.id} />
          </div>
        );

      case Tabs.PREVIEW:
      default:
        return (
          <div className="relative flex flex-row space-x-6">
            <div className="flex flex-col w-1/2">
              <PatientPreferrencesForm form={patientForm} userId={patient.id} />
              <TimelineSection patientId={patient.id} />
            </div>
            <div className="flex flex-col w-1/2">
              <h2 className="text-xl text-slate-700 font-bold mb-4">Tâches</h2>
              {tasks?.map((task) => <TaskCard key={task.id} task={task} />)}
            </div>
          </div>
        );
    }
  }, [patient?.id, formValues, tasks, activeTab]);

  const [chatIsOpen, setChatIsOpen] = useState(true);
  const toggleChat = useCallback(() => {
    setChatIsOpen(!chatIsOpen);
    if (messages?.length) {
      setTimeout(() => {
        const inputBar = document.getElementById('input-bar');
        if (inputBar) {
          inputBar.scrollIntoView({ block: 'end', behavior: 'smooth' });
        }
      }, 500);
    }
  }, [setChatIsOpen, chatIsOpen, messages]);

  return (
    <div className="flex flex-row">
      <div className="flex flex-col bg-sky-50 grow w-full h-screen relative p-4 pt-2">
        {header}
        <div ref={contentRef} className="h-full overflow-y-scroll">
          {tabContent}
        </div>

        <div className="absolute bottom-3 right-3 z-90">
          <button
            className="p-1 border border-black rounded-full"
            onClick={toggleChat}
          >
            {chatIsOpen ? (
              <RiChatOffLine size={24} />
            ) : (
              <RiChatFollowUpLine size={24} />
            )}
          </button>
        </div>
      </div>

      {chatIsOpen && (
        <div className="flex flex-col w-[800px] h-screen overflow-scroll">
          {wsStatus?.status !== Statuses.READY && (
            <p className="bg-red-500 text-white p-1.5 flex flex-row items-center justify-center">
              <IoWarningOutline size={24} />
              <span className="mx-1">WHATSAPP KO</span>
              <IoWarningOutline size={24} />
            </p>
          )}

          <MessageEditionModal />

          <div className="mx-4">
            {messages?.map((message, i) => {
              if (
                [
                  ChatTypes.NOTIFICATION_TEMPLATE,
                  ChatTypes.E2E_NOTIFICATION,
                ].includes(message.type as ChatTypes)
              ) {
                return null;
              }

              const isMessageEditable =
                message.fromMe &&
                moment
                  .unix(message.timestamp)
                  .isAfter(moment().subtract(15, 'minutes'));

              return (
                <div
                  key={i}
                  className={cx(
                    'relative mb-4 p-4 rounded-lg break-words max-w-[350px]',
                    message.fromMe
                      ? 'bg-emerald-50 text-emerald-900 ml-auto'
                      : 'bg-sky-100 text-sky-700 mr-auto',
                  )}
                >
                  {message.fromMe && message.type !== ChatTypes.REVOKED && (
                    <div className="absolute -right-1 -top-1 ">
                      {isMessageEditable && (
                        <button
                          className="border border-yellow-800 p-0.5 rounded-full mr-1"
                          onClick={() => {
                            onEditMessage(message?.id?.id, message.body || '');
                          }}
                        >
                          <CiEdit className="text-yellow-800" />
                        </button>
                      )}
                      <button
                        className="border border-red-500 p-0.5 rounded-full"
                        disabled={isDeletionLoading}
                        onClick={() => {
                          if (
                            conversationId &&
                            message?.id?.id &&
                            confirm(
                              'Voulez-vous vraiment supprimer ce message ?',
                            )
                          ) {
                            deleteMessage({
                              chatId: conversationId,
                              messageId: message.id.id,
                            });
                          }
                        }}
                      >
                        <RiDeleteBin5Line className="text-red-500" />
                      </button>
                    </div>
                  )}

                  <Message message={message} />
                  <div className="flex flex-row justify-end">
                    <p className="text-xs text-gray-500 text-right italic mr-1">
                      {moment.unix(message.timestamp).calendar()}
                    </p>
                    {message.fromMe && <Acknowledgement ack={message.ack} />}
                  </div>
                </div>
              );
            })}
          </div>

          <div className="pt-6 pb-4 border-t border-gray-100" id="input-bar">
            <MessageForm
              form={form}
              conversationId={conversationId}
              cbSuggestions={() => setActiveTabWithHash(Tabs.SUGGESTIONS)}
              onSubmit={form.handleSubmit(onSubmit)}
              onDelayedSubmit={form.handleSubmit(onDelayedSubmit)}
              isSubmitButtonDisabled={isSubmitButtonDisabled}
              handleGoToVideoLink={handleGoToVideoLink}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default withPatientForm(ConversationPage);
