import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import cx from 'classnames';
import AuthenticatedImage from '@@/components/AuthenticatedImage';
import {
  TaskTypes,
  TaskStatuses,
  translateTaskType,
  translateTaskStatus,
} from '@@/constants/task';

const getTypeClassName = (row: Unpatient.Task) => {
  switch (row.type) {
    case TaskTypes.CONTACT_SEARCH:
      return 'bg-orange-100 text-orange-700';
    case TaskTypes.PATIENT_REMINDER:
      return 'bg-amber-100 text-amber-700';
    case TaskTypes.TRANSCRIPT_FETCH:
      return 'bg-indigo-100 text-indigo-700';
    case TaskTypes.RDV_CREATION:
      return 'bg-pink-100 text-pink-700';
    case TaskTypes.RDV_PATCH:
      return 'bg-purple-100 text-purple-700';
    case TaskTypes.RDV_DELETION:
      return 'bg-red-100 text-red-700';
    case TaskTypes.OTHER:
      return 'bg-slate-100 text-slate-700';
    default:
      return '';
  }
};

const getTaskStatusClassName = (row: Unpatient.Task) => {
  if (row.status === TaskStatuses.DONE) {
    return 'bg-green-100 text-green-700';
  }

  if (!row.deadline) {
    return 'bg-yellow-100 text-yellow-700';
  }

  const now = moment();
  const deadlineMoment = moment(row.deadline);
  if (
    deadlineMoment.isSame(now, 'week') ||
    deadlineMoment.diff(now, 'days') < 4
  ) {
    return 'bg-red-100 text-red-700 ';
  }

  if (deadlineMoment.diff(now, 'days') < 15) {
    return 'bg-orange-100 text-orange-700';
  }

  return 'bg-yellow-100 text-yellow-700';
};

const TaskCard: React.FC<{ task: Unpatient.Task }> = ({ task }) => {
  return (
    <Link
      className="mb-2 border border-slate-200 p-2 rounded-lg bg-white"
      to={`/tasks#all@${task.id}`}
    >
      <div className="flex flex-row items-center justify-between mb-2">
        <div className="flex flex-row items-center">
          {task.ownerId?.photoDocumentId ? (
            <AuthenticatedImage
              documentId={task.ownerId?.photoDocumentId}
              placeholder="/user-profile-placeholder.jpg"
              alt={`${task.ownerId?.firstName} ${task.ownerId?.lastName}`}
              className="w-8 h-8 bg-white font-main rounded-full"
            />
          ) : (
            <div className="w-8 h-8 font-main rounded-full bg-slate-100 border border-slate-200 flex items-center justify-center">
              <span className="text-sky-600 text-sm font-semibold">
                {task.ownerId?.firstName?.slice(0, 1)}
                {task.ownerId?.lastName?.slice(0, 1)}
              </span>
            </div>
          )}
          <span
            className={cx(
              'ml-2 text-sm p-1 rounded-md',
              getTypeClassName(task),
            )}
          >
            {translateTaskType(task.type)}
          </span>
        </div>

        <span
          className={cx('text-sm p-1 rounded-md', getTaskStatusClassName(task))}
        >
          {translateTaskStatus(task.status)}
        </span>
      </div>
      <p className="text-sm text-slate-700">{task.request}</p>
    </Link>
  );
};

export default TaskCard;
