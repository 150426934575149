import React from 'react';
import { FieldError } from 'react-hook-form';

type SelectOption = {
  id: string;
  label: string;
  disabled?: boolean;
  selected?: boolean;
};

const SelectInput: React.FC<{
  id: string;
  label?: string;
  register: any;
  placeholder?: string;
  options: SelectOption[];
  error?: FieldError;
  callback?: Function;
  selectClassName?: string;
  labelClassName?: string;
  disabled?: boolean;
}> = ({
  id,
  label,
  register,
  placeholder,
  options,
  error,
  callback,
  disabled,
  selectClassName = 'block font-main text-main text-sm appearance-none w-full bg-white border-sm border-gray-400 hover:border-gray-600 pl-4 pr-8 rounded-lg shadow-sm focus:outline-none focus:shadow-outline h-12',
  labelClassName = 'block font-main text-main text-sm mb-1',
}) => {
  const errorComponent = error?.message && (
    <p className="italic font-main text-sm text-red-500 m-1">{error.message}</p>
  );

  if (placeholder) {
    options = [
      {
        id: '',
        label: placeholder,
        disabled: false,
        selected: true,
      },
      ...options,
    ];
  }

  return (
    <div className="inline-block relative w-full">
      {!!label && (
        <label className={labelClassName} htmlFor={id}>
          {label}
        </label>
      )}
      <div className="inline-block relative w-full">
        <select
          {...register(id)}
          onChange={(e) => {
            register(id).onChange(e);
            if (typeof callback === 'function') {
              callback(e.target.value);
            }
          }}
          id={id}
          className={selectClassName}
          disabled={!!disabled}
        >
          {options.map((opt: SelectOption, i) => (
            <option value={opt.id} key={i} disabled={!!opt.disabled}>
              {opt.label}
            </option>
          ))}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg
            className="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </div>
      </div>
      {errorComponent}
    </div>
  );
};

export default SelectInput;
