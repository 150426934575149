import React from 'react';
import * as yup from 'yup';
import StandardInput from '@@/components/Inputs/StandardInput';

export const schema = yup
  .object({
    CUSTOM__CHIEF_COMPLAINT: yup.string(),
    CUSTOM__OVERVIEW: yup.string(),
    CUSTOM__MEDICAL_FOLLOW_UP: yup.string(),
    CUSTOM__PATIENT_CONCERNS: yup.string(),
    CUSTOM__MEDICAL_HISTORY: yup.string(),
    CUSTOM__SURGICAL_HISTORY: yup.string(),
    CUSTOM__FAMILY_HISTORY: yup.string(),
    CUSTOM__LIFESTYLE: yup.string(),
    CUSTOM__CURRENT_TREATMENTS: yup.string(),
    CUSTOM__COMPLEMENTARY_EXAMS: yup.string(),
    CUSTOM__ASSESSMENT: yup.string(),
    CUSTOM__PLAN: yup.string(),
  })
  .required();

const EncounterNoteForm: React.FC<{
  form: any;
  isOnboarding: boolean;
}> = ({ form, isOnboarding }) => {
  const {
    register,
    formState: { errors },
  } = form;

  return (
    <div className="mt-8 grid grid-cols-1 gap-8">
      <StandardInput
        register={register}
        id="CUSTOM__CHIEF_COMPLAINT"
        label="Motif de consultation"
        type="textarea"
        placeholder="Motif de consultation"
        error={errors.CUSTOM__CHIEF_COMPLAINT}
      />

      {isOnboarding && (
        <>
          <StandardInput
            register={register}
            id="CUSTOM__OVERVIEW"
            label="⁠Évaluation de l'état de santé général"
            type="textarea"
            placeholder="Évaluation de l'état de santé général"
            error={errors.CUSTOM__OVERVIEW}
          />

          <StandardInput
            register={register}
            id="CUSTOM__MEDICAL_FOLLOW_UP"
            label=" ⁠Discussion sur les options de suivi médical"
            type="textarea"
            placeholder="⁠Discussion sur les options de suivi médical"
            error={errors.CUSTOM__MEDICAL_FOLLOW_UP}
          />

          <StandardInput
            register={register}
            id="CUSTOM__PATIENT_CONCERNS"
            label="Préoccupation(s)"
            type="textarea"
            placeholder="Préoccupation(s)"
            error={errors.CUSTOM__PATIENT_CONCERNS}
          />

          <StandardInput
            register={register}
            id="CUSTOM__MEDICAL_HISTORY"
            label="Antécédents médicaux"
            type="textarea"
            placeholder="Antécédents médicaux"
            error={errors.CUSTOM__MEDICAL_HISTORY}
          />

          <StandardInput
            register={register}
            id="CUSTOM__SURGICAL_HISTORY"
            label="Antécédents chirurgicaux"
            type="textarea"
            placeholder="Antécédents chirurgicaux"
            error={errors.CUSTOM__SURGICAL_HISTORY}
          />

          <StandardInput
            register={register}
            id="CUSTOM__SURGICAL_HISTORY"
            label="Antécédents familiaux"
            type="textarea"
            placeholder="Antécédents familiaux"
            error={errors.CUSTOM__FAMILY_HISTORY}
          />

          <StandardInput
            register={register}
            id="CUSTOM__LIFESTYLE"
            label="Mode de vie"
            type="textarea"
            placeholder="Mode de vie"
            error={errors.CUSTOM__LIFESTYLE}
          />

          <StandardInput
            register={register}
            id="CUSTOM__CURRENT_TREATMENTS"
            label="Traitement(s) en cours"
            type="textarea"
            placeholder="Traitement(s) en cours"
            error={errors.CUSTOM__CURRENT_TREATMENTS}
          />

          <StandardInput
            register={register}
            id="CUSTOM__COMPLEMENTARY_EXAMS"
            label="TExamens complémentaires"
            type="textarea"
            placeholder="Examens complémentaires"
            error={errors.CUSTOM__COMPLEMENTARY_EXAMS}
          />
        </>
      )}
      <StandardInput
        register={register}
        id="CUSTOM__ASSESSMENT"
        label="Contexte et évaluation"
        type="textarea"
        placeholder="Contexte et évaluation"
        error={errors.CUSTOM__ASSESSMENT}
      />
      <StandardInput
        register={register}
        id="CUSTOM__PLAN"
        label="Recommandations"
        type="textarea"
        placeholder="Recommandations"
        error={errors.CUSTOM__PLAN}
      />
    </div>
  );
};

export default EncounterNoteForm;
